// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import { filterRoutesByRole } from 'routes';
import loading from "components/Loading";
import { useState, useEffect } from "react";
import { SignOutButton, SignInButton, ClerkProvider, useUser,useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

function Privacy() {
  const { userId, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);

  useEffect(() => {
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        //console.log(data);
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);

  const filteredRoutes = filterRoutesByRole(routes, userRoles);
  return (
    <>
      <DefaultNavbar
        routes={filteredRoutes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "dark",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Allgemeines Regelwerk
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Zuletzt aktualisiert am 11.02.2024
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    <b>1 Allgemeines</b>
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    1.	Das Team behält sich vor, alle in den Serverregeln sowie den Allgemeinen Regeln erfassten Regeln, Richtlinien sowie Sanktionen nach eigenem Ermessen durchzusetzen und auf Einzelmaßnahmen anzupassen.	
                    <br />
                    <br />
                    2.	Persönliche Beleidigungen außerhalb einer Rollenspielsituation und jede Form von Belästigung sind verboten und führen zu einem temporären Bann.
                    <br />
                    <br />
                    3.	Rassismus, Homophobie, Transphobie, Sexismus, Hassrede und alle übrigen Formen von herabwürdigender Sprache werden umgehend mit einem permanenten Ausschluss aus der Community geahndet.
                    <br />
                    <br />
                    4.	Auch wenn der Ton früher etwas rauer war, sind schwere Beleidigungen, schwerwiegender Rassismus, Sexismus und Vergewaltigungs RP strengstens verboten und werden mit einem permanenten Ausschluss aus der Community geahndet.
                    <br />
                    <br />
					5.	Die Verbreitung von Fremd- und Eigenwerbung ist untersagt und führt zum permanenten Ausschluss aus der Community.
                    <br />
                    <br />
					6.	Der Whitelist-Status erlischt automatisch nach 24 Stunden nach dem Verlassen des Discords genauso wie jegliches Anrecht auf diesen Status. Um erneut auf dem Projekt spielen zu können, muss eine neue Whitelist absolviert werden.
					<br />
					<br />
					7.	Das Team hat die zusätzliche Möglichkeit, bei Einzelpersonen als Disziplinarmaßnahme bis zu 3 Strikes auszusprechen. Bei 3 verzeichneten Strikes wird die Person vom Projekt ausgeschlossen und erhält nach einer achtwöchigen Frist die Möglichkeit, einen Entbannungsantrag zu stellen.
					<br />
					<br />
					8.	Du musst 18 Jahre alt sein. In Verdachtsfällen behält sich das Team vor, entsprechende Whitelistanträge abzulehnen und/oder Spielende auszuschließen.
					<br />
					<br />
					9.	In den Western Hills gilt die deutsche Sprache als Amtssprache.
					<br />
					<br />
					10.	Ein gutes Mikrofon ist der Mindestqualitätsanspruch.
					<br />
					<br />
					11.	Die Nutzung von Stimmenverzerrern ist verboten. 
					<br />
					<br />
					12.	Soundboards dürfen nur zu immersiven Zwecken genutzt werden und nicht dafür, um das RP zu stören.
					<br />
					<br />
					13.	Die Western Hills sind ein inklusiver Server. Dies bedeutet für Spielende, welche über diverse Einschränkungen verfügen, dass sie in ihrer Whitelist die Möglichkeit erhalten uns dies mitzuteilen und wir als Team werden zusammen mit dem Spieler Lösungsmöglichkeiten prüfen, um ihr Spielerlebnis so schön wie möglich zu gestalten und sie zu einem Teil unserer Community werden zu lassen. Dies ist in vielen Fällen aber unter Umständen nicht möglich.
					<br />
					<br />
					14.	Das Ausnutzen von Grauzonen oder Lücken im Regelwerk ist verboten! Sollten dir während des Spielens oder anderweitig Grauzonen oder Lücken auffallen, bitten wir diese sofort zu melden.
					<br />
					<br />
					15.	Jeder Spieler erklärt sich mit Spielen auf unserem Server dazu bereit, jegliche Regelverstöße, sofern von ihm eine solche zur Kenntnis genommen wird, umgehend zu melden.
                    <br />
                    <br />
                    16.	Ebenfalls erklärt sich jeder Spieler dazu bereit, dass seine Stimme auf dem Server im Rahmen von Streams, YouTube Videos o.ä. aufgenommen und verwendet werden darf.
                    <br />
                    <br />
                    17.	Wer den Frieden der Community stört, wird permanent von der Community ausgeschlossen. 
                    <br />
                    <br />
					18.	An Personen mit besonderer Verantwortung (Boss einer Bande, Chef eines Unternehmens, Abgeordnete, Sheriffs, Marshal) wird die Erwartungshaltungshaltung geknüpft, sich in besonderem Maße an die Regeln und Richtlinien des Servers zu halten und RP-fördernde Spielerlebnisse zu schaffen. Das Team behält sich vor, etwaige Maßnahmen an Personen mit Führungsverantwortung herauszugeben, da sie maßgeblich am Gesamterscheinungsbild des Rollenspiels auf diesem Server beteiligt sind.
           Klicke {" "}<MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="https://wiki.western-hills.de/de/Regelwerke/Server-Regelwerk#allgemeine-serverregeln"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          hier
                        </MKTypography> für weitere Informationen
					<br />
                    <br />
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                  <b>2 Discord & Kommunikation mit dem Team der Western Hills</b>
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    1. Discord Community-Etikette: Jeder Spieler und jede Spielerin ist angehalten, sich auch abseits des Roleplay respektvoll und freundlich gegenüber anderen Mitgliedern der Community zu verhalten. Beleidigungen, Diskriminierung, Mobbing sowie jegliche Form von wiederholt bewusst provokanten Äußerungen und Meinungsbildungen (z.B. &quot;Toxic-Verhalten&quot;) in den Chatkanälen, die Unruhe innerhalb der Community zum Ziel haben oder haben könnten, werden nicht toleriert und können mit einem Entzug der &quot;Bürger-Rolle&quot; oder dem dauerhaften Ausschluss geahndet werden.
                    <br />
                    <br />
                    2.	Die Kommunikation mit dem Team der Western Hills findet ausschließlich über den offiziellen Discord-Server statt. 
                    <br />
                    <br />
                    3.	Das Team wird keinen privaten Discord-Servern von Gruppierungen und/oder Unternehmen beitreten und/oder diese als offiziellen Teil einer Gruppierung/eines Unternehmens bewilligen. Jede Kommunikation hat via Support-Ticket zu erfolgen. Sollten dem Team Informationen zugespielt werden, dass auf solchen Discord-Servern Regelbrüche begangen werden, behält sich das Team vor, entsprechende Sanktionen zu ergreifen und umzusetzen.
                    <br />
                    <br />
                    4.	Privatnachrichten an das Team mit der Bitte um Hilfe (o. ä.) sind nicht gestattet und führen zu einem 24 Stunden Ausschluss.
                    <br />
                    <br />
                    5.	Unhöfliche und/oder beleidigende Kommunikation in Richtung des Teams werden entsprechend geahndet. 
                    <br />
                    <br />
					5.	Rückerstattungen seitens des Teams müssen innerhalb von 24 Stunden per Ticket eingereicht werden. Es werden nur Rückerstattungen erfolgen, wenn der Verlust auf Grundlage von Bugs beruht. Hierzu sind Foto- und/ oder Videobeweise notwendig.
					<br />
					<br />
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                  <b>3 Ausschüsse & Sanktionen</b>
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
					1.	Alle Ausschlüsse und Maßnahmen werden in Teamsitzungen besprochen, beschlossen und mit Mehrheitsentscheidung umgesetzt.
                    <br />
                    <br />
                    2.	Alle Informationen, Entscheidungen und daraus resultierenden Maßnahmen werden mit den betroffenen Personen/ Gruppen/ Gewerbe vertraulich besprochen.
                    <br />
                    <br />
                    3.	Es werden nur dann Einzelheiten der Entscheidung an die gesamte Spielerschaft weitergegeben, wenn die betroffenen Personen/ Gruppen / Gewerbe diese Vertraulichkeit missachten.
                    <br />
                    <br />
                    4.	Bei Personen, welche falsche Informationen oder Halbwahrheiten verbreiten, kann aus Transparenzgründen der gesamte Ticketverlauf veröffentlicht werden. In schweren Fällen behält sich das Team vor, die Person dauerhaft aus der Community auszuschließen.
                    <br />
                    <br />
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                  4 Streaming & Aufnahmen
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    <b>1. Allgemeines</b><br />
                    1. Jeder Spieler hat generell die Befugnis unser Projekt auf öffentlichen Plattformen streamen oder aufzunehmen. Um einen Überblick über unsere öffentliche Darstellung zu behalten, bitten wir darum eine unverbindliche Creator-Rolle per Ticket beim Support anzumelden<br />
                    2. Durch das Spielen auf dem Server stimmt Ihr der Aufnahme und ggf. der Veröffentlichung von Ton- und Bildaufnahmen zu.<br />
                    3. In den gängigen Sprachkanälen ist das Streamen nur mit Einverständnis aller Beteiligten erlaubt. <br />
                    4. In einem Support-Gespräch ist der Stream unverzüglich stumm zu schalten! Jegliche Aufnahmen sind gleichermaßen verboten.<br />
                    5. Jeder Streamer ist für sich selbst verantwortlich.<br />
                    6. Jeder Streamer sollte bestimmte Regeln in seinem Chat aufrechterhalten, wie z.B. gegen Meta oder BSG. <br />
                    7. VODs, Clips oder der Live-Chat können als Videobeweis vom Support geahndet werden. <br />
                    <br />
                    <br />
                    <b>2. Content Creator</b><br />
                    1. Jedes Mitglied der Community hat das Recht eine Creator-Rolle anzufordern.<br />
                    2. Beim Innehaben einer Creator-Rolle ist es besonders wichtig, ein regelkonformes und gutes RP-Erlebnis nach außen hin zu bieten.<br />
                    3. Mitglieder mit der Rolle Content Creator haben das Recht, Inhalte von unserem Projekt zu erstellen und zu teilen, wobei sie weiterhin die Regeln und Richtlinien des Servers befolgen müssen.<br />
                    4. Die Rolle Verified Creator kann beim Team angefragt werden und wird unter folgenden Kriterien vergeben: <br />
                        a. Mindestens zwei Streams oder Uploads pro Woche, <br />
                        b. Interesse an aktivem Austausch mit den anderen Creatoren, <br />
                        c. Bedacht auf eine positive Außendarstellung des Projekts und der Marke TWH.<br />
                    5. Um eine Verified Creator Rolle zu beantragen, muss der Discord-Account des Mitglieds eindeutig mit dem jeweiligen Streaming- oder Upload-Kanal verknüpft oder vermerkt sein.<br />
                    6. Bei der freiwilligen Nutzung der Panel-Grafik bitten wir darum, die Website https://western-hills.de/ oder den Discord https://discord.gg/western-hills zu verlinken. <br />
                    7. Das Team behält sich das Recht vor, die Verified Creator Rolle bei Inaktivität oder Verstößen gegen die Regeln wieder zu entziehen. In solchen Fällen kann auch die Content Creator Rolle entzogen und das Erstellen von Inhalten auf dem Server vorerst untersagt werden. <br />
                    <br />
                    <br />
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
