/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function SocialAnalytics() {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          lg={6}
          sx={{ mx: "auto", pb: 3, textAlign: "center" }}
        >
          <MKTypography variant="h4" color="info" textGradient>
            Ausschreibungen
          </MKTypography>
          <MKTypography variant="h2" my={1}>
            Starte deine Reise im Team
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Du suchst nach einer neuen Herausforderung? Hier findest du unsere aktuell ausgeschriebenen Stellen. Neben einem starken Fokus auf Austausch und Integration legen wir als Unternehmen großen Wert auf Zusammenhalt und Weiterentwicklung.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              icon="groups"
              title="Das Team"
              description="Ein freundliches und motiviertes Team, welches Wert auf Zusammenarbeit und Eigenverantwortung legt"
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              icon="school"
              title="Weiterentwicklung"
              description="Möglichkeiten zur persönlichen und fachlichen Weiterentwicklung"
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ mx: "auto" }}>
            <DefaultInfoCard
              icon="workspaces"
              title="Organisation"
              description="Eine gut organisierte und strukturierte Arbeitsweise sorgt für eine flache Hirarchie"
              direction="center"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default SocialAnalytics;
