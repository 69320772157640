/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
// import MKDatePicker from "components/MKDatePicker";
import MKButton from "components/MKButton";

import { useState, useEffect, useRef } from "react";

function Search({ onTagSelect }) {
  const [selectedTag, setSelectedTag] = useState('News'); // default value
  return (
    <MKBox component="section">
      <Container>
        <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}>
          <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
            <MKTypography display="block" variant="button" fontWeight="regular" color="text" mb={1}>
              Tag
            </MKTypography>
            <Autocomplete
              value={selectedTag}
              onChange={(event, newValue) => setSelectedTag(newValue)}
              options={["News", "Patchnote", "Tutorial"]}
              renderInput={(params) => <MKInput {...params} variant="standard" />}
            />
          </Grid>
          <Grid item xs={12} lg={3} sx={{ mt: 4 }}>
            <MKButton onClick={() => onTagSelect(selectedTag)} variant="gradient" color="info" fullWidth>
              Suchen
            </MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Search;
