// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import { filterRoutesByRole } from 'routes';
import loading from "components/Loading";
import { useState, useEffect } from "react";
import { SignOutButton, SignInButton, ClerkProvider, useUser,useAuth , SignedOut, SignIn} from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

function Privacy() {
  const { userId, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);

  useEffect(() => {
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        //console.log(data);
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);

  const filteredRoutes = filterRoutesByRole(routes, userRoles);
  return (
    <>
      <DefaultNavbar
        routes={filteredRoutes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "dark",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
  <Container>
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Card>
          <MKBox
            variant="gradient"
            bgColor="dark"
            borderRadius="lg"
            coloredShadow="dark"
            p={3}
            mt={-3}
            mx={2}
          >
            <MKTypography variant="h3" color="white">
              Gesetzbuch
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Zuletzt aktualisiert am 19.12.2023
            </MKTypography>
          </MKBox>
          <MKBox pb={6} px={6}>
            <MKTypography variant="h5" mt={6} mb={3}>
              §1 BELEIDIGUNG
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Die Beleidigung wird mit einer Geldstrafe bis zu 5$ bestraft.
              <br />
              <br />
              <b>2.</b> Wird eine Beleidigung nach Abs. 1 gegenüber einem Beamten auf Grundlage des Dienstes begangen, wird diese mit einer Geldstrafe bis zu 7$ bestraft.
              <br />
              <br />
              <b>3.</b> Wiederholte Beleidigungen können mit einem höheren Bußgeld bestraft werden.
              <br />
              <br />
              <b>4.</b> Als Beleidigung gelten auch rassistische oder diskriminierende Äußerungen.
              <br />
              <br />
              <b>5.</b> Der Straftatbestand gilt als Antragsdelikt. Die betroffene Person muss aktiv um rechtliche Verfolgung bitten.
              <br />
              <br />
              <b>6.</b> Eine Beleidigung durch einen Amtsträger wird aktiv verfolgt und wird mit 10$ bestraft.
              <br />
              <br />
            </MKTypography>
            {/* Paragraph §2 UNTERLASSENE HILFELEISTUNG */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §2 UNTERLASSENE HILFELEISTUNG
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer bei Unglücksfällen oder gemeiner Gefahr oder Not nicht Hilfe leistet, obwohl dies erforderlich und ihm den Umständen nach zuzumuten, insbesondere ohne erhebliche eigene Gefahr und ohne Verletzung anderer wichtiger Pflichten möglich ist, wird mit einer Geldstrafe bis zu 10$ bestraft.
              <br />
              <br />
              <b>2.</b> Wer in einer Situation eine Person aktiv und wissentlich behindert, die einem Dritten Hilfe leistet oder leisten will, wird mit Freiheitsstrafe bis zu 15 HE oder einer Geldstrafe bis zu 15$ bestraft.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §3 KÖRPERVERLETZUNG */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §3 KÖRPERVERLETZUNG
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer eine andere Person mutwillig körperlich misshandelt oder an der Gesundheit schädigt, wird mit einer Geldstrafe bis zu 20$ bestraft.
              <br />
              <br />
              <b>2.</b> Wer eine andere Person im besonders schweren Fall körperlich misshandelt oder an der Gesundheit schädigt, wird mit einer Freiheitsstrafe bis zu 20 HE oder einer Geldstrafe bis zu 30$ bestraft.
              <br />
              <br />
              <b>3.</b> Wer die Tat fahrlässig begeht, wird mit einer Geldstrafe bis zu 15$ bestraft.
              <br />
              <br />
              <b>4.</b> Der Versuch ist strafbar.
              <br />
              <br />
              <b>5.</b> Zivilisten sowie Amtsträger ist es untersagt Häftlingen oder in gewahrsam befindliche Personen, Leid anzutun. Dies wird mit einer Freiheitsstrafen von 40 HE oder einer Geldstrafe von 60$ bestraft.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §4 Lebensbedrohliche Körperverletzung */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §4 Lebensbedrohliche Körperverletzung
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer durch Fahrlässigkeit das Leben eines Menschen gefährdet und in einen komatösen Zustand versetzt, wird mit Freiheitsstrafe bis zu 30 HE und mit Geldstrafe bis zu 100$ bestraft.
              <br />
              <br />
              <b>2.</b> Bei Wiederholung sowie besonders schweren absichtlichen Fällen, kann die Todesstrafe ausgesprochen werden.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §5 Mord */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §5 Mord
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Der Mörder wird mit einer Freiheitsstrafe bis zu 120 HE oder einer Geldstrafe von 200$ bestraft. Im besonders schwierwiegenden Fällen, droht die Todesstrafe. Mörder ist, wer
              a. aus Mordlust, Habgier oder sonstigen niedrigen Beweggründen, oder
              b. heimtückisch oder grausam oder mit gemeingefährlichen Mitteln
              oder
              c. um eine andere Straftat zu ermöglichen oder zu verdecken
              einen Menschen tötet.
              <br />
              <br />
              <b>2.</b> Die Haftstrafe ist auf Sisika abzusitzen
              <br />
              <br />
              <b>3.</b> Der Versuch ist der Tat gleichgestellt.
              <br />
              <br />
            </MKTypography>

                        {/* Paragraph §6 Totschlag */}
                        <MKTypography variant="h5" mt={6} mb={3}>
              §6 Totschlag
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer einen Menschen tötet, ohne Mörder zu sein, wird als Totschläger mit einer Freiheitsstrafe bis zu 40 HE oder einer Geldstrafe bis zu 100$ bestraft.
              <br />
              <br />
              <b>2.</b> In besonders schweren Fällen ist eine Freiheitsstrafe von 50 HE und 170$ maßgebend.
              <br />
              <br />
              <b>3.</b> Der Versuch ist strafbar.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §7 Diebstahl */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §7 Diebstahl
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer eine fremde bewegliche Sache von einem anderen in der Absicht wegnimmt, die Sache sich oder einem Dritten rechtswidrig zuzueignen, wird mit einer Geldstrafe in Höhe des Wertes des Diebesgutes plus einer Strafzahlung von 10$ belangt. Die Rückgabe der Sache ist verpflichtend.
              Lässt sich die Sache nicht an den ursprünglichen Besitzer zurückgeben, ist der Wert der Sache an diesen auszuhändigen.
              <br />
              <br />
              <b>2.</b> Ein besonders schwerer Fall des Diebstahls liegt vor, wenn eine Sache gestohlen wird, die durch ein verschlossenes Behältnis oder eine andere Schutzvorrichtung gegen Wegnahme besonders gesichert ist. In diesem Fall fällt das doppelte Bußgeld an.
              <br />
              <br />
              <b>3.</b> Der Versuch ist strafbar.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §8 Raub */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §8 Raub
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer mit Gewalt gegen eine Person oder unter Anwendung von Drohung mit gegenwärtiger Gefahr für Leib oder Leben eine fremde bewegliche Sache einem anderen in der Absicht wegnimmt, die Sache sich oder einem Dritten rechtswidrig zuzueignen, wird mit einer Freiheitsstrafe bis zu 30 HE oder einer Geldstrafe von 60$ bestraft. 
              Die Rückgabe der Sache ist verpflichtend.
              Lässt sich die Sache nicht an den ursprünglichen Besitzer zurückgeben, ist der Wert der Sache an diesem auszuhändigen.
              <br />
              <br />
              <b>2.</b> Der Versuch ist strafbar.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §9 Freiheitsberaubung - Entführung */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §9 Freiheitsberaubung - Entführung
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer einen Menschen einsperrt oder auf eine andere Weise der Freiheit beraubt, wird mit einer Freiheitsstrafe bis zu 45 HE und mit einer Geldstrafe bis zu. 100$ bestraft.
              <br />
              <br />
              <b>2.</b> Wenn der Täter durch die Tat oder während der Tat durch begangene Handlung die körperliche Unversehrtheit des Opfers beeinträchtigt, liegt ein besonders schwerer Fall der Freiheitsberaubung vor. Diese wird mit einer Freiheitsstrafe bis zu. 50 HE oder einer Geldstrafe von 130$ bestraft.
              <br />
              <br />
              <b>3.</b> Der Versuch ist strafbar.
              <br />
              <br />
            </MKTypography>

                        {/* Paragraph §10 Geiselnahme */}
                        <MKTypography variant="h5" mt={6} mb={3}>
              §10 Geiselnahme
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer einen Menschen entführt oder sich eines Menschen bemächtigt, um Ihn oder einen Dritten durch die Drohung mit dem Tod oder einer schweren Körperverletzung des Opfers zu einer Handlung, Duldung oder Unterlassung zu nötigen, wird mit einer Freiheitsstrafe von 45 HE oder einer Geldstrafe bis zu 100$ bestraft.
              <br />
              <br />
              <b>2.</b> Ein besonders schwerer Fall der Geiselnahme liegt vor, wenn die Geisel durch die Handlung der Geiselnehmer zu Tode kommt. In diesem Fall ist der Täter mit einem Freiheitsentzug von 50 HE oder einer Geldstrafe bis zu 130$ zu bestrafen.
              <br />
              <br />
              <b>3.</b> Der Versuch ist strafbar.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §11 Betrug */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §11 Betrug
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer in der Absicht, sich oder einem Dritten einen rechtswidrigen Vermögensvorteil zu verschaffen, das Vermögen eines anderen dadurch beschädigt, dass er durch Vorspielen falscher Tatsachen einen Irrtum erregt, wird mit einer Freiheitsstrafe von 20 HE oder einer Geldstrafe bis zu 30$ bestraft. Das geminderte Vermögen ist zu ersetzen.
              <br />
              <br />
              <b>2.</b> Wer die Straftat gemäß §11 Abs. 1 als Gewerbetreibender begeht, wird mit einer Haftstrafe bis zu 30 HE oder einer Geldstrafe von 50 $ bestraft. Das geminderte Vermögen ist zu ersetzen. In schweren Fällen kann auch die Lizenz des Gewerbes entzogen werden.
              <br />
              <br />
              <b>3.</b> Der Versuch ist strafbar.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §12 Vorteilsgewährung, Bestechung (Amtsträger) */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §12 Vorteilsgewährung, Bestechung (Amtsträger)
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Amtsträger sind alle Beschäftigten der Judikative und Exekutive sowie Legislative.
              <br />
              <br />
              <b>2.</b> Wer einem Amtsträger einen Vorteil für diesen oder einen Dritten als Gegenleistung dafür anbietet oder verspricht, dass er eine Diensthandlung vorgenommen hat oder künftig wird und dadurch seine Dienstpflichten verletzt hat oder verletzen würde, wird mit einer Freiheitsstrafe bis zu 30 HE oder einer Geldstrafe bis zu 50$ bestraft.
              <br />
              <br />
              <b>3.</b> Der Versuch ist strafbar.
              <br />
              <br />
            </MKTypography>

                        {/* Paragraph §13 Bestechlichkeit, Vorteilsannahme */}
                        <MKTypography variant="h5" mt={6} mb={3}>
              §13 Bestechlichkeit, Vorteilsannahme
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Ein Amtsträger, der für die Dienstausübung einen Vorteil für sich oder einen Dritten fordert, sich versprechen lässt oder annimmt, wird mit Freiheitsstrafe bis zu 30 HE oder einer Geldstrafe bis zu 100$ bestraft.
              <br />
              <br />
              <b>2.</b> Der Amtsträger wird in jedem Fall aus dem Dienst entlassen.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §14 Hausfriedensbruch */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §14 Hausfriedensbruch
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer in die Wohnung, das im Eigentum befindliche Land oder die Geschäftsräume eines anderen, als Sperrzone deklarierte Gebiete oder ähnliche Gebiete widerrechtlich eindringt, oder wer ohne Befugnis darin verweilt, auf die Aufforderung des Eigentümers oder Berechtigten Personen sich nicht entfernt, wird mit einer Geldstrafe bis zu 10$ bestraft.
              <br />
              <br />
              <b>2.</b> Die Tat kann ausschließlich auf Antrag verfolgt werden.
              <br />
              <br />
              <b>3.</b> Bei Wiederholung kann eine zusätzliche Haftstrafe bis zu 10 HE vollstreckt werden.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §15 Missbrauch von Titeln, Berufsbezeichnungen und Abzeichen */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §15 Missbrauch von Titeln, Berufsbezeichnungen und Abzeichen
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer sich unbefugt als Amtsträger wie z.B. Sheriff, Senator, Arzt, Bürgermeister oder Minister ausgibt, wird mit einer Freiheitsstrafe bis zu 20 HE oder einer Geldstrafe bis zu 30$ bestraft.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §16 Sachbeschädigung */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §16 Sachbeschädigung
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer mutwillig eine fremde Sache beschädigt oder zerstört, wird neben dem Sachwert dieser, mit einer Geldstrafe bis zu 10$ bestraft.
              <br />
              <br />
            </MKTypography>

                        {/* Paragraph §17 Widerstand gegen Vollstreckungsbeamte */}
                        <MKTypography variant="h5" mt={6} mb={3}>
              §17 Widerstand gegen Vollstreckungsbeamte
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer einen Amtsträger, der zur Vollstreckung von Gesetzen, Urteilen oder Gerichtsbeschlüssen berufen ist, bei der Vornahme einer solchen Diensthandlung mit Gewalt oder durch Drohung mit Gewalt Widerstand leistet, wird mit Freiheitsstrafe bis zu 5 HE oder einer Geldstrafe bis zu 5$ bestraft.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §18 Strafvereitelung */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §18 Strafvereitelung
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer absichtlich oder wissentlich ganz oder zum Teil vereitelt, dass ein anderer dem Strafgesetz gemäß wegen einer rechtswidrigen Tat bestraft oder einer Maßnahme unterworfen wird, wird mit einer Geldstrafe bis zu 10$ oder einer Freiheitsstrafe von 10 HE bestraft.
              <br />
              <br />
              <b>2.</b> Das Vertuschen oder Vorenthalten von Informationen, welche zur Vereitelung oder Vertuschung einer Straftat geführt haben, werden mit einer Geldstrafe bis zu 10$ oder einer Freiheitsstrafe von 10 HE bestraft.
              <br />
              <br />
              <b>3.</b> Geistliche sind im Rahmen der Beichte davon ausgenommen.
              <br />
              <br />
              <b>4.</b> Anwälte sind im Rahmen ihres Mandats davon ausgenommen.
              <br />
              <br />
              <b>5.</b> Der Versuch ist strafbar.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §19 Meineid */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §19 Meineid
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer vor Gericht unter Eid eine falsche Aussage tätigt, wird mit einer Freiheitsstrafe bis zu 30 HE oder einer Geldstrafe bis zu 100$ bestraft.
              <br />
              <br />
              <b>2.</b> Zugelassenen Anwälten wird die Lizenz entzogen.
              <br />
              <br />
            </MKTypography>

                        {/* Paragraph §20 Bedrohung */}
                        <MKTypography variant="h5" mt={6} mb={3}>
              §20 Bedrohung
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer einen Menschen mit der Begehung eines gegen ihn oder eine ihm nahestehenden Person gerichteten Verbrechens bedroht, wird mit einer Geldstrafe bis zu 10$ bestraft.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §21 Öffentlicher Waffengebrauch */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §21 Öffentlicher Waffengebrauch
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Das Abfeuern von Schusswaffen in Städten oder auf Farmen ohne rechtfertigenden Grund ist strafbar und wird mit einer Geldstrafe von 5$ bestraft.
              <br />
              <br />
              <b>2.</b> Bei Wiederholungstätern können die Waffen eingezogen werden.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §22 Notwehr */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §22 Notwehr
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer in einer gegenwärtigen, nicht anders anwendbaren Gefahr für Leben, Freiheit, Eigentum oder ein anderes Rechtsgut eine Tat begeht, um die Gefahr von sich oder einem anderen abzuwenden, handelt nicht rechtswidrig.
              <br />
              <br />
            </MKTypography>

                        {/* Paragraph §23 Mittäterschaft */}
                        <MKTypography variant="h5" mt={6} mb={3}>
              §23 Mittäterschaft
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Als Täter wird bestraft, wer die Straftat selbst oder durch einen anderen begeht. Begehen mehrere die Tat gemeinsam und gemeinschaftlich, so wird jeder als Haupttäter bestraft.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §24 Wiederholungstäter */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §24 Wiederholungstäter
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer wiederholt durch gleiche Straftaten auffällt, wird als Wiederholungstäter angesehen. Einem Wiederholungstäter kann von einem Richter ein höheres Strafmaß als das gesetzlich festgelegte auferlegt werden. Dies kann auch über das maximale Strafmaß hinausgehen.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §25 Identitätsdiebstahl */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §25 Identitätsdiebstahl
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer sich unrechtmäßig als eine andere Person ausgibt, um Straftaten zu begehen oder der betroffenen Person, von der die Identität gestohlen wird, einen Nachteil zu verschaffen, wird mit einer Geldstrafe bis zu 10$ bestraft.
              <br />
              <br />
              <b>2.</b> Von einem besonders schweren Fall kann ausgegangen werden, wenn der Identitätsdiebstahl zu einem massiven Nachteil für das Opfer des Identitätsdiebstahls geführt hat. In diesem Fall wird eine Freiheitsstrafe von 20 HE oder eine Geldstrafe bis zu 50$ verhängt.
              <br />
              <br />
            </MKTypography>

                        {/* Paragraph §26 Erpressung */}
                        <MKTypography variant="h5" mt={6} mb={3}>
              §26 Erpressung
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer einen Menschen rechtswidrig mit Gewalt oder durch Drohung mit einem empfindlichen Übel zu einer Handlung, Duldung oder Unterlassung nötigt und dadurch dem Vermögen des Genötigten oder eines anderen Nachteil zufügt, um sich oder einen Dritten zu Unrecht zu bereichern, wird mit einem Bußgeld bis zu. 30$ oder einer Freiheitsstrafe von 30 HE bestraft.
              <br />
              <br />
              <b>2.</b> Das Bußgeld der Straftat, welche durch den Genötigten begangen wurde, ist bei besonders schwerer Nötigung gemildert zu bestrafen. Zusätzlich ist die Strafe der begangenen Straftat des Genötigten auf die Strafe des Erpressers zu rechnen.
              <br />
              <br />
              <b>3.</b> Der Versuch ist strafbar.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §27 Töten von Nutztieren und Wildtieren */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §27 Töten von Nutztieren und Wildtieren
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer unabhängig seiner Beweggründe ein Nutztier, welches sich im Schutzbereich seines Geheges, Weide oder Ähnliches befindet, tötet, wird mit einer Geldstrafe bis zu. 10$ bestraft. Zusätzlich muss der Täter den Wert der Nutztiere erstatten.
              <br />
              <br />
              <b>2.</b> In den Städten ist das Nutzen eines Lassos zum Transport von Wildtieren untersagt. Ein Verstoß wird mit einer Geldstrafe bis zu. 10 $ geahndet.
              <br />
              <br />
              <b>3.</b> Das mutwillige Töten von Pferden ist verboten. Es gilt das Bußgeld von §27 Abs. 1.
              <br />
              <br />
              <b>4.</b> Das gezielte Jagen und Erlegen von Raub- und besonders schützenswerten Tieren ist nach Angabe ohne einen Auftrages eines Amtsträgers untersagt.
              <br />
              <br />
              <b>5.</b> Das Jagen von Büffeln sowie Bisons ist zu jeder Zeit untersagt. Wird ein Büffel dennoch gejagt, wird ein Bußgeld in Höhe von 100$ fällig.
              <br />
              <br />
            </MKTypography>

                        {/* Paragraph §28 Gleichstellung der ehrenamtlichen Amtsträger */}
                        <MKTypography variant="h5" mt={6} mb={3}>
              §28 Gleichstellung der ehrenamtlichen Amtsträger
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Ehrenamtliche Amtsträger wie Senatoren oder Bürgermeister sind den staatlich Angestellten gleichgestellt. Sie fallen somit ebenfalls unter die in §12 und §13 genannten Personen.
              <br />
              <br />
              <b>2.</b> Dies gilt auch für Abgeordnete, sollten sie auf das ihnen zustehende Gehalt verzichten und somit keine staatlichen Angestellten sein.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §29 Fahrlässige Nutzung von Reittieren und Zugtieren */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §29 Fahrlässige Nutzung von Reittieren und Zugtieren
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Das schnelle Reiten in Städten ist untersagt und wird mit einer Geldstrafe von 5$ bestraft.
              <br />
              <br />
              <b>2.</b> Reittiere müssen in den Städten angeleint oder in eine örtliche Obhut gebracht werden. Andernfalls wird eine Geldstrafe von 5$ verhängt.
              <br />
              <br />
              <b>3.</b> Das mutwillige Blockieren von Straßen mit Kutschen und das schnelle Fahren mit diesen innerhalb der Stadt kann mit einer Geldstrafe von 5$ geahndet werden.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §30 Maskierungen */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §30 Maskierungen
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer versucht, in Städten, Farmen, sowie Siedlungen, sowie den dazu gehörigen Gebieten seine Identität mittels Maskierung zu verbergen, wird mit einer Geldstrafe von 5$ bestraft.
              <br />
              <br />
              <b>2.</b> Im Wiederholungsfall kann ein höheres Bußgeld verhängt werden.
              <br />
              <br />
            </MKTypography>

                        {/* Paragraph §31 Nichtzahlung öffentlicher Rechnungen / Bußgelder */}
                        <MKTypography variant="h5" mt={6} mb={3}>
              §31 Nichtzahlung öffentlicher Rechnungen / Bußgelder
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Bürger sind verpflichtet, nach Möglichkeit ihre Rechnungen direkt vor Ort zu zahlen. Wer dies nicht kann und auch sonst zahlungsunfähig ist, muss die Rechnungssumme im Rahmen gemeinnütziger Arbeit abarbeiten.
              <br />
              <br />
              <b>2.</b> Diese kann in besonderen Fällen auch bei zahlungsfähigen Bürgern sowie Amtsträgern verhangen werden.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §32 Hochverrat */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §32 Hochverrat
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer nachweislich plant oder unternimmt, mit Gewalt oder durch Drohung mit Gewalt ...<br />
              a. das Gebiet eines Landes ganz oder zum Teil einem anderen Land des Countys einzuverleiben oder einen Teil eines Landes von diesem abzutrennen oder<br />
              b. die auf der Verfassung eines Landes beruhende verfassungsmäßige Ordnung anzugreifen oder<br />
              c. versucht die gewählten oder eingesetzten Vertreter des Staates zu stürzen, töten oder<br />
              d. jene unterstützt, egal ob aktiv oder passiv, solche und ähnliche Ziele zu verfolgen respektive umzusetzen,<br />
              <br />
              wird mit mindestens 360 HE, oder möglicher lebenslanger Haft bestraft. Für Soldaten oder Gesetzeshüter, Politiker sowie öffentlich bestellte Würdenträger führt eine Zuwiderhandlung unweigerlich zu einer härteren Bestrafung. Eine Bestrafung führt zum Verlust des Stimm- und Wahlrechtes, dem Verlust seiner Stellung und etwaig bekleideter Ämter. Jeglicher persönlicher Besitz wird enteignet und geht in den Besitz des Staates über
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §33 Besitz/Handel mit illegalen Gegenständen */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §33 Besitz/Handel mit illegalen Gegenständen
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Der Besitz und Handel mit illegalen Gegenständen ist untersagt. Als illegal deklariert ist:<br />
              a. Moonshine <br />b. Falsche Banknoten <br />c. Kokain <br />d. Sumpfgras <br />e. Opium <br />f. Heroin<br /><br />
              <b>2.</b> Rum darf nur in legalen, lizensierten Brauereien, bzw. Brennerei hergestellt werden.<br /><br />

              <b>3.</b> Mittel, die ärztlich verschrieben sind, erfordern eine ärztliche Verschreibung.<br /><br />
                    
              <b>4.</b> Gegenstände, die von einem Arzt zur Durchführung seiner Arbeit benötigt werden, sind hiervon ausgenommen.<br /><br />
                  
              <b>5.</b> Ein Verstoß wird mit einer Haftstrafe bis zu 30 HE oder einer Geldstrafe bis zu 30$ geahndet.<br /><br />
                  
              <b>6.</b> Der Versuch ist strafbar.

              <br />
              <br />
            </MKTypography>

                        {/* Paragraph §34 Bankraub */}
                        <MKTypography variant="h5" mt={6} mb={3}>
              §34 Bankraub
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Als Bankraub wird der Raub bzw. Diebstahl des Geldes, welches sich in Banken und deren Tresoren befindet, angesehen. Die Handlung ist strafbar und wird mit einer Freiheitsstrafe von 90 HE oder einer Geldstrafe bis zu 75$ bestraft.
              <br />
              <br />
              <b>2.</b> Ein Bankraub, in Verbindung mit der Bedrohung nach §20 oder einer Geiselnahme nach §10 begangen wird, gilt als schwerer Bankraub und wird mit einer Freiheitsstrafe von 120 HE oder einer Geldstrafe bis zu 150$ bestraft.
              <br />
              <br />
              <b>3.</b> Der Versuch ist strafbar.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §35 Grabraub */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §35 Grabraub
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Als Grabraub wird der Raub bzw. der Diebstahl von Grabbeigaben, welche sich in, um und auf freien Grabstätten sowie Friedhöfen befinden, angesehen. Grabraub wird mit einer Geldstrafe von 30$ oder 30 HE geahndet.
              <br />
              <br />
              <b>2.</b> Die Schändung eines Grabes wird nach §35 Abs. 1 bestraft.
              <br />
              <br />
              <b>3.</b> In besonders schweren Fällen kann eine höhere Strafe verhangen werden.
              <br />
              <br />
              <b>4.</b> Der Versuch ist strafbar.
              <br />
              <br />
            </MKTypography>

            {/* Paragraph §36 Nähe zu kriminellen Vereinigungen */}
            <MKTypography variant="h5" mt={6} mb={3}>
              §36 Nähe zu kriminellen Vereinigungen
            </MKTypography>
            <MKTypography variant="body2" color="text">
              <b>1.</b> Wer als gesetzestreuer Bürger wiederholt die Nähe zu Gesetzlosen sucht, um sich selbst oder Dritten einen Vorteil oder anderen einen Nachteil zu verschaffen, oder um diesen Aufträge zu geben, wird mit dem Verlust des Stimm- und Wahlrechts, dem Verlust seiner fraktionsinternen Stellung und bekleideter Ämter bestraft. Jeglicher gewerblicher Besitz wird beschlagnahmt und geht in den Besitz des Staates über.
              <br />
              <br />
              <b>2.</b> Im besonders schweren Fall kann das Privateigentum beschlagnahmt werden und in staatliches Eigentum übergehen.
              <br />
              <br />
            </MKTypography>

            {/* Weitere Paragraphen im gleichen Format */}












          </MKBox>
        </Card>
      </Grid>
    </Grid>
  </Container>
</MKBox>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
