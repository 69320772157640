/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
// rellax
import Rellax from "rellax";


import JsxParser from 'react-jsx-parser';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Patchnotes from "pages/Blogs/PreviewArticle/sections/Information";
import Steps from "pages/Blogs/SingleArticle/sections/Steps";
import OurEfforts from "pages/Blogs/SingleArticle/sections/OurEfforts";
import Article from "pages/Blogs/PreviewArticle/sections/Features";
import Posts from "pages/Blogs/SingleArticle/sections/Posts";
import Support from "pages/Blogs/SingleArticle/sections/Support";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";



import { useState, useEffect, useRef, React } from "react";
import { useParams } from 'react-router-dom';

// Images
import bgImage from "assets/images/webpage-twh.webp";

import { useLocation } from 'react-router-dom';

function formatTimestampToDDMMYYYY(timestamp) {
  const date = new Date(timestamp);

  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  const year = date.getUTCFullYear();

  return `${day}.${month}.${year}`;
}


const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
function SingleArticle() {

  return (
    <>
      <MKBox bgColor="white" py={0.25}>
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "https://www.creative-tim.com/product/material-kit-pro-react",
            label: "buy now",
            color: "success",
          }}
          transparent
          relative
        />
      </MKBox>
      
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            sx={{ mx: "auto", textAlign: "center" }}
          ><Container>
            <MKTypography
              variant="h2"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Patchnotes: Community&lsquo;s Call       
            </MKTypography>
            </Container>
            <MKTypography variant="body1" color="white" mt={1}>
              01.01.2022
            </MKTypography>
          </Grid>
      </MKBox>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/* <Patchnotes /> */}
        <Article />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default SingleArticle;
