export const json = {
  "locale": "de",
  "title": "Whitelist-Antrag",
  "description": "Lasst euch Zeit und lest euch alles gründlich durch! Umso ausführlicher desto besser.",
  
  "completedHtml": {
   "de": "<h3>Vielen Dank für deine Bewerbung!</h3>\n<p>Bitte bedenke, dass deine Bewerbung eine gewisse Zeit in Anspruch nehmen kann und Teammitglieder keineswegs ohne Aufforderung privat angeschrieben dürfen. Bei einer Absage gilt diese Entscheidung zu akzeptieren und es zu einem anderen Zeitpunkt nochmals zu versuchen. Ansonsten sehen wir uns dazu gezwungen darauffolgende Bewerbungen abzulehnen. Es darf nur eine Bewerbung pro Nutzer abgegeben werden!</p> <br /> <p>Vergewissere dich, dass auf unserem Discord ein Ticket für dich geöffnet wurde! Sollte dies nicht der Fall sein ist etwas schief gelaufen und du solltest den Support kontaktieren.</p>"
  },
  "pages": [
   {
    "name": "startpage",
    "title": {
      "default": "Starte deine Whitelist!",
     "de": "Starte deine Whitelist!"
    },
    "elements": [
      {
          "type": "panel",
          "elements": [
            {
              "type": "html",
              "name": "whitelist_intro",
              "html": "<article class='intro'><h4 class='intro__heading intro__heading--income title'>Bevor du los legst</h4><div class='intro__body wysiwyg'><p>Lasst euch genügend Zeit für eure Whitelist und lest alles <b>gründlich</b> durch</p><p>Umso ausführlicher und präziser eure Antworten ausfallen, umso besser. Beachtet zudem dabei folgende Dinge:</p><ul><li>Jede Form eines Plagiats führt zum direkten Ausschluss</li><li><p>Alle Antworten müssen ohne Fremde Hilfe abgegeben werden</p></li></ul></br><p>Ansonsten wünschen wir viel Erfolg!</p></div></article>"
            }
          ],
          "name": "panel_start"
      }
  ],
   },
   {
    "name": "page1",
    "elements": [
     {
      "type": "boolean",
      "name": "bl_age",
      "title": {
       "de": "Ich bin bereits über 18 Jahre alt."
      },
      "isRequired": true
     },
     {
      "type": "boolean",
      "name": "bl_roleplay_experience",
      "title": {
       "de": "Hast du bereits Roleplay Erfahrung?"
      },
      "isRequired": true,
      "commentText": {
       "de": "Inwiefern hast du bereits Erfahrungen mit Roleplay gemacht?"
      }
     },
     {
      "type": "text",
      "name": "txt_roleplay_experience",
      "visibleIf": "{bl_roleplay_experience} = true",
      "title": {
       "de": "Inwiefern hast du bereits Erfahrungen mit Roleplay gemacht?"
      }
     },
     {
      "type": "comment",
      "name": "txt_about_you",
      "title": {
       "de": "Über dich"
      },
      "description": {
       "de": "Erzähl uns etwas über dich. Ergänze deine Bewerbung auch gerne mit persönlichen Einschränkungen, falls das Team dies berücksichtigen sollte."
      },
      "isRequired": true
     },
     {
      "type": "radiogroup",
      "name": "rb_recognize",
      "title": {
       "de": "Wie bist du auf unseren Server aufmerksam geworden?"
      },
      "choices": [
       {
        "value": "Item 1",
        "text": {
         "de": "Website"
        }
       },
       {
        "value": "Item 2",
        "text": {
         "de": "Server-Listen"
        }
       },
       {
        "value": "Item 3",
        "text": {
         "de": "Stream"
        }
       },
       {
        "value": "Item 4",
        "text": {
         "de": "Empfehlung eines Freundes"
        }
       }
      ],
      "showOtherItem": true
     },
     {
      "type": "text",
      "name": "question3",
      "visibleIf": "{rb_recognize} = 'Item 3'",
      "title": {
       "de": "Von welchem Streamer kommst du?"
      }
     }
    ],
    "title": {
     "de": "Allgemeines"
    }
   },
   {
    "name": "page2",
    "elements": [
     {
      "type": "panel",
      "name": "personal-info",
      "elements": [
       {
        "type": "text",
        "name": "first-name",
        "title": {
         "default": "First name",
         "de": "Vorname"
        },
        "isRequired": true
       },
       {
        "type": "text",
        "name": "last-name",
        "startWithNewLine": false,
        "title": {
         "default": "Last name",
         "de": "Nachname"
        },
        "isRequired": true
       },
       {
        "type": "text",
        "name": "birthdate",
        "title": {
         "default": "Date of birth",
         "de": "Geburtsdatum"
        },
        "isRequired": true,
        "inputType": "date",
        "min": "1810-01-01",
        "max": "1881-01-01"
       },
       {
        "type": "comment",
        "name": "txt_charstory",
        "title": {
         "de": "Charakterstory"
        },
        "description": {
         "de": "Stelle uns nun deinen Charakter vor. Schreibe eine kurze Geschichte über ihn und vergesse dabei die Stärken und Schwächen deines Charakters nicht."
        },
        "isRequired": true
       },
       {
        "type": "dropdown",
        "name": "character_direction",
        "title": {
         "de": "Welchen Weg will dein Charakter einschlagen?"
        },
        "isRequired": true,
        "choices": [
         {
          "value": "Item 1",
          "text": {
           "de": "Outlaw"
          }
         },
         {
          "value": "Item 2",
          "text": {
           "de": "Krimineller"
          }
         },
         {
          "value": "Item 3",
          "text": {
           "de": "Zivilist"
          }
         },
         {
          "value": "Item 4",
          "text": {
           "de": "Staatsfraktion"
          }
         },
         {
          "value": "Item 5",
          "text": {
           "de": "Native"
          }
         }
        ],
        "showOtherItem": true
       },
       {
        "type": "html",
        "name": "role_description_outlaw",
        "visible": false,
        "visibleIf": "{character_direction} = 'Item 1'",
        "html": {
         "de": "<p>Der Outlaw ist eine Person, welche sich nicht an bestehende Gesetze hält und von der Gesellschaft geächtet wird.</p>"
        }
       },
       {
        "type": "html",
        "name": "role_description_criminal",
        "visible": false,
        "visibleIf": "{character_direction} = 'Item 2'",
        "html": {
         "de": "<p>Ein Kleinkrimineller ist jemand, welcher sich im Gegensatz zu Outlaws, Gedanken um seinen Stand in der Gesellschaft macht</p>"
        }
       },
       {
        "type": "html",
        "name": "role_description_civilist",
        "visible": false,
        "visibleIf": "{character_direction} = 'Item 3'",
        "html": {
         "de": "<p>Als Zivilist lebt es sich meist unbeschwert. Egal ob Unternehmer, Farmer, ein Schmied oder ein allgemein unbeschwerter Bürger. Die Türen des Staates stehen für die gesetzestreuen Bürger immer offen.</p>"
        }
       },
       {
        "type": "html",
        "name": "role_description_law",
        "visible": false,
        "visibleIf": "{character_direction} = 'Item 4'",
        "html": {
         "de": "<p>Sie haben sich zur Aufgabe gemacht, das Böse zu bekämpfen, die Banden im lande hinter Gitter zu bringen oder den Menschen im Land ärztlich beizustehen. Mit einem ausgeprägten Gerechtigkeitssinn kämpfen sie für das Gesetz, ihr Land und seine Bürger!</p>"
        }
       },
       {
        "type": "html",
        "name": "role_description_native",
        "visible": false,
        "visibleIf": "{character_direction} = 'Item 5'",
        "html": {
         "de": "<p>Verdrängt aus dem eigenen land kämpfen sie ums überleben. Langsam aber sicher ist der Krieg abgefacht und eine langsame Eingliederung in die Gesellschaft ist zuteilen denkbar.</p>"
        }
       }
      ],
      "title": {
       "default": "Personal Information",
       "de": "Persönliche Informationen"
      },
      "description": {
       "de": "Dein Charaktername darf nicht mit einem bekannten Charakter oder einer bekannten Person, einer historischen Figur, einer Comic-, Film- und Videospielfigur in Verbindung gebracht werden. Der Name deines Charakters, muss deine eigene Kreation sein und sollte mit Ernsthaftigkeit gewählt werden."
      }
     }
    ],
    "title": {
     "de": "Charakterbogen"
    },
    "description": {
     "de": "Stelle uns deinen Charakter vor, welchen du bespielen möchtest."
    }
   },
   {
    "name": "page3",
    "elements": [
     {
      "type": "comment",
      "name": "question1",
      "title": {
       "de": "Was versteht man unter \"Fail-RP\" und \"Power-RP\"?"
      },
      "description": {
       "de": "Nenne jeweils ein Beispiel für Fail-RP und Power-RP"
      },
      "isRequired": true
     },
     {
      "type": "comment",
      "name": "question4",
      "title": {
       "de": "Wie verhältst du dich, wenn ein Regelbruch im RP stattfindet?"
      },
      "description": {
       "de": "Gehe hierbei einmal davon aus, dass es ein dringendes Eingreifen notwendig ist und einmal davon, dass es bis nach dem RP warten kann."
      },
      "isRequired": true
     },
     {
      "type": "comment",
      "name": "question5",
      "title": {
       "de": "Wo schaust du zuerst nach Antworten, wenn du technische Probleme mit dem Spiel und dem TS hast?"
      },
      "isRequired": true
     },
     {
      "type": "comment",
      "name": "question6",
      "title": {
       "de": "Wie sind NPCs zu behandeln?"
      },
      "description": {
       "de": "Wie verhältst du dich gegenüber NPCs in der Spielwelt und wie sind diese zu betrachten."
      },
      "isRequired": true
     },
     {
      "type": "comment",
      "name": "question7",
      "title": {
       "de": "Was unterscheidet den Spielstil eines Outlaw-Spielers mit dem eines Zivilisten oder Kleinkriminellen?"
      },
      "description": {
       "de": "Versuche hier sehr deutlich klarzustellen, was die Alleinstellungsmerkmale der einzelnen Charaktere sind."
      },
      "isRequired": true
     },
     {
      "type": "panel",
      "name": "panel_gameknowlegde",
      "elements": [
       {
        "type": "text",
        "name": "question2",
        "title": {
         "de": "In welchem Jahr spielt \"The Western Hills\"?"
        },
        "isRequired": true
       },
       {
        "type": "text",
        "name": "question8",
        "title": {
         "de": "Was sind Sozialstufen?"
        },
        "isRequired": true
       },
       {
        "type": "text",
        "name": "question9",
        "title": {
         "de": "Auf welcher Taste befindet sich das Radialmenü und wo kann man seine Voice Range verändern?"
        },
        "description": {
         "de": "Gebe die jeweilige Taste an"
        },
        "isRequired": true
       }
      ],
      "title": {
       "de": "Spielverständnis"
      }
     }
    ],
    "title": {
     "de": "Regelwerk"
    },
    "description": {
     "de": "Fragen zum Einstig: Erwartet werden ausführliche Antworten im Bezug auf das Regelwerk und deinem Charakter."
    }
   },
   {
    "name": "page_szenarien",
    "elements": [
     {
      "type": "comment",
      "name": "question10",
      "title": {
       "de": "Du wirst auf dem Discord angeschrieben, dass dein Saloon gerade überfallen wird. Du bist nicht eingeloggt. Was tust du? Begründe auch warum."
      },
      "isRequired": true
     },
     {
      "type": "comment",
      "name": "question11",
      "title": {
       "de": "Während einer lautstarken und intensiven, verbalen Auseinandersetzung siehst du aus dem Augenwinkel, wie die Hand deines Gegenübers immer wieder Richtung seiner Waffe zuckt, während er dich anbrüllt und droht, dich zu töten. Plötzlich tritt dein Gegenüber ein paar Meter zurück, zieht seinen Revolver und richtet ihn auf dich. Darfst du dein Gegenüber nun regelkonform einfach niederschießen und wer darf sich an dieser Situation mit beteiligen?"
      },
      "description": {
       "de": "Auf den Wert des eigenen Lebens ist hier nicht einzugehen"
      },
      "isRequired": true
     },
     {
      "type": "comment",
      "name": "question12",
      "title": {
       "de": "Du reitest mit deiner Bande von 9 Outlaws abends am Fluss entlang. Plötzlich siehst du am Ufer zwei andere Spieler, die friedlich zusammen angeln. Ihr beschließt als Bande die Spieler auszurauben, zieht eure Waffen gemeinsam und richtet sie auf die Spieler, während ihr sie mit euren Pferden umstellt. Du raubst den Spielern ihr ganzes Geld, ihre Waffen und alles, was sie mit sich tragen, bedankst dich freundlich und verschwindest mit deiner Bande schnell vom Ereignisort. Ist in dieser Situation alles regelkonform verlaufen?"
      },
      "isRequired": true
     },
     {
      "type": "comment",
      "name": "question13",
      "title": {
       "de": "Du bist in der Stadt und jemand steigt am Ende der Straße auf dein Pferd und will wegreiten. Du weißt, dass du mit \"H\" dein Pferd rufen kannst und es kommt mit dem Reiter darauf sofort zurück. Was machst du nun?"
      },
      "description": {
       "de": "Bedenke bei deinen weiteren Handlungen auch die Charaktereigenschaften deines Charakters"
      },
      "isRequired": true
     },
     {
      "type": "comment",
      "name": "question14",
      "title": {
       "de": "Du erreichst gerade Valentine, als du Schüsse und laute Explosionen aus der Stadt hörst. Viele Menschen laufen panisch und schreiend durch die Gegend. Wie reagierst du in diesem Szenario?"
      },
      "isRequired": true
     },
     {
      "type": "comment",
      "name": "question15",
      "title": {
       "de": "Du stehst an einem See und genießt den Blick aufs Wasser. Hinter dir zielt ein Mann mit einem Bandana im Gesicht lautlos mit einer Waffe auf dich. Du siehst ihn nicht (3rd Person) und erst nachdem er mit dir spricht, reagierst du auf ihn. Während des Überfalls sprecht ihr miteinander, du drehst dich jedoch nicht um. Was tust du nun? Was darfst du später dem Sheriff beschreiben?"
      },
      "isRequired": true
     },
     {
      "type": "comment",
      "name": "question16",
      "title": {
       "de": "Du wurdest bei einer Schießerei getroffen und bist bewusstlos. Die Schießerei geht noch eine ganze Weile weiter. An was darfst du dich erinnern, nachdem der Arzt dich wieder aufgepäppelt hat?"
      },
      "isRequired": true
     },
     {
      "type": "comment",
      "name": "question17",
      "title": {
       "de": "Ein anderer Spieler kommt zu dir und möchte dir seine selbst geschnitzte Holzfigur zeigen und beschreibt dir diese. Danach drückt er Sie dir in die Hand. Du siehst dass er dir nur ein Stück Holz ins Inventar gesteckt hat. Wie verhältst du dich?"
      },
      "isRequired": true
     }
    ],
    "title": {
     "de": "Szenarien"
    },
    "description": {
     "de": "Nun geht es um dein RP-Verständnis und den Umgang mit deinem Charakter und den Regeln. \nDie Frage soll nach bestem Verständnis beantwortet werden, denn bei manchen Szenarien gibt es kein \"Richtig\" oder \"Falsch\"."
    }
   },
   {
    "name": "page4",
    "elements": [
     {
      "type": "checkbox",
      "name": "question18",
      "title": {
       "de": "Mit dem Absenden der Bewerbung versichere ich, dass ich folgendes zur Kenntnis genommen, gelesen und akzeptiert habe:"
      },
      "isRequired": true,
      "choices": [
       {
        "value": "Item 1",
        "text": {
         "de": "Allgemeines Regelwerk"
        }
       },
       {
        "value": "Item 2",
        "text": {
         "de": "Server Regelwerk"
        }
       },
       {
        "value": "Item 3",
        "text": {
         "de": "Roleplay Guide"
        }
       },
       {
        "value": "Item 4",
        "text": {
         "de": "Server-Wiki"
        }
       }
      ],
      "minSelectedChoices": 4
     }
    ],
    "title": {
     "de": "Abschluss"
    },
    "description": {
     "de": "Bestätigung der Anfrage"
    }
   }
  ],
  "showTitle": false,
  "showQuestionNumbers": "off",
  "questionErrorLocation": "bottom",
  "showProgressBar": "bottom",
  "showTOC": true,
  "pagePrevText": "Zurück",
  "pageNextText": "Weiter",
  "completeText": "Absenden",
  "firstPageIsStarted": true,
  "widthMode": "static",
  "width": "800px"
 }