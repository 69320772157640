/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

//Page sections
import Counters from "pages/Company/Donations/sections/Counters";
import Overview from "pages/Company/Donations/sections/costoverview";
import DonateButton from "pages/Company/Donations/sections/DonateButton";



// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/webpage-twh.webp";

import { filterRoutesByRole } from 'routes';
import loading from "components/Loading";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import { SignOutButton, useAuth, SignInButton, ClerkProvider, useUser, SignedOut, SignIn, RedirectToSignIn  } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

function userHasRole(userRoles, specifiedRoles) {
  return userRoles.some(role => specifiedRoles.includes(role));
}

const findAllowedRolesForRoute = (routePath, routes) => {
  for (const route of routes) {
    if (route.collapse) {
      const roles = findAllowedRolesForRoute(routePath, route.collapse);
      if (roles) return roles;
    } else if (route.route === routePath) {
      return route.allowedRoles;
    }
  }
  return null;
};

function Donations() {
  const navigate = useNavigate();
  const { userId, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);

  useEffect(() => {
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        //console.log(data);
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);

  const filteredRoutes = filterRoutesByRole(routes, userRoles);

  useEffect(() => {
    const currentPath = location.pathname;
    const allowedRoles = findAllowedRolesForRoute(currentPath, routes);
    if (!isLoading && !userHasRole(userRoles, allowedRoles)) {
      navigate('/');  // Navigate to home page
    }
  }, [isLoading,userRoles, navigate]);  // Dependencies

  if (isLoading) {
    return loading;  // Or some loading spinner
  }

  return (
    <>
      <DefaultNavbar
        routes={filteredRoutes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "white",
        }}
        transparent
        light
      />
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h2"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Spenden und Ausgaben
            </MKTypography>
            <MKTypography variant="body1" color="white" mt={1}>
              Um den Server und die Entwicklung zu finanzieren, sind wir auf Spenden angewiesen.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
        <Container>
          <Grid
            container
            item
            justifyContent="center"
            xs={12}
            lg={6}
            sx={{ mx: "auto", pb: 3, textAlign: "center" }}
          >
            <MKTypography variant="h2" my={1}>
              Aktuelle Zahlen 
            </MKTypography>
            <MKTypography variant="body1" color="text">
              Die aktuellen Zahlen innerhalb des laufenden Monats in der Übersicht.
            </MKTypography>
            <MKTypography variant="body1" color="text">
              (unbereinigt vor Gebühren)
            </MKTypography>
          </Grid>
        </Container>
        
        <Counters />

        
        <Container>
          <Grid
            container
            item
            justifyContent="center"
            xs={12}
            lg={6}
            sx={{ mx: "auto", pb: 3, textAlign: "center" }}
          >
            <MKTypography variant="h2" my={1}>
              Jahresübersicht
            </MKTypography>
            <MKTypography variant="body1" color="text">
              Die letzten 12 Monate der Spenden und Ausgaben im Detail.
            </MKTypography>
            <MKTypography variant="body1" color="text">
              (unbereinigt vor Gebühren)
            </MKTypography>
          </Grid>
        </Container>
        <Overview />


        <br />
        <DonateButton />
        <Container>
          {/* <Grid
            container
            item
            justifyContent="center"
            xs={12}
            lg={6}
            sx={{ mx: "auto", pb: 3, textAlign: "center" }}
          >
            <MKTypography variant="h2" my={1}>
              Wie kann ich Spenden?
            </MKTypography>
            <MKTypography variant="body1" color="text">
              So kannst du The Western Hills monetär unterstützen.
            </MKTypography>
          </Grid> */}
          
        </Container>
        
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Donations;
