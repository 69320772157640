/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import { useEffect, useState } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";
import Author from "layouts/pages/blogs/author";
import SignUpCoverPage from "layouts/authentication/sign-up/cover";
import SignInPage from "layouts/authentication/sign-in/basic";
import WhitelistView from "layouts/dashboard/whitelistView";
import WhitelistPage from "layouts/pages/support/whitelist";
import PrivacyPage from 'layouts/pages/support/privacy';

import SingleArticle from 'pages/Blogs/SingleArticle';

import Impressum from 'pages/Support/Impressum';

import sidenavRoutes from "sidenavRoutes";

import { deDE } from "@clerk/localizations";

// Material Kit 2 PRO React routes
import routes from "routes";



//dashboard stuff
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import themeDark from "assets/theme-dark";

import Icon from "@mui/material/Icon";
// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";


import { ClerkProvider } from "@clerk/clerk-react";



if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

function ClerkProviderWithRoutes() {
  const navigate = useNavigate();
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.name} />;
      }

      return null;
    });
  return (
    <ClerkProvider publishableKey={clerkPubKey} localization={deDE} navigate={(to) => navigate(to)}>
      <Routes key="routes">
        {getRoutes(routes)}
        {getRoutes(sidenavRoutes)}
        <Route key="home" path="/home" element={<Presentation />} />
        <Route key="profile" path="/profile" element={<Author />} />
        <Route key="register" path="/register" element={<SignUpCoverPage />} />
        <Route key="login" path="/login" element={<SignInPage />} />
        <Route key="impressum" path="/impressum" element={<Impressum />} />
        <Route key="redirectAll" path="*" element={<Navigate to="/home" />} />
        <Route key="article" path="/article/:id" element={<SingleArticle />} />
        <Route key="privacy" path="/privacy" element={<PrivacyPage />} />
        <Route key="whitelistView" path="/whitelistview/:id" element={<WhitelistView />} />
      </Routes>
    </ClerkProvider>
  );
}

export default function App() {
  const options = {
    // passing the client secret obtained from the server
  };
  const [controller, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);


  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      
        <CssBaseline />
        <ClerkProviderWithRoutes />

    </ThemeProvider>
  );
}
