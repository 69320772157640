// adjustments.js

const adjustments = {
  '4/2024': {
    income: 5,   // Example manual adjustment (+100 to income for August 2023)
    expenses: 246   // Example manual adjustment (+50 to expenses for August 2023)
  },
  '3/2024': {
    income: 25,   // Example manual adjustment (+100 to income for August 2023)
    expenses: 466   // Example manual adjustment (+50 to expenses for August 2023)
  },
  '2/2024': {
    income: 1,   // Example manual adjustment (+100 to income for August 2023)
    expenses: 516   // Example manual adjustment (+50 to expenses for August 2023)
  },
  '1/2024': {
    income: 1,   // Example manual adjustment (+100 to income for August 2023)
    expenses: 226   // Example manual adjustment (+50 to expenses for August 2023)
  },
  '12/2023': {
    income: 0,   // Example manual adjustment (+100 to income for August 2023)
    expenses: 326   // Example manual adjustment (+50 to expenses for August 2023)
  },
  '11/2023': {
    income: 150,   // Example manual adjustment (+100 to income for August 2023)
    expenses: 336   // Example manual adjustment (+50 to expenses for August 2023)
  },
  '10/2023': {
    income: 50,   // Example manual adjustment (+100 to income for August 2023)
    expenses: 246   // Example manual adjustment (+50 to expenses for August 2023)
  },
  '9/2023': {
    income: 50,   // Example manual adjustment (+100 to income for August 2023)
    expenses: 226   // Example manual adjustment (+50 to expenses for August 2023)
  },
  '8/2023': {
    income: 100,   // Example manual adjustment (+100 to income for August 2023)
    expenses: 226   // Example manual adjustment (+50 to expenses for August 2023)
  },
  '7/2023': {
    income: 398,   // Subtract 50 from income for July 2023
    expenses: 636   // Add 25 to expenses for July 2023
  },
  '6/2023': {
    income: 728,   // Subtract 50 from income for July 2023
    expenses: 516   // Add 25 to expenses for July 2023
  },
  '5/2023': {
    income: 283,   // Subtract 50 from income for July 2023
    expenses: 256   // Add 25 to expenses for July 2023
  },
  '4/2023': {
    income: 644,   // Subtract 50 from income for July 2023
    expenses: 580   // Add 25 to expenses for July 2023
  },
  '3/2023': {
    income: 529,   // Subtract 50 from income for July 2023
    expenses: 289   // Add 25 to expenses for July 2023
  },
  '2/2023': {
    income: 513,   // Subtract 50 from income for July 2023
    expenses: 306   // Add 25 to expenses for July 2023
  },
  '1/2023': {
    income: 432,   // Subtract 50 from income for July 2023
    expenses: 425   // Add 25 to expenses for July 2023
  },
  '12/2022': {
      income: 0,   // Subtract 50 from income for July 2023
      expenses: 1756   // Add 25 to expenses for July 2023
    },
  };
  
  export default adjustments;
  