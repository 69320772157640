/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
import Profile from "pages/Blogs/Author/sections/Profile";
import Posts from "pages/Blogs/Author/sections/Posts";
import Contact from "pages/Blogs/Author/sections/Contact";
import Footer from "pages/Blogs/Author/sections/Footer";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { filterRoutesByRole } from 'routes';

import DefaultFooter from "examples/Footers/DefaultFooter";

// Images
import bgImage from "assets/images/webpage-twh.webp";

import { useState, useEffect } from "react";

import {
  ClerkProvider,
  useAuth,
  SignedIn,
  SignedOut,
  UserProfile,
  useUser,
  RedirectToSignIn,
} from "@clerk/clerk-react";
import { useNavigate, BrowserRouter } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

function Author() {
  const { isLoaded, isSignedIn, user } = useUser();
  const { userId, sessionId, getToken } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);


  useEffect(() => {
    if(!userId || userId === "undefined") return;
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        //console.log(data);
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);
  
  const filteredRoutes = filterRoutesByRole(routes, userRoles);
  return (
    <>
    <SignedIn>
      <DefaultNavbar
        routes={filteredRoutes}
        action={{
          type: "external",
          route: "",
          label: "free download",
          color: "white",
        }}
        transparent
        light
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Profile />
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            sx={{ mx: "auto", textAlign: "center" }}
          ><UserProfile /></Grid>
          
        </Card>
        
          
        
        <MKBox pt={6} px={1} mt={6}>
         <DefaultFooter content={footerRoutes} />
        </MKBox>
      </MKBox>
    </SignedIn>
    <SignedOut>
      <RedirectToSignIn />
    </SignedOut>
    </>
  );
}
export default Author;
