/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


import { Triangle } from  'react-loader-spinner'


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleFooter from "examples/Footers/SimpleFooter";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

import Stack from "@mui/material/Stack";


import React from "react";
import { Navigate } from 'react-router-dom';
import { Model, surveyLocalization } from "survey-core";

import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { json } from "./components/survey.js";
import { themeJson } from "./components/theme.js";
import "survey-core/survey.i18n";
// Routes
import routes from "routes";

import clerk from "@clerk/clerk-react";

import { filterRoutesByRole } from 'routes';
import loading from "components/Loading";
import { useState, useEffect, useCallback  } from "react";
import { SignOutButton, SignInButton, ClerkProvider, useUser, useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;





function getProviderUserIdFromDiscordProvider(objects) {
  const discordObject = objects.find(obj => obj.provider === 'discord');
  //console.log(discordObject)
  return discordObject ? discordObject.providerUserId : null;
}

function Whitelist() {
  const [collapse, setCollapse] = useState(false);
  const {userId, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);
  const {isSignedIn, user, isLoaded } = useUser();
  const [userDiscordId, setUserDiscordId] = useState(null)
  const [userExternalAccounts, setUserExternalAccounts] = useState([])
  const [userIsInDiscord, setUserIsInDiscord] = useState(false)
  const [userDiscordData, setUserDiscordData] = useState(false)
  const [userRoleAddedByCLick, setUserRoleAddedByCLick] = useState(false)

  function addUserRole(userId, roleToAdd, roleAddedByCLick){
    //console.log("add user role: "+roleToAdd+" to user: "+userId)
    if (userId){
      //send api call with userid and role to add to API_URL + "users/addUserRole/:userId/:role"
      //console.log("send Api call for user: "+userId)
      fetch(API_URL + '/users/addUserRole/' + userId+"/"+roleToAdd, {
        headers: {
          'x-api-key': API_KEY
        }
      })
        .then(response => response.json())
        .then(data => {
          //console.log(data);
          if(roleAddedByCLick){
            setUserRoleAddedByCLick(true)
          }

          
  
        })
        .catch(error => {
          console.error('Error:', error);

  
        });
    }else{
      null
    }
  
  }

  useEffect(() => {
    if (!userId) return;
    //console.log(userId)
    setIsLoading(true);
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        //console.log(data);
        //console.log(Array.from(data))
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });




      //user accounts
      if (!user || !user.externalAccounts) return;
      if(user && user.externalAccounts){
       //console.log(user.externalAccounts)
       const discordId = getProviderUserIdFromDiscordProvider(user.externalAccounts);
       //console.log(discordId)
       fetch(API_URL + '/discord/users/' + discordId, {
         headers: {
           'x-api-key': API_KEY
         }
       })
         .then(response => response.json())
         .then(data => {
           //console.log(data);
           setUserDiscordData(data)
           if(data.isInGuild){
            //console.log("User is in discord guild")
            setUserIsInDiscord(true)
            
           
            }
            setIsLoading(false);
         })
         .catch(error => {
           console.error('Error:', error);
           setIsLoading(false);
       });
    }
      
   
  }, [userId, user, userRoleAddedByCLick]);
  
  const filteredRoutes = filterRoutesByRole(routes, userRoles);

  const [survey] = React.useState(new Model(json));
  survey.applyTheme(themeJson);
  survey.locale = "de";
  const surveyComplete = useCallback((sender) => {
    //console.log("Try complete survey for user " + userId)
    //console.log(userId)
    //console.log(sender.data)
    if(userId){
      saveSurveyResults(
        API_URL +"/whitelist",
        sender.data,
        json,
        userId
      )
      console.log("survey complete")
    }else{
      console.log("No user found to complete survey")
    }
      
       
  }, [userId]);

  survey.onComplete.add(surveyComplete);


function saveSurveyResults(url, json, questions, idUser) {
  fetch(url, {
    method: 'POST',
    headers: {
      'x-api-key': API_KEY,
      'Content-Type': 'application/json' 
    },
    body: JSON.stringify({
      "user": idUser,
      "answer": json,
      "question": questions
    })
  })
  .then(response => {
    if (response.ok) {
      addUserRole(userId, "whitelist-pending", false)
    } else {
      // Handle error
    }
  })
  .catch(error => {
    // Handle error
  });
}

  function hasRole(rolesArray, specificRole) {
    if(!rolesArray) return false;
    return rolesArray.includes(specificRole);
  }



  function checkWHitelistStatus(){
    if(hasRole(userDiscordData.roles, "Bürger")){
      //console.log("user is whitelisted, userid: "+userId)
      addUserRole(userId, "whitelisted", true)
      
      //refresh page
      //window.location.reload();
    }
    
  }

  const roleToCheck = "whitelisted"
  /* if (userRoles.includes(roleToCheck)) {
    return <Navigate to="/" />; // Redirect to the home screen
  } */
  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0
  }

  useEffect(() => {
    if (isLoading) {
      null
    }
  }, [isLoading,userRoles]);  // Dependencies
  
  const whitelist_pending = userRoles.includes("whitelist-pending")
  return (
    <>
      <DefaultNavbar
        routes={filteredRoutes}
        action={{
          type: "external",
          route: "",
          label: "buy now",
          color: "dark",
        }}
        sticky
      />
      
      <MKBox component="section" pt={20} pb={12}>
      
        <Container>
          

          <Grid container justifyContent="center">
            
            <Grid item xs={12}>
              <Card>
              <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="info"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" textAlign="center" color="white">
                    Whitelist
                  </MKTypography>
                  <MKTypography variant="body2" textAlign="center" color="white" opacity={0.8}>
                   Formular zum Erhalt der Whitelist
                  </MKTypography>
                </MKBox>
                
                <MKBox p={6}>
                  {!isLoading && !isObjectEmpty(userDiscordData) && hasRole(userDiscordData.roles, "Bürger") && !userRoles.includes(roleToCheck)?(
                    <Container>
                    <Grid container justifyContent="right">
                      <Stack direction="row" alignItems="flex-end" spacing={1}>
                        
                        <MKButton variant="outlined" color="dark" onClick={(event) => {checkWHitelistStatus()}}>
                          Ich habe bereits eine Whitelist!
                        </MKButton>
                      </Stack>
                    </Grid>
                  </Container>
                  ):(null)}
                {
                  whitelist_pending ? (
                    <Grid
                      container
                      item
                      xs={12}
                      lg={8}
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      sx={{ mx: "auto", textAlign: "center" }}
                      
                    ><MKTypography variant="h3" opacity={0.8} mt={1} mb={1}>
                      Vielen Dank für deine Bewerbung
                      </MKTypography>  <br></br>
                      <MKTypography variant="body1" opacity={0.8} mt={-5} mb={1}>
                      Deine Whitelist wird noch bearbeitet!
                      </MKTypography> 
                      
                    </Grid>
                  ) : (!userIsInDiscord && !isLoading) ? (
                    <Grid
                      container
                      item
                      xs={12}
                      lg={8}
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      sx={{ mx: "auto", textAlign: "center" }}
                    >
                      <MKTypography variant="h3" opacity={0.8} mt={1} mb={1}>
                      Hier stimmt etwas nicht
                      </MKTypography>  <br></br>
                      <MKTypography variant="body1" opacity={0.8} mt={-5} mb={1}>
                        Stelle sicher, dass dein richtiger Discord-Account mit dem Account auf der Website verknüpft ist.<br></br>
                        Dies kannst du auch in deinem Profil nachholen.<br></br><br></br>
                    
                        Falls du dies schon getan haben solltest, musst du dich nur noch auf unserem Discord befinden, dann kann die Reise los gehen!
                      </MKTypography> <br></br>
                      <MKButton href= "https://discord.gg/western-hills" target="#" color="dark">
                        Join Discord
                      </MKButton>
                    </Grid>
                  ) : userRoles.includes(roleToCheck)?(
                    <Grid
                      container
                      item
                      xs={12}
                      lg={8}
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      sx={{ mx: "auto", textAlign: "center" }}
                    >
                      <MKTypography variant="h3" opacity={0.8} mt={1} mb={1}>
                      Whitelist-Status
                      </MKTypography>  <br></br>
                      <MKTypography variant="body1" opacity={0.8} mt={-5} mb={1}>
                      Du hast deine Whitelist bereits erhalten und kannst dich auf dem Server einloggen.
                      </MKTypography> 
                      
                    </Grid>
                    
                  ):!isLoading && !userRoles.includes(roleToCheck) && userIsInDiscord?(
                    <Survey model={survey} />
                  ): (
                    <Grid
                      container
                      item
                      xs={12}
                      lg={8}
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      sx={{ mx: "auto", textAlign: "center" }}
                    >
                     <Triangle
                        color="#1a4ee8"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                      
                    </Grid>
                  )
                }

                  
                  
                  
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={{ xs: 0, lg: 3 }} pb={3}>
        <SimpleFooter />
      </MKBox>
    </>
  );
}

export default Whitelist;
