/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/presentation/sections/Counters";
import Information from "pages/presentation/sections/Information";
import DesignBlocks from "pages/presentation/sections/DesignBlocks";
import Download from "pages/presentation/sections/Download";
import AuthPages from "pages/presentation/sections/AuthPages";
import Pages from "pages/presentation/sections/Pages";
import Testimonials from "pages/presentation/sections/Testimonials";
import Pricing from "pages/presentation/sections/Pricing";

import loading from "components/Loading";

// Presentation page components
import BuiltByDevelopers from "pages/presentation/components/BuiltByDevelopers";



// Routes
import { filterRoutesByRole } from 'routes';
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-twh-home.webp";

import { useState, useEffect, useRef } from "react";
// rellax
import Rellax from "rellax";

// typed-js
import Typed from "typed.js";

import { SignOutButton, SignInButton, ClerkProvider, useUser,useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

function hasRole(rolesArray, specificRole) {
  return rolesArray.includes(specificRole);
}

function Presentation() {
  const headerRef = useRef(null);
  const typedJSRef = useRef(null);
  const { isLoaded, isSignedIn, user } = useUser();
  const { userId, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);

  useEffect(() => {
    if(!userId || userId === "undefined") return;
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        //console.log(data);
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);

  const filteredRoutes = filterRoutesByRole(routes, userRoles);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  // Setting up typedJS
  useEffect(() => {
    const typedJS = new Typed(typedJSRef.current, {
      strings: ["Projekt", "Erlebnis", "Abenteuer" ],
      typeSpeed: 90,
      backSpeed: 60,
      backDelay: 1500,
      startDelay: 500,
      loop: false,
    });

    return () => typedJS.destroy();
  }, []);


  return (
    <>
      <DefaultNavbar
        routes={filteredRoutes}
        action={{
          type: "external",
          route: "",
          label: "free download",
          color: "dark",
        }}
        sticky
      />
      
      <MKBox
        ref={headerRef}
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Ein einzigartiges <span ref={typedJSRef} />
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
            Starte noch heute deine Reise auf The Western Hills und erlebe Roleplay wie nie zuvor.
            </MKTypography>

            {(userId)?
              (
                <MKButton href= "https://discord.gg/western-hills" target="#" color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>
                  Join Discord
                </MKButton>
              ):(
                <MKButton href= "/register" color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>
                  Registrieren
                </MKButton>
              )}
            
            <MKTypography variant="h6" color="white" mt={8} mb={1}>
            Finde uns auf
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <MKTypography component="a" variant="body1" color="white" href="https://www.instagram.com/TheWesternHills/" mr={3}>
                <i className="fab fa-instagram" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="https://twitter.com/TheWesternHills" mr={3}>
                <i className="fab fa-twitter" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="https://www.youtube.com/@western-hills">
                <i className="fab fa-youtube" />
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Information />
        <DesignBlocks />
        {/* <Pages /> */}
        <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="flag"
                title="Getting Started"
                description="Schau dir unseren Einsteiger Guide an, um zu erfahren wie du deine ersten Schritte auf dem Server bestreitest."
                action={{
                  type: "internal",
                  route: "/einsteiger-guide",
                  label: "Los gehts",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="apps"
                title="FAQ"
                description="Was brauchst du überhaupt um auf dem Server spielen zu können? Hier findest du alle nötigen Informationen."
                action={{
                  type: "internal",
                  route: "/faq",
                  label: "Mehr lesen",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="people"
                title="Das Unternehmen"
                description="Lerne das Team hinter dem Projekt kennen und erfahre mehr über die Geschichte des Servers."
                action={{
                  type: "internal",
                  route: "/about-us",
                  label: "Mehr lesen",
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <Testimonials />
        <Download />
        {/* <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank you for your support!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  We deliver the best web products
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://twitter.com/intent/tweet?text=Check%20Material%20Design%20System%20made%20by%20%40CreativeTim%20%23webdesign%20%23designsystem%20%23mui5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fmaterial-kit-react"
                  target="_blank"
                  color="twitter"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-twitter" />
                  &nbsp;Tweet
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/material-kit-react"
                  target="_blank"
                  color="instagram"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-instagram" />
                  &nbsp;Instagram
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox> */}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
