/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useRef, useEffect } from 'react';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";


// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";


import adjustments from './data/adjustments';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;


function Counters() {
  const [ income, setIncome ] = useState(0);
  const [ expenses, setExpenses ] = useState(0);
  const [ current, setCurrent ] = useState(0);
  const [ currentPrefix, setCurrentPrefix ] = useState("-");
  const [ numberColor, setNumberColor ] = useState("info");
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const date = new Date();
    const currentMonthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;
    const currentAdjustments = adjustments[currentMonthYear] || { income: 0, expenses: 0 };
    console.log(API_URL+'/stripe/successful-payment-intents?key=donations')
    fetch(API_URL+'/stripe/successful-payment-intents?key=donations', {
      headers: {
        'x-api-key': API_KEY 
      }
    })
      .then(response => response.json())
      .then(data => {
        // Filter intents for the current month only
        console.log("Got to the data part")
        const currentMonthIntents = data.filter(intent => {
          const intentDate = new Date(intent.created * 1000);
          
          return `${intentDate.getMonth() + 1}/${intentDate.getFullYear()}` === currentMonthYear;
          
        });

        const totalIncome = currentMonthIntents.reduce((sum, intent) => sum + intent.amount, 0) / 100 + currentAdjustments.income;
        console.log(totalIncome)
        
        // Ensure expenses are always positive for display purposes
        const displayExpenses = Math.abs(expenses + currentAdjustments.expenses);
        
        setIncome(totalIncome);
        setExpenses(displayExpenses);

        // Compute the current balance by subtracting the absolute value of expenses
        const balance = totalIncome - Math.abs(expenses) - Math.abs(currentAdjustments.expenses);
        setCurrent(balance);

        // Set the prefix and color based on the balance
        setCurrentPrefix(balance >= 0 ? "+" : "-");
        setNumberColor(balance >= 0 ? "success" : "error");
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error fetching donations:', error);
        setIsLoading(false);  // Set loading to false after receiving data
      });
  }, []);

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={expenses}
              prefix="-"
              suffix="€"
              title="Ausgaben"
              description="Alle Ausgaben, welche wir getätigt haben"
              color="error"
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={income}
              prefix="+"
              suffix="€"
              title="Einnahmen"
              description="Alle Spenden, welche wir erhalten haben"
              color="success"
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={Math.abs(current)}
              prefix={currentPrefix}
              suffix="€"
              title="Aktueller Stand"
              description="Der aktuelle Stand unseres Finanzfluss"
              color = {numberColor}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
