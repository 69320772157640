// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { filterRoutesByRole } from 'routes';
import loading from "components/Loading";
import { useState, useEffect } from "react";
import { SignOutButton, SignInButton, ClerkProvider, useUser,useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
function Privacy() {
  const { userId, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);
  useEffect(() => {
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);
  const filteredRoutes = filterRoutesByRole(routes, userRoles);
  return (
    <>
      <DefaultNavbar
        routes={filteredRoutes}
        action={{
          type: "external",
          route: "",
          label: "buy now",
          color: "dark",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Ärzte Guide
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Ärzte auf the Western Hills – Alles was du Wissen solltest!
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Einführung, Spielmechaniken & Medikamente
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
{/* To-Do Dietrich: Bild einfügen href= https://wiki.western-hills.de/guides/body1.png */}
                    Ärzte im County arbeiten streng angelehnt an den medizinischen Wissensstand rund um das Jahr 1899 und sind staatlich angestellt. Um sich einen Überblick über die ärztlichen Praktiken und Medikamenten während dieser Zeit zu verschaffen steht den eingesetzten Ärzten ein Handbuch zur Verfügung, welches in Zusammenarbeit mit dem Team ständig weiter ausgearbeitet wurde. Spezielle Krankheiten wie Malaria und Cholera, die im County in einigen Gebieten vorherrschen, stellen die Ärzte und Patienten vor eine große Herausforderung. Diese schweren Krankheiten werden als solche ausgespielt da der Heilungsprozess individuell dauert. Um ein authentisches Roleplay zu gewährleisten, ist es wichtig sich im Vorfeld Gedanken um seinen Charakter zu machen (Charaktergeschichte) und dass dieser wenige bis ausgeprägte medizinische Kenntnisse mitbringt, wenn er als Arzt im County von The Western Hills praktizieren möchte.
                    <br />Neben kleinen und Großen Krankheiten/Verletzungen kommen auch Patienten mit Frakturen und Knochenbrüchen. Über ein „Body Check up“-Menü werden Knochenbrüche und die Temperatur des Patienten angezeigt. Über dieses Menü werden die Knochenbrüche aus Sicht der Spielmechanik geheilt. Ebenfalls beinhaltet es eine Krankenakte, in der Behandlungen eingetragen werden können. In den Ärztehäusern sind Behandlungsliegen mit der notwendigen medizinischen Ausstattung vorhanden und können genutzt werden. Ebenso wird dort in Zusammenarbeit mit einer Apotheke Medizin und Verbandsmaterial hergestellt.
                    <br />Die Ärzte praktizieren die meiste Zeit in eingeteilten Praxen oder sind unterwegs, um Patienten in Not zu helfen. Das einzigartige Dispatch System auf The Western Hills ermöglicht, dass wenn ein Patient in Nöten den Befehl „alertdoctor“ absendet, ein NPC Bote zeitverzögert zu den Arzt Praxen eilt, um den Standort der verletzten Person mitzuteilen. Es ist bekannt, dass bestimmte Regionen bei Dunkelheit gefährlich werden können und es kann riskant sein, sich in solchen Situationen alleine auf den Weg zu machen. In diesen Fällen kann es sinnvoll sein, professionelle Hilfe in Anspruch zu nehmen, um potentiellen Gefahren vorzubeugen.
{/* To-Do Dietrich: Wort alertdoctor verlinken href= https://wiki.western-hills.de/de/HowTo/Spielsysteme/Aerzte */}
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Wie fange ich an?
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Erstelle einen Charakter rund um seine Fähigkeiten und Eigenschaften. Woher stammt er oder sie, welche Bildung hat er genossen, welchen medizinischen Weg hat er schon eingeschlagen? Wähle eine Kleidung, die zum medizinischen Hintergrund passend ist.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Finde dich etwas im County ein. Lerne Land, Leute und Ärzte kennen. Alle Städte und deren Bewohner haben ihren eigenen Charme.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Lerne mehr über die Arbeit der Medizin im County von den Ärzten. Jede Region bietet andere Herausforderungen für den Mediziner Beruf. 
                    <br />Verschiedenste Umgebungen bieten unterschiedliche Pflanzen.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Besorge dir Dinge, die du zum praktizieren als Arzt oder Ärztin, bzw. Krankenpfleger/in brauchen wirst. (Pferd, etwas Verpflegung, erste medizinische Items)
                    <br />Ärzte arbeiteten sehr eng mit den Apotheker/innen zusammen. Jeder Arzt bringt seine eigenen Behandlungsmethoden oder Kenntnissen mit. Früher war es oft eine Herausforderung, korrekte Diagnosen zu stellen, weshalb Ärzte miteinander kooperierten und ihr Wissen teilten, um die Patientenversorgung zu verbessern.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Das medizinische Rollenspiel bietet neben Freude an den Behandlungen auch Vielseitigkeit, indem die Möglichkeit geboten wird, tiefgründige RP-Stränge zu entfalten oder Forschungsprojekte durchzuführen.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Ärzte und Ärztinnen waren der Zeit sehr hoch angesehen. Jedoch nicht selten konnten Patienten ihre Rechnung nicht bezahlen oder lebten weit außerhalb der Stadt, weswegen ein Besuch beim Arzt eine längere Reise mit sich zog. Somit praktizieren Ärzte und Ärztinnen staatlich aber bringen ihre eigenen Praktiken mit, reisen umher oder praktizieren in örtlichen Ärztehäusern oder Praxen.
                        </MKTypography>
                      </MKBox>                      
                  </MKBox>
                  <MKTypography variant="body2" color="text">
                    
                  </MKTypography>
                  
                </MKBox>
                <MKBox
                  display="flex"
                  alignItems="center"
                  borderRadius="xl"
                  my={1}
                  py={40}
                  sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                      `${linearGradient(
                        rgba(gradients.dark.main, 0.2),
                        rgba(gradients.dark.state, 0.1)
                      )}, url(https://wiki.western-hills.de/guides/aphoteker2.jpg)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  >

                </MKBox>
                
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}
export default Privacy;