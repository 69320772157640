
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/dashboard/whitelistsAdmin/data/openWhitelistsTableData";
import projectsTableData from "layouts/dashboard/whitelistsAdmin/data/projectsTableData";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme-dashboard";


// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";


// RTL plugins
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
// Material Dashboard 2 React routes
import sidenavRoutes from "sidenavRoutes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Icon from "@mui/material/Icon";
// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

import { filterRoutesByRole } from 'routes';

import { ThemeProvider } from "@mui/material/styles";

import { SignOutButton, SignInButton, ClerkProvider, useUser, useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";
import Whitelist from "pages/Support/Whitelist";



function WhitelistsDoneFunction(){
    const { columns, rows } = authorsTableData();
    const { columns: pColumns, rows: pRows } = projectsTableData();



    return (
        <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Abgeschlossene Whitelistanfragen
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
    );
}

export default WhitelistsDoneFunction;