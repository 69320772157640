/* eslint-disable react/prop-types */
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/dashboard/whitelistsAdmin/data/openWhitelistsTableData";
import projectsTableData from "layouts/dashboard/whitelistsAdmin/data/projectsTableData";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme-dashboard";


// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";


// RTL plugins
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
// Material Dashboard 2 React routes
import sidenavRoutes from "sidenavRoutes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Icon from "@mui/material/Icon";
// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

import { filterRoutesByRole } from 'routes';

import { ThemeProvider } from "@mui/material/styles";

import { SignOutButton, SignInButton, ClerkProvider, useUser, useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";
import Whitelist from "pages/Support/Whitelist";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;



function filterByStates(data, states) {
  return data.filter(entry => states.includes(entry.state));
}

function WhitelistsDoneFunction(){
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
      fetch(API_URL + '/whitelist/all', {
          method: 'POST',
          headers: {
              'x-api-key': API_KEY,
              'Content-Type': 'application/json'
          },
      })
      .then(response => response.json())
      .then(data => {
          console.log(data);
          const selectedStates = ["accepted", "declined"];
          const filteredEntries = filterByStates(data, selectedStates);
          const formattedData = formatData(filteredEntries);
          setColumns(formattedData.columns);
          setRows(formattedData.rows);
      })
      .catch(error => {
          console.error('Error:', error);
      });
  }, []);

  function reformatTime(timeString) {
    const date = new Date(timeString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();

    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
}

  const formatData = (data) => {
    const Author = ({ image, name, email }) => (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
      </MDBox>
    );

    const Job = ({ title, description }) => (
      <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
    );
    // Sort the data by the createdAt field
    const sortedData = [...data].sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    });
    return {
      columns: [
        { Header: "Whitelist", accessor: "author", width: "45%", align: "left" },
        { Header: "Berabeiter", accessor: "function", align: "left" },
        { Header: "status", accessor: "status", align: "center" },
        { Header: "Zeitstempel", accessor: "whitelistCreated", align: "center" },
        { Header: "action", accessor: "action", align: "center" },
      ],
  
      rows: sortedData.map(item => ({
        author: <Author image={"Test"} name={item.id} email={item.user} />,
        function: <Job title={item.supporterId} description={reformatTime(item.updatedAt)} />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent={item.state} color={item.state === 'accepted' ? 'success' : 'error'} variant="gradient" size="sm" />
          </MDBox>
        ),
        whitelistCreated: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {reformatTime(item.updatedAt)}
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href={"/whitelistview/"+item.id} variant="caption" color="text" fontWeight="medium">
            Öffnen
          </MDTypography>
        ),
      }))
    };
};

    return (
        <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Abgeschlossene Whitelistanfragen
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
    );
}

export default WhitelistsDoneFunction;