// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import { filterRoutesByRole } from 'routes';
import loading from "components/Loading";
import { useState, useEffect } from "react";
import { SignOutButton, SignInButton, ClerkProvider, useUser,useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

function Privacy() {
  const { userId, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);

  useEffect(() => {
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);

  const filteredRoutes = filterRoutesByRole(routes, userRoles);
  return (
    <>
      <DefaultNavbar
        routes={filteredRoutes}
        action={{
          type: "external",
          route: "",
          label: "buy now",
          color: "dark",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Zivilisten Guide
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Zivilisten bilden das Rückgrat des Staates und sind für Western Hills genauso wichtig, wie die LAW, oder die Crime.
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                     Einführung
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Wenn du überlegst einen Zivilisten zu spielen, dann gibt es viele Möglichkeiten dich einzubringen. Angefangen bei deiner Geschichte bis hin zu deinem RP kann diese Rolle sehr abwechslungsreich sein. Zivilisten leben in Städten, Dörfern oder auf einer Farm. Sie sind ein sehr wichtiger Bestandteil der Bevölkerung; Ohne sie steht alles still. Ihre Merkmale und Fähigkeiten kommen allen zu gute.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Wie fange ich an und wie verhält sich ein Zivilist?
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Aus was für einer Familie komme ich? Eher aus dem ländlichen Kansas, oder dem städtischen New York? Richte demnach dein Verhalten aus. 
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Bin ich in gut betuchtem Hause aufgewachsen, oder mussten die Eltern schwer schuften für einen Laib Brot? Überlege dir, wie sich das Leben in deiner Kindheit auf dich ausgewirkt hat. Ist dein Charakter mit wenig zufrieden, oder ist er in einer kaufmännischen Familie groß geworden?
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Das Alter des Charakters ist durchaus wichtig. In der Zeit zwischen 1800-1899 ist sehr viel passiert. Vielleicht hat dein Charakter Kriege miterlebt, was ihn geprägt hat. Bedenke auch, dass die Lebenserwartung in dieser Zeit nicht so hoch war, wie heutzutage. Welche Einschränkungen könnte das Alter haben?
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Kann mein Charakter mit einer Waffe umgehen? Wenn ja, welche? Es ist nicht logisch, dass dein Charakter alles kann. Bedenke im Zusammenspiel mit dem Alter, dass dein Charakter, wie du jetzt vielleicht, nicht alles kann. Was nicht bedeutet, dass dein Charakter es nicht IC lernen kann. Während der Bauer sein Gewehr stets bei sich hatte, ist der feine Bürger ggf. gar nicht bewaffnet.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Sprache ist ein gutes Stilmittel um zu zeigen, welche Bildung dein Charakter genoss. Höflichkeit ist grundsätzlich nie verkehrt, wobei auf dem Lande ein etwas rauer Ton herrscht.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Solltest du bereits einen Weg im Kopf haben, dann versuche in deiner Geschichte einen Grundstein zu setzen. Vielleicht die Arbeit in einem Saloon? Holzfäller im Norden? Bauer? Vielleicht will dein Charakter aber genau das Gegenteil?
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Religion war und ist ein wichtiger Teil von Amerika. Du musst keinen Fanatiker spielen, aber Äußerungen, dass man nicht an Gott glaubt, konnten einen schnell in eine Außenseiter Position bringen.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Arbeite mit dem Staat. Denk dran, du bist nicht Punisher69 mit DeadEye und Level 200. Es ist keine Schande, sich bei Problemen an den Staat zu wenden. Ob es LAW ist, oder höhere Instanzen. Das bietet allen RP.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Lass dich einstellen! Bevor jeder seinen eigenen Laden eröffnet und merkt, dass er das nicht alleine stemmen kann. Lass dich einstellen. Arbeite eine Weile, mache Freunde und Bekannte. Vielleicht ergibt sich eine Gelegenheit für dich etwas eigenes aufzubauen. Wer weiß? Vielleicht übernimmst du ja auch den Laden in dem du eingestellt bist.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Nur weil du als Zivilist anfängst, bedeutet das nicht, dass du IC nicht andere Wege einschlagen kannst. Vielleicht ergibt sich IC die Situation, dass du den Weg des Gesetzlosen einschlägst? Vielleicht willst du dich auch an diesen rächen und gehst zu der LAW? Zivilisten bilden das Rückgrat des Staates und sind für Western Hills genauso wichtig, wie die LAW. Diese Optionen bestehen weiterhin. Verstehe auch, dass du Respekt vor dem Staat hast, schließlich ging man damals nicht zimperlich mit den Leuten um. Ausnahmen bestätigen hier die Regel. Charakterentwicklung findet jeder Zeit statt.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Es ist nicht verkehrt, ein leeres Blatt Papier zu sein. Deine Geschichte ist dafür da geschrieben zu werden und dazu laden wir dich bei The Western Hills ein. Wichtig ist, dass du Wert auf Authentizität legst und vergiss nicht, dass wir alle hier sind um Spaß zu haben.
                        </MKTypography>
                      </MKBox>
                      <br /> <br />
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Als Zivilist bieten sich viele Möglichkeiten sein Leben zu gestalten und sein Geld zu verdienen in einer Zeit des großen Wandel, der technischen Neuerungen und Erfindungen. Doch genau so gegenteilig rauh war die Welt um die Leute herum: Die letzten Reste des Wilden Westens, Outlaws und nicht zuletzt die wilde Natur machten das Umland gefährlich.
                      </MKTypography>
                      
                  </MKBox>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
