/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard/dashboard";
import Tables from "layouts/dashboard/tables";
import WhitelistsAdmin from "layouts/dashboard/whitelistsAdmin"
import Billing from "layouts/dashboard/billing";
import RTL from "layouts/dashboard/rtl";
import Notifications from "layouts/dashboard/notifications";
import Profile from "layouts/dashboard/profile";
import SignIn from "layouts/dashboard/authentication/sign-in";
import SignUp from "layouts/dashboard/authentication/sign-up";
import IngameAdmin from "layouts/dashboard/ingame-admin";
import QueryStatsIcon from '@mui/icons-material/QueryStats';

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Ingame Admin",
    key: "ingame-admin",
    icon: <Icon fontSize="small">manage_accounts</Icon>,
    route: "/ingame-admin",
    component: <IngameAdmin />,
    allowedRoles: ["team"],
  },
  {
    type: "divider",
    key: "divider1",
    allowedRoles: ["team"],
  },
  {
    type: "title",
    key: "title1",
    title: "Whitelist-Support"
  },
  {
    type: "collapse",
    name: "Whitelists",
    key: "whitelist-admin",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/whitelists-admin",
    component: <WhitelistsAdmin />,
    allowedRoles: ["whitelist-support","team"],
  },
  {
    type: "divider",
    key: "divider2",
    allowedRoles: ["team"],
  },
  {
    type: "title",
    key: "title2",
    title: "Externe Tools",
    allowedRoles: ["team"],
  },
  {
    type: "collapse",
    name: "Grafana",
    key: "grafana",
    icon: <QueryStatsIcon/>,
    href: "http://37.27.55.24:3000/dashboards",
    target: "_blank",
    allowedRoles: ["team"],
  },
  {
    type: "collapse",
    name: "Team-Wiki",
    key: "teamwiki",
    icon: <Icon fontSize="small">table_view</Icon>,
    href: "https://twh-scripts.gitbook.io/internal-wiki/leitfaden/teamleitfaden",
    target: "_blank",
    allowedRoles: ["team"],
  },
  
];

export default routes;



export function filterRoutesByRole(routes, userRoles) {
  return routes
    .filter(route => {
      // Check for excludedRoles and if any of them are in userRoles
      if (route.excludedRoles && route.excludedRoles.some(role => userRoles.includes(role))) {
        return false;
      }

      // Check for allowedRoles and if any of them are in userRoles
      if (route.allowedRoles) {
        return route.allowedRoles.some(role => userRoles.includes(role));
      }
      return true;
    })
    .map(route => {
      if (route.collapse) {
        return {
          ...route,
          collapse: filterRoutesByRole(route.collapse, userRoles),
        };
      }
      return route;
    });
}
