// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import { filterRoutesByRole } from 'routes';
import loading from "components/Loading";
import { useState, useEffect } from "react";
import { SignOutButton, SignInButton, ClerkProvider, useUser,useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

function Privacy() {
  const { userId, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);

  useEffect(() => {
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        //console.log(data);
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);

  const filteredRoutes = filterRoutesByRole(routes, userRoles);
  return (
    <>
      <DefaultNavbar
        routes={filteredRoutes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "dark",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Server-Regelwerk
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Zuletzt aktualisiert am 11.02.2024
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    <b>1 Allgemeines</b>
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    1.	Sei kein Idiot und nutze deinen Verstand. 
                    <br />
                    <br />
                    2. 	Sollte es innerhalb der Rollenspiels zu Meinungsverschiedenheiten kommen, so erwarten wir von allen Spielenden, die Situation bestmöglich durchzuspielen, ohne die anderen Personen zu verletzen. Sobald die Situation als beendet gilt, können alle betroffenen Spieler ein Ticket eröffnen und die Meinung sowie ggf. einen finalen Beschluss des Teams verlangen. OOC (Out of Character) Argumente werden nicht toleriert.
                    <br />
                    <br />
                    3. 	Die Western Hills spielen in einem fiktiven Universum, in dem die Unterdrückung und Versklavung von BPoC (Black People of Color) nicht stattgefunden hat. Um mehr über die Server-Lore zu erfahren, klicke {" "}<MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="https://wiki.western-hills.de/de/Regelwerke/Server-Regelwerk#die-western-hills-spielen-in-einem-fiktiven-universum-in-dem-die-unterdr%C3%BCckung-und-versklavung-von-bpoc-black-people-of-color-nicht-stattgefunden-hat"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          hier
                        </MKTypography> und {" "}<MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="https://wiki.western-hills.de/de/Lore/Ereignisse/sturm"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          hier
                        </MKTypography>
                    <br />
                    <br />
                    4. 	Meta, Fail und Powergaming sind streng verboten und werden administrativ sanktioniert. {" "}<MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="https://wiki.western-hills.de/de/Regelwerke/Server-Regelwerk#meta-fail-und-powergaming-sind-streng-verboten-und-werden-administrativ-sanktioniert"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          hier
                        </MKTypography> erfahrt ihr mehr dazu. 
                    <br />
                    <br />
					5. 	VDM (Vehicle-Deathmatch) und RDM (Random-Deathmatch) sind verboten und werden seitens des Teams geahndet.
                    <br />
                    <br />
					6.	Erotisches Rollenspiel, welches über das Küssen und Berührungen hinausgeht, ist in jeder Form nicht gestattet.
					<br />
					<br />
					7.	Verstümmelungs- sowie Folter- und Selbstmord-Rollenspiel ist nicht gestattet.
					<br />
					<br />
					8.	Das absichtliche Nutzen von Exploits, Glitches, Hacks oder Bugs zur persönlichen Bereicherung führt zu einem permanenten Ausschluss aus der Community. Personen, die einen Glitch/Bug/Exploit innerhalb des Servers finden, bitten wir darum, ein entsprechendes Ticket im Discord zu eröffnen.
					<br />
					<br />
					9.	Dem Team ist es gestattet, den Mehrwert des Rollenspiels auf dem Server zu bewerten. Sollte das Team zu dem Schluss kommen, dass die Qualität des Rollenspiels auf dem Server durch das Verhalten von Einzelpersonen und/oder Gruppen und/oder Gewerben negativ beeinträchtigt wird, so behält sich das Team vor, entsprechende Maßnahmen umzusetzen, um der Qualität des Rollenspiels wieder gerecht zu werden.
					<br />
					<br />
					10.	Solange Spielende sich im Rollenspiel befinden, ist es untersagt, über OOC Themen zu sprechen
					<br />
					<br />
					11.	Das Ignorieren von Interaktionen im Rollenspiel mit anderen Personen auf dem Server wird als Fail-RP gewertet und führt zu einer Sanktionierung.
					<br />
					<br />
					12.	In den Western Hills bespielen wir das Jahr 1899, welches am 01.01. eines jeden Jahres resettet wird.
					<br />
					<br />
					13.	Achte auf deine Wortwahl! „Gambo“, „Much“, „Geringverdiener“, „Brudi“, „Digga“ und andere Formen des modernen Sprachgebrauchs finden hier keinen Platz. Versuche, moderne Sprache zu vermeiden und sprich geläufiges Deutsch oder eine noch besser passende Form der Formulierung.
					<br />
					<br />
					14.	Maskierte Charaktere dürfen nicht anhand ihrer Stimme erkannt werden.
					<br />
					<br />
					15.	Der Kleidungsstil der Charaktere muss dem Jahrhundert entsprechend gestaltet werden. Ein absichtlicher Verstoß gegen diese Richtlinie und das Diskriminieren von einzelnen Geschlechtern auf Grundlage derer selbst gewählten Farbkombinationen führt zu Sanktionen bis hin zum permanenten Ausschluss aus der Community. Mehr dazu {" "}<MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="https://wiki.western-hills.de/de/Regelwerke/Server-Regelwerk#der-kleidungsstil-der-charaktere-muss-dem-jahrhundert-entsprechend-gestaltet-werden-ein-absichtlicher-versto%C3%9F-gegen-diese-richtlinie-und-das-diskriminieren-von-einzelnen-geschlechtern-auf-grundlage-derer-selbst-gew%C3%A4hlten-farbkombinationen-f%C3%BChrt-zu-sanktionen-bis-hin-zum-permanenten-ausschluss-aus-der-community"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          hier
                        </MKTypography>.
					<br />
					<br />
					16.	Die Nachahmung von Mitgliedern der Exekutive (Law) und Judikative (Anwalt / Richter) ist nicht erlaubt.
					<br />
					<br />
					17.	Das absichtliche Provozieren der Law nur in der Absicht eine gewalttätige Situation zu beginnen ist nicht erlaubt und führt zu Sanktionen seitens des Teams. 
					<br />
					<br />
					18.	Korruption ist grundsätzlich erlaubt.Staatlichen Fraktionen ist schwere Korruption untersagt. Es ist also verboten, einen massiv korrupten Sheriff, Arzt oder sonstige Ämter zu bespielen. Kleinere Bestechungen anzunehmen oder mal wegzugehen, um seinem Charakter Vorteile im zukünftigen RP zu verschaffen, sind in Ordnung. Dies beinhaltet z.B. auch Bestechungen von politischen Amtsträgern zur Beeinflussung von Abstimmungen. Behaltet immer im Hinterkopf, dass Korruption auch auffliegen kann!
					<br />
					<br />
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    <b>2 Richtlinien für Charaktere</b>
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    1.	Bespielte Charaktere müssen ein Mindestalter von 18 Jahren haben.
                    <br />
                    <br />
                    2.	Das Ausspielen von Schwangerschaften ist nicht erlaubt.
                    <br />
                    <br />
                    3.	Charakternamen dürfen keine Ähnlichkeit zu bekannten Personen des öffentlichen Lebens haben, welche einen hohen Bekanntheitsgrad besitzen.
                    <br />
                    <br />
                    4.	Spielende dürfen selbst entscheiden, ob ihre Charaktere in einer Situation sterben oder aufgrund ihres Alters. 
                    <br />
                    <br />
					5.	Das Team behält sich vor, den Spielenden die Entscheidung über den Tod ihres Charakters abzunehmen. 
					<br />
                    <br />
					6.	Nach dem Tod eines Charakters kann erst nach 24 Stunden ein weiterer Charakter per Ticket beantragt werden.
					<br />
                    <br />
					7.	NPCs jeglicher Art werden so behandelt, als würde es sich um echte Personen handeln.
					<br />
                    <br />
					8.	Das eigene und das Leben Anderer ist von Anfang an zu schätzen. Von allen Spielenden wird erwartet, dass sie ihr Leben bewahren und den Schaden an weiteren Personen zu begrenzen. Bei Nichteinhaltung behält sich das Team vor, die Entscheidung über den Charaktertod dem Spieler abzunehmen.
					<br />
                    <br />
					9.	Nur das Team hat die Möglichkeit einzelne Charaktere hinrichten und/oder sterben zu lassen. Spielende haben keine Möglichkeit, Anträge zur Tötung anderer Charaktere zu stellen.
					<br />
                    <br />
					10.	Personen, welche Natives bespielen wollen, haben die Möglichkeit einen entsprechenden Charakter zu erstellen und ihn in einem Reserva, als integrierter Teil der amerikanischen Einwanderer, oder als Outlaw-Native zu bespielen. Aufgrund der realen Historie der Native Americans ist auf dem Projekt TWH eine normale Sprechweise für entsprechende Spieler und Spielerinnen zu wählen. Mehr dazu siehe {" "}<MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="https://wiki.western-hills.de/de/Regelwerke/Server-Regelwerk#der-kleidungsstil-der-charaktere-muss-dem-jahrhundert-entsprechend-gestaltet-werden-ein-absichtlicher-versto%C3%9F-gegen-diese-richtlinie-und-das-diskriminieren-von-einzelnen-geschlechtern-auf-grundlage-derer-selbst-gew%C3%A4hlten-farbkombinationen-f%C3%BChrt-zu-sanktionen-bis-hin-zum-permanenten-ausschluss-aus-der-community"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          hier
                        </MKTypography>.
					<br />
                    <br />
					11.	Eventcharaktere bedürfen einer vorherigen Freigabe des Teams, um erstellt und bespielt zu werden. Hierbei handelt es sich ausschließlich um Charaktere mit Verfallsdatum, welches das Team vorgibt.
					<br />
                    <br />
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                  <b>3 Schussankündigung & Fesseln</b>
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    1.	Eine Schussankündigung betrifft alle Spieler im Umkreis von 100 Metern, auch wenn diese nicht direkt an der Situation beteiligt sind. (z.B. Bewohner der Stadt, die gegenüber im Saloon sitzen). 
                    <br />
                    <br />
                    2.	Grundloses Schießen ist untersagt. Es muss im Vorfeld entsprechendes Rollenspiel mit der Gegenpartei stattgefunden haben, bevor Personen zur Waffengewalt greifen dürfen. Dies gilt auch bei Hinterhalten. Weitere Informationen zum Thema Konflikte findet ihr {" "}<MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="https://wiki.western-hills.de/de/Regelwerke/Server-Regelwerk#schussank%C3%BCndigung-fesseln"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          hier
                        </MKTypography>. 
                    <br />
                    <br />
                    3.	Ein Schuss – ob in die Luft oder sonst irgendwohin – gilt als Schussankündigung
                    <br />
                    <br />
                    4.	Das Nutzen des Lassos gegen einen anderen Spieler, um diesen zu fangen und/ oder dingfest zu machen, ist in einer PVP-Situation untersagt. Spieler dürfen jedoch andere Spieler mit dem Lasso, außerhalb einer akuten PVP-Situation, fesseln.
                    <br />
                    <br />
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                  <b>4 Hinrichtungen & Suizide</b>
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
					1. Hinrichtungen können grundsätzlich JEDEN treffen und bedürfen keiner Zustimmung der betroffenen Person.
                    <br />
                    <br />
                    2. Hinrichtungen können nur seitens des Teams durchgeführt werden. Hierfür werden auffällige Personen seitens des Teams beobachtet und in einer Teamsitzung über das weitere Vorgehen abgestimmt.
                    <br />
                    <br />
                    3. Suizide oder auch &quot;Char-Tod&quot; benötigen einen Antrag. Eine Begründung entfällt hier natürlich. Ihr seid jedoch dazu angehalten, eurem Charakter und allen Charakteren in seinem Umfeld einen &quot;würdigen&quot; Abschied zu ermöglichen.
                    <br />
                    <br />
                    4. Sowohl für Hinrichtungen als auch für Suizide gilt: Ist der Charakter tot, bleibt er tot.
                    <br />
                    <br />
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                  <b>5 Gruppengrößen & Gruppenverhalten</b>
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
					1. Outlaws können sich in Gruppen von mehr als 6 Personen treffen, sollten aber unter keinen Umständen auf der Karte gemeinsam unterwegs sein oder an bewaffneten Konflikten gemeinsam teilhaben. Jede Ausnahme dieser Regel erfordert die Zustimmung des Teams, welche vorab per Ticket zu erfragen ist. Weitere Informationen zum Thema Gruppendynamiken findet ihr {" "}<MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="https://wiki.western-hills.de/de/Regelwerke/Server-Regelwerk#gruppengr%C3%B6%C3%9Fen-gruppenverhalten"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          hier
                        </MKTypography>.   
                    <br />
                    <br />
                    2. Die Höchstzahl an Teilnehmern an einem bewaffneten Konflikt beträgt für Zivilisten sowie Law ebenfalls 6 Mitglieder. Ein Zusammenschluss aus Law und Zivilisten in bewaffneten Konflikten (auch wenn nur temporär) führt zu einer gemeinsamen Stärke von 12 Personen und ist untersagt. Weitere Informationen zum Thema Gruppendynamiken findet ihr {" "}<MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="https://wiki.western-hills.de/de/Regelwerke/Server-Regelwerk#gruppengr%C3%B6%C3%9Fen-gruppenverhalten"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          hier
                        </MKTypography>.  
                    <br />
                    <br />
                    3. Die Fraktionsgröße ist auf 10 Spieler begrenzt. 
                    <br />
                    <br />
					</MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                  <b>6 Bespielen von Konflikten</b>
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
					1. Eine Fraktion/ Unternehmen darf nicht gleichzeitig in denselben Konflikt gemeinsam mit einer dritten Fraktion/ Unternehmen involviert sein.
                    <br />
                    <br />
                    2. Die Gesamtzahl der beteiligten Personen darf die Gruppengröße von 6 Personen pro Seite nicht überschreiten.
                    <br />
                    <br />
                    3. Ein Zusammenschluss von Outlawbanden ist nur dann gestattet, wenn ein gemeinsames wirtschaftliches Ziel durch vorherigen Konflikt – ausgehend von einer dritten Partei – beeinträchtig wurde. Die Gruppengröße von 6 Personen im Konfliktfall bleibt hiervon unberührt. Eine vorherige Rücksprache mit dem Team wird dringend empfohlen.
                    <br />
                    <br />
                    4. Alle Charaktere, welche die Regel bezüglich der Gruppengröße überschreiten, sollten den Bereich umgehend verlassen und sich von der Szene zurückziehen. 
                    <br />
                    <br />
					5. Das absichtliche Benutzen von Verbündeten, um eine verfeindete Gruppe zu bekämpfen, gilt als Machtspiel und hat Sanktionen zur Folge. 
                    <br />
                    <br />
					6. Wenn Outlaws / Law den Ereignisort betreten, sollten sich unbeteiligte Mitglieder so schnell wie möglich aus der Situation entfernen, um Verwechslungen zu vermeiden. Im Idealfall sollten auch unbeteiligte Mitglieder unbewaffnet sein. Für den Fall, dass es keine Möglichkeit gibt, den Ort des Geschehens sicher oder logisch zu verlassen, wird empfohlen, dass Zivilisten Deckung suchen, bis die Konfrontation vorbei ist.
                    <br />
                    <br />
                  </MKTypography>
				  <MKTypography variant="h5" mt={6} mb={3}>
                  <b>7 Überfälle & Geiselnahmen</b>
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
					1. Allen beteiligten Personen ist bei Überfällen und Geiselnahmen intensives Rollenspiel zu bieten. Ein reines Ausrauben von Spielenden ohne Mehrwert für diese Personen ist nicht gestattet.
                    <br />
                    <br />
                    2. Alle Personen in den Western Hills können als Geisel genommen oder überfallen werden.
                    <br />
                    <br />
                    3. Personen, die überfallen oder als Geisel genommen werden, müssen allen involvierten Parteien ein entsprechend intensives Rollenspiel bieten.
                    <br />
                    <br />
                    4. Situationen mit Geiselnahmen dürfen nicht mit freiwilligen Geiseln (Fail-RP) und/ oder verkleideten Outlaws durchgeführt werden.
                    <br />
                    <br />
					5. Die Law darf sich selbst als Geisel eintauschen, wenn dies das situative RP unterstützt und zum Schutz der Zivilisten dient.
                    <br />
                    <br />
                    6. Dem Opfer ist bei Überfällen nicht sein gesamtes Hab und Gut zu entwenden. Hier gilt: Ein gesundes Maß ist der richtige Weg. 1/3 des mitgeführten Geldes sowie 3 Items müssen im Inventar des Opfers bleiben.
                    <br />
                    <br />
					7. Opfer dürfen nicht gezwungen werden ihr Bankkonto zu plündern.
                    <br />
                    <br />
					8. Opfer dürfen nicht gezwungen werden Immobilien, Pferde, Kutschen oder Gewerbe zu überschreiben.
                    <br />
                    <br />
                  </MKTypography>
				 <MKTypography variant="h5" mt={6} mb={3}>
                  <b>8 Sonstige kriminelle Aktivitäten</b>
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
					1. Jede Bande darf nur alle 3 Tage daran beteiligt sein, eine Person der eigenen Bande aus der Haft zu befreien. Der Gefangene, welcher befreit wird, ist von dieser Regel nicht betroffen. Diese Regel gilt sowohl für die Befreiung von Gefangenen aus den Zellen als auch während des Transports des Gefangenen an einen bestimmten Ort (Sisika). 
                    <br />
                    <br />
                    2. Maximal 6 Outlaws dürfen an einem Banküberfall teilnehmen. 
                    <br />
                    <br />
                    3. Alle 6 Outlaws müssen sich zum Zeitpunkt des Banküberfalls innerhalb der Stadt aufhalten.
                    <br />
                    <br />
                    4. Jede Person darf nur alle 7 Tage eine Bank ausrauben. Dies gilt bereits ab Initiierung eines Bankraubs, unabhängig vom Endergebnis. 
                    <br />
                    <br />
                  </MKTypography> 
				  <MKTypography variant="h5" mt={6} mb={3}>
                  <b>9 Immobilien- & Unternehmensbesitz</b>
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
					1. Der Besitz eines Unternehmens und/oder einer Immobilie geschieht nur via Rücksprache mit dem Team und/oder dem Immobilienmakler.
                    <br />
                    <br />
                    2. Für Unternehmen müssen Konzepte eingereicht werden, in welchen dem Team die Ideen, den Mehrwert, die Personenanzahl sowie alle weiteren Informationen mitgeteilt werden. Erst nach finaler Entscheidung des Teams kann und wird ein Unternehmen bewilligt. 
                    <br />
                    <br />
                    3. Das Team behält sich vor, Konzepte, Immobilien und Unternehmen abzulehnen, erneut zu vergeben oder wegzunehmen, wenn aus Sicht des Teams durch das Bespielen des Unternehmens und/oder der Immobilie eine RP-Blase entsteht, welche keinen Mehrwert für Dritte bietet und somit eine Qualitätsverschlechterung darstellt.
                    <br />
                    <br />
                  </MKTypography> 
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
