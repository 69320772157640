import React, { useEffect, useRef, useState } from 'react';

import MKTypography from 'components/MKTypography';
import MKBox from 'components/MKBox';

const styles = `
  
  .keyboard {
      text-align: center;
      font-size: 14px;
      font-family: sans-serif;
  }
  .key--double[data-key ="49"]{
  background: rgb(82,82,82);
  background: linear-gradient(90deg, rgba(82,82,82,0.6741071428571428) 0%, rgba(55,55,55,1) 100%);
  }
  .key--double[data-key ="50"]{
  background: rgb(82,82,82);
  background: linear-gradient(90deg, rgba(82,82,82,0.6741071428571428) 0%, rgba(55,55,55,1) 100%);
  }
  .key--double[data-key ="51"]{
  background: rgb(82,82,82);
  background: linear-gradient(90deg, rgba(82,82,82,0.6741071428571428) 0%, rgba(55,55,55,1) 100%);
  }
  .key--fn[data-key ="117"]{
  background: rgb(82,82,82);
  background: linear-gradient(90deg, rgba(82,82,82,0.6741071428571428) 0%, rgba(55,55,55,1) 100%);
  }
  .key--double[data-key ="52"]{
  background: rgb(82,82,82);
  background: linear-gradient(90deg, rgba(82,82,82,0.6741071428571428) 0%, rgba(55,55,55,1) 100%);
  }
  .key--letter[data-char ="I"]{
  background: rgb(82,82,82);
  background: linear-gradient(90deg, rgba(82,82,82,0.6741071428571428) 0%, rgba(55,55,55,1) 100%);
  }
  
  .key--letter[data-char ="X"]{
  background: rgb(82,82,82);
  background: linear-gradient(90deg, rgba(82,82,82,0.6741071428571428) 0%, rgba(55,55,55,1) 100%);
  }
  .key--letter[data-char ="H"]{
  background: rgb(82,82,82);
  background: linear-gradient(90deg, rgba(82,82,82,0.6741071428571428) 0%, rgba(55,55,55,1) 100%);
  }
/*   .key--letter[data-char ="J"]{
  background: rgb(82,82,82);
  background: linear-gradient(90deg, rgba(82,82,82,0.6741071428571428) 0%, rgba(55,55,55,1) 100%);
  } */
  .key--w3{
  background: rgb(82,82,82);
  background: linear-gradient(90deg, rgba(82,82,82,0.6741071428571428) 0%, rgba(55,55,55,1) 100%);
  }
  
  .key--letter[data-char ="X"]{
  background: rgb(82,82,82);
  background: linear-gradient(90deg, rgba(82,82,82,0.6741071428571428) 0%, rgba(55,55,55,1) 100%);
  }
  .key--letter[data-char ="U"]{
  background: rgb(82,82,82);
  background: linear-gradient(90deg, rgba(82,82,82,0.6741071428571428) 0%, rgba(55,55,55,1) 100%);
  }
  .key--letter[data-char ="Z"]{
  background: rgb(82,82,82);
  background: linear-gradient(90deg, rgba(82,82,82,0.6741071428571428) 0%, rgba(55,55,55,1) 100%);
  }
  .key--letter[data-char ="M"]{
  background: rgb(82,82,82);
  background: linear-gradient(90deg, rgba(82,82,82,0.6741071428571428) 0%, rgba(55,55,55,1) 100%);
  }
  
  .keyboard__row {
      display: inline-block;
      height: 3em;
      margin: 0.2em;
  }
  
  .keyboard__row--h1 {
      height: 1.7em;
      line-height: 1.4em;
  }
  
  .keyboard__row--h3 {
      height: 3.3em;
  }
  
  .keyboard__row > * {
      position: relative;
      background: #333;
      text-align: center;
      color: #eee;
      float: left;
      border-radius: 0.3em;
      margin: 0.2em;
      padding: 0.2em;
      width: 3.3em;
      height: 100%;
      box-sizing: border-box;
      cursor: pointer;
      -webkit-user-select: none;
      border: 1px solid #444;
      box-shadow: 0 0.2em 0 0.05em #222;
      border-bottom-color: #555;
  }
  
  .keyboard__row--h1 > div {
      width: 3.5em;
  }
  
  .keyboard__row > div[data-pressed],
  .keyboard__row > div:active {
      background: #2a2a2a;
      color: #aaa;
      position: relative;
      top: 0.2em;
      box-shadow: 0 0 0 0.05em black;
  }
  
  .key--w3 {
      width: 4.6em;
  }
  
  .key--w4 {
      width: 6em;
  }
  
  .key--w5 {
      width: 6.5em;
  }
  
  .key--w6 {
      width: 8.3em;
  }
  
  .key--space {
      width: 18em;
  }
  
  .key--double > * {
      margin-top: -0.2em;
  }
  
  .key--letter {
      line-height: 2.8em;
  }
  
  .key--bottom-left > * {
      position: absolute;
      text-align: left;
      bottom: 0.4em;
      left: 0.4em;
  }
  
  .key--bottom-right > * {
      position: absolute;
      text-align: right;
      bottom: 0.4em;
      right: 0.4em;
  }
  
  .key--fn > * {
      font-size: 0.6em;
      line-height: 1em;
      padding-top: 1.2em;
      padding-right: 0.2em;
      text-align: right;
      float: right;
  }
  
  .key--word > * {
      font-size: 0.8em;
  }
  .key--fn:hover{
    border: solid 2px white;
    background: radial-gradient(#5C5C5B, #444);
    transition: background .4s ease;
  }
  .key--double:hover{
    border: solid 2px white;
    background: radial-gradient(#5C5C5B, #444);
    transition: background .4s ease;
  }
  .key--letter:hover{
    border: solid 2px white;
    background: radial-gradient(#5C5C5B, #444);
    transition: background .4s ease;
  }
  .key--bottom-left:hover{
    border: solid 2px white;
    background: radial-gradient(#5C5C5B, #444);
    transition: background .4s ease;
  }
  .key--bottom-right:hover{
    border: solid 2px white;
    background: radial-gradient(#5C5C5B, #444);
    transition: background .4s ease;
  }
  .key--arrow:hover{
    border: solid 2px white;
    background: radial-gradient(#5C5C5B, #444);
    transition: background .4s ease;
  }
  .key--word:hover{
    border: solid 2px white;
    background: radial-gradient(#5C5C5B, #444);
    transition: background .4s ease;
  }
  
  .key--arrow--tall > *,
  .key--arrow > * {
      font-size: 0.5em;
      line-height: 3em;
  }
  
  .key--arrow {
      height: 1.8em;
      margin-top: 1.7em;
      line-height: 0.5em;
  }
  
  .key--arrow--tall > * {
      padding-top: 0.2em;
  }
  
  .keyboard > .keyboard__row {
      text-align: center;
  }
  .key--w3[data-key ="91"]{
  background: rgb(82,82,82);
  background: linear-gradient(90deg, rgba(82,82,82,0.6741071428571428) 0%, rgba(55,55,55,1) 100%);
  }

`;

const KeyboardLayout = () => {
    const [title, setTitle] = useState("Tastaturbelegung");
    const [subtitle, setSubtitle] = useState('Klicke auf eine Taste um die Beschreibung anzuzeigen');
    const h3Ref = useRef(null);

    const initialTitle = "Tastaturbelegung";

    const getKey = (e) => {
        const location = e.location;
        let selector;
        if (location === KeyboardEvent.DOM_KEY_LOCATION_RIGHT) {
            selector = `[data-key="${e.keyCode}-R"]`;
        } else {
            const code = e.keyCode || e.which;
            selector = `[data-key="${code}"], [data-char*="${String.fromCharCode(code)}"]`;
        }
        return document.querySelector(selector);
    };

    const changeTitle = (title) => {
        setTitle(title);
    };

    const changeSub = (subtitle) => {
        setSubtitle(subtitle);
    };
    

    useEffect(() => {

        const handleKeyDown = (e) => {
            const key = getKey(e);
            if (key) key.setAttribute('data-pressed', 'on');
        };

        const handleKeyUp = (e) => {
            const key = getKey(e);
            if (key) key.removeAttribute('data-pressed');
        };

        document.body.addEventListener('keydown', handleKeyDown);
        document.body.addEventListener('keyup', handleKeyUp);

        // Clean up event listeners when component is unmounted
        return () => {
            document.body.removeEventListener('keydown', handleKeyDown);
            document.body.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    return (
        <div>
            
            <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="info"
                  p={3}
                  mt={-3}
                  mx={5}
                >
                  <MKTypography variant="h3" textAlign="center" color="white">
                  {title}
                  </MKTypography>
                  <MKTypography ref={h3Ref} variant="body1" color="white"  textAlign="center"  opacity={0.8}>
                    {subtitle}
                  </MKTypography>
            </MKBox>
            <MKTypography ref={h3Ref} variant="body1" color="white" justifyContent="center" textAlign="center" alignItems="center" opacity={0.8}>
                    .
                  </MKTypography>
            <div className="keyboard">
            <div className="keyboard__row keyboard__row--h1">
            <div data-key="27" className="key--word">
              <span>esc</span>
            </div>
            <div data-key="112" className="key--fn">
              <span>F1</span>
            </div>
            <div data-key="113" className="key--fn">
              <span>F2</span>
            </div>
            <div data-key="114" className="key--fn">
              <span>F3</span>
            </div>
            <div data-key="115" className="key--fn">
              <span>F4</span>
            </div>
            <div data-key="116" className="key--fn">
              <span>F5</span>
            </div>
            <div data-key="117" className="key--fn" onMouseOver={() => changeTitle('TWH-Menü')} onClick={() => changeSub('Hier findest du alles was du brauchst! Über den Sozialstufen-Pass bis hin zum Server-Wiki!')}>
              <span>F6</span>
            </div>
            <div data-key="118" className="key--fn">
              <span>F7</span>
            </div>
            <div data-key="119" className="key--fn">
              <span>F8</span>
            </div>
            <div data-key="120" className="key--fn">
              <span>F9</span>
            </div>
            <div data-key="121" className="key--fn">
              <span>F10</span>
            </div>
            <div data-key="122" className="key--fn">
              <span>F11</span>
            </div>
            <div data-key="123" className="key--fn">
              <span>F12</span>
            </div>
            <div data-key="n/a" className="key--word">
              <span>pwr</span>
            </div>
          </div>
          <div className="keyboard__row">
            <div className="key--double" data-key="192">
              <div>~</div>
              <div>`</div>
            </div>
            <div className="key--double" data-key="49" onMouseOver={() => changeTitle('Voice-Range')}  onClick={() => changeSub('Ändere deine Sprach-Reichweite [3m, 8m, 15m oder 32m]')}>
              <div>!</div>
              <div>1</div>
            </div>
            <div className="key--double" data-key="50" onMouseOver={() => changeTitle('Begrüßen')} onClick={() => changeSub('Begrüße andere Spieler im Vorbeigehen. Tippe deinen Hut an oder Grüße sie mit einem einfachen Winken')}>
              <div>@</div>
              <div>2</div>
            </div>
            <div className="key--double" data-key="51" onMouseOver={() => changeTitle('Zeigen')} onClick={() => changeSub('Lass andere wissen, wohin sie schauen sollen')}>
              <div>#</div>
              <div>3</div>
            </div>
            <div className="key--double" data-key="52" onMouseOver={() => changeTitle('Hände heben')} onClick={() => changeSub('Hebe deine Hände um dich zu ergeben.')} >
              <div>$</div>
              <div>4</div>
            </div>
            <div className="key--double" data-key="53">
              <div>%</div>
              <div>5</div>
            </div>
            <div className="key--double" data-key="54">
              <div>^</div>
              <div>6</div>
            </div>
            <div className="key--double" data-key="55">
              <div>&</div>
              <div>7</div>
            </div>
            <div className="key--double" data-key="56">
              <div>*</div>
              <div>8</div>
            </div>
            <div className="key--double" data-key="57">
              <div>(</div>
              <div>9</div>
            </div>
            <div className="key--double" data-key="48">
              <div>)</div>
              <div>0</div>
            </div>
            <div className="key--double" data-key="189">
              <div>_</div>
              <div>-</div>
            </div>
            <div className="key--double" data-key="187">
              <div>+</div>
              <div>=</div>
            </div>
            <div className="key--bottom-right key--word key--w4" data-key="8">
              <span>delete</span>
            </div>
          </div>
          <div className="keyboard__row">
            <div className="key--bottom-left key--word key--w4" data-key="9">
              <span>tab</span>
            </div>
            <div className="key--letter" data-char="Q">Q</div>
            <div className="key--letter" data-char="W">W</div>
            <div className="key--letter" data-char="E">E</div>
            <div className="key--letter" data-char="R">R</div>
            <div className="key--letter" data-char="T">T</div>
            <div className="key--letter" data-char="Z" onMouseOver={() => changeTitle('Ragdoll')} onClick={() => changeSub('Solltest du feststecken oder ein item an deinem Charakter kleben, lasse dich einfach fallen')}>Z</div>
            <div className="key--letter" data-char="U" onMouseOver={() => changeTitle('Boots-Menü')} onClick={() => changeSub('Drücke U während du in einem Boot sitzt, um das zugehörige Menü zu öffnen')} >U</div>
            <div className="key--letter" data-char="I" onMouseOver={() => changeTitle('Inventar')} onClick={() => changeSub('Drücke I um auf dein Hab und Gut zuzugreifen')}>I</div>
            <div className="key--letter" data-char="O">O</div>
            <div className="key--letter" data-char="P">P</div>
            <div className="key--double" data-key="219" data-char="{[">
              <div>&#123;</div>
              <div>[</div>
            </div>
            <div className="key--double" data-key="221" data-char="}]">
              <div>&#125;</div>
              <div>]</div>
            </div>
            <div className="key--double" data-key="220" data-char="|\">
              <div>|</div>
              <div>\</div>
            </div>
          </div>
          <div className="keyboard__row">
            <div className="key--bottom-left key--word key--w5" data-key="20">
              <span>caps lock</span>
            </div>
            <div className="key--letter" data-char="A">A</div>
            <div className="key--letter" data-char="S">S</div>
            <div className="key--letter" data-char="D">D</div>
            <div className="key--letter" data-char="F">F</div>
            <div className="key--letter" data-char="G">G</div>
            <div className="key--letter" data-char="H" onMouseOver={() => changeTitle('Pferd rufen')} onClick={() => changeSub('Pfeife dein Pferd zu dir')}>H</div>
            <div className="key--letter" data-char="J" >J</div>
            <div className="key--letter" data-char="K">K</div>
            <div className="key--letter" data-char="L">L</div>
            <div className="key--double" data-key="186">
              <div>:</div>
              <div>;</div>
            </div>
            <div className="key--double" data-key="222">
              <div>&#34;</div>
              <div>&#39;</div>
            </div>
            <div className="key--bottom-right key--word key--w5" data-key="13">
              <span>enter</span>
            </div>
          </div>
          <div className="keyboard__row">
            <div className="key--bottom-left key--word key--w6" data-key="16">
              <span>shift</span>
            </div>
            <div className="key--letter" data-char="Y">Y</div>
            <div className="key--letter" data-char="X" onMouseOver={() => changeTitle('Radial Menü')} onClick={() => changeSub('Im Radialmenü findest du alles, was du auf die schnelle benötigst')}>X</div>
            <div className="key--letter" data-char="C">C</div>
            <div className="key--letter" data-char="V">V</div>
            <div className="key--letter" data-char="B">B</div>
            <div className="key--letter" data-char="N">N</div>
            <div className="key--letter" data-char="M" onMouseOver={() => changeTitle('Karte')} onClick={() => changeSub('Solange du auch eine Karte im Inventar hast, kannst du sie dir auch anschauen')}>M</div>
            <div className="key--double" data-key="188">
              <div>&lt;</div>
              <div>,</div>
            </div>
            <div className="key--double" data-key="190">
              <div>&gt;</div>
              <div>.</div>
            </div>
            <div className="key--double" data-key="191">
              <div>?</div>
              <div>/</div>
            </div>
            <div className="key--bottom-right key--word key--w6" data-key="16-R">
              <span>shift</span>
            </div>
          </div>
          <div className="keyboard__row keyboard__row--h3">
            <div className="key--bottom-left key--word">
              <span>fn</span>
            </div>
            <div className="key--bottom-left key--word key--w1" data-key="17">
              <span>strg</span>
            </div>
            <div className="key--bottom-left key--word key--w1" data-key="18">
              <span>win</span>
            </div>
            <div className="key--bottom-right key--word key--w3" data-key="91" onMouseOver={() => changeTitle('Türen abschliessen')} onClick={() =>changeSub('Falls du eine Tür mit einem Schloss daran siehst, kannst du sie per Tastendruck öffnen falls du dazu berechtigt bist [Privathäuser werden mit LSHIFT + ALT geöffnet]')}>
              <span>alt</span>
            </div>
            <div className="key--double key--right key--space" data-key="32" data-char=" ">
              &nbsp;
            </div>
            <div className="key--bottom-left key--word key--w3" data-key="93-R">
              <span>alt gr</span>
            </div>
            <div className="key--bottom-left key--word key--w1" data-key="18-R">
              <span>option</span>
            </div>
            <div data-key="37" className="key--arrow">
              <span>&#9664;</span>
            </div>
            <div className="key--double key--arrow--tall" data-key="38">
              <div>&#9650;</div>
              <div>&#9660;</div>
            </div>
            <div data-key="39" className="key--arrow">
              <span>&#9654;</span>
            </div>
          </div>
        <div>
                <h3 className="h3"></h3></div>
            </div>
            <style>
            {styles}
            </style>
        </div>
    );
};

export default KeyboardLayout;
