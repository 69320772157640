/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.webp";
import bgBack from "assets/images/rotating-card-bg-back.jpg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Starte deine Reise
                    <br />
                    noch heute
                  </>
                }
                description="Registriere dich noch heute, beantrage deine Whitelist und starte direkt in eine neue Welt"
              />
              <RotatingCardBack
                image={bgBack}
                title="Hol dir deine Whitelist!"
                description="Registrieren, Whitelist beantragen und direkt loslegen."
                action={{
                  type: "internal",
                  route: "/register",
                  label: "Registrieren",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="person"
                  title="Individualität"
                  description="TWH setzt auf Individualität eines jeden Spielers und bietet dir die Möglichkeit, deine eigene Welt zu erschaffen. Werde kreativ und sei wer du werden willst."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="description"
                  title="Professionalität"
                  description="Sowohl in der Entwicklung als auch in der Arbeit im Team setzen wir auf ein hohes Maß an Professionalität. Etablierte Praktiken aus der Geschäftswelt finden auch bei uns Anwendung."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="add"
                  title="Mehr als nur ein Spiel"
                  description="Abseits des Servers bietet TWH weitere Angebote rund um das Projekt, wie z.B. unseren Entwickler-Podcast."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="auto_graph"
                  title="Innovation"
                  description="Nicht nur im hauseigenen Entwicklungsunternehmen setzten wir international auf Innovation. Auch in der Entwicklung von TWH setzen wir auf neue Technologien und Ansätze."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
