// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import Table from "examples/Tables/Table";

import React, { useState, useRef, useEffect } from 'react';

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import adjustments from './data/adjustments';

import { Triangle } from  'react-loader-spinner'


function MonthlySummaryTable() {
  const [expenses, setExpenses] = useState(0); // assumed given
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState({
    columns: [
      { name: "Monat", align: "left" },
      { name: "Ausgaben", align: "left" },
      { name: "Einnahmen", align: "center" },
      { name: "Endstand", align: "center" },
    ],
    rows: []
  });
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    fetch(API_URL+'/stripe/successful-payment-intents?key=donations', {
      headers: {
        'x-api-key': API_KEY 
      }
    })
        .then(response => response.json())
        .then(intents => {
            const groupedByMonth = groupByMonth(intents);

            // Merging adjustments with groupedByMonth data.
            Object.keys(adjustments).forEach(month => {
                if (!groupedByMonth[month]) {
                    groupedByMonth[month] = [];
                }
            });

            const sortedKeys = Object.keys(groupedByMonth).sort((a, b) => new Date(b.split('/')[1], b.split('/')[0]) - new Date(a.split('/')[1], a.split('/')[0])).slice(0, 12);
            const sortedData = sortedKeys.reduce((acc, key) => ({ ...acc, [key]: groupedByMonth[key] }), {});
            const rows = constructRows(sortedData, expenses);
            setTableData(prev => ({ ...prev, rows }));
            setIsLoading(false);
        })
        .catch(error => {
            console.error('Error fetching donations:', error);
        });
}, [expenses]);
  

  return (
    <MKBox component="section" py={1}>
        <Container>
            <Grid container item xs={12} lg={10} mx="auto">
             
              {isLoading ? (
                 <Grid container sx={{
                  textAlign: 'center',
                  display: "grid",
                  placeItems: "center",
                  position: 'fixed', // Fixed position
                  right: 0, left: 0, // Give it full screen size
                  zIndex: 9999, // Make sure it's on top
                }}>
                  <Triangle
                    color="#344767"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClassName="loaderStyle"
                    visible={true}
                  />
                 </Grid>
              ) : (
                <Table columns={tableData.columns} rows={tableData.rows} />
              )}
                
            </Grid>
        </Container>
    </MKBox>
  );
}

function groupByMonth(intents) {
  return intents.reduce((acc, intent) => {
    const date = new Date(intent.created * 1000);
    const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;

    if (!acc[monthYear]) {
      acc[monthYear] = [];
    }
    acc[monthYear].push(intent);
    return acc;
  }, {});
}

function constructRows(groupedByMonth, expenses) {
  const monthlyData = Object.entries(groupedByMonth).map(([monthYear, intents]) => {
    let income = intents.reduce((sum, intent) => sum + (intent.amount / 100), 0);
    let monthExpenses = expenses; // default monthly expenses

    // Apply adjustments
    const adj = adjustments[monthYear] || {};
    income += adj.income || 0;
    monthExpenses += adj.expenses || 0;

    const current = Math.round((income - monthExpenses) * 100) / 100;

    const currentPrefix = current >= 0 ? "+" : "-";
    const color = current >= 0 ? "success" : "error"; 

    return {
      monthYear,
      monthExpenses,
      income,
      current,
      currentPrefix,
      color
    };
  });

  // Compute the summary for all months
  const totalExpenses = monthlyData.reduce((sum, data) => sum + data.monthExpenses, 0);
  const totalIncome = monthlyData.reduce((sum, data) => sum + data.income, 0);
  const totalCurrent = Math.round((totalIncome - totalExpenses - 1500.0) * 100) / 100;
  const currentPrefix = totalCurrent >= 0 ? "+" : "-";
  const color = totalCurrent >= 0 ? "success" : "error";

  // Construct rows including the summary
  const rows = [...monthlyData.map(data => ({
    Monat: <MKTypography variant="caption" color="secondary" fontWeight="medium">{data.monthYear}</MKTypography>,
    Ausgaben: <MKBadge max = {9999} variant="contained" badgeContent={"-" + Math.abs(data.monthExpenses).toString()} color={"error"} size="xs" container/>,
    Einnahmen: <MKBadge max = {9999} variant="contained" badgeContent={"+" + Math.abs(data.income).toString()} color={"success"} size="xs" container/>,
    Endstand: <MKBadge max = {9999} variant="contained" badgeContent={data.currentPrefix + Math.abs(data.current).toString()} color={data.color} size="xs" container/>
  })), {
    Monat: <MKTypography variant="h7" color="secondary" fontWeight="bold"><b>Zusammenfassung</b></MKTypography>,
    Ausgaben: <MKBadge max = {9999} variant="contained" badgeContent={"-" + Math.abs(totalExpenses).toString()} color={"error"} size="xs" container/>,
    Einnahmen: <MKBadge max = {9999} variant="contained" badgeContent={"+" + Math.abs(totalIncome).toString()} color={"success"} size="xs" container/>,
    Endstand: <MKBadge max = {9999} variant="contained" badgeContent={currentPrefix + Math.abs(totalCurrent).toString()} color={color} size="xs" container/>
  }];

  return rows;
}



export default MonthlySummaryTable;