/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import Snackbar from "@mui/material/Snackbar";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/dashboard/tables/data/authorsTableData";
import projectsTableData from "layouts/dashboard/ingame-admin/data/projectsTableData";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme-dashboard";


// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

import Autocomplete from "@mui/material/Autocomplete";
// RTL plugins
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
// Material Dashboard 2 React routes
import sidenavRoutes from "sidenavRoutes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator, setDarkMode } from "context";
import { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { SignOutButton, SignInButton, ClerkProvider, useUser, useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";

import Icon from "@mui/material/Icon";
// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

import { filterRoutesByRole } from 'routes';

import { ThemeProvider } from "@mui/material/styles";
import input from "assets/theme/components/form/input";
import MKTypography from "components/MKTypography";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const transformPlayerData = (players) => {
  return Object.entries(players).map(([key, value]) => ({
    label: `${value.firstname} ${value.lastname} [${key}]`,
    id: key
  }));
};

const transformItemData = (items) => {
  return Object.entries(items).map(([key, value]) => ({
    label: `${value.item} [${value.label}]`,
    id: value.item
  }));
};



function Tables() {
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const [controller, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };


  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const toggleSnackbar = () => setShow(!show);

  const [article, setArticle] = useState(null);
  const [collapse, setCollapse] = useState(false);
  const { userId, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);
  const [onlinePlayers, setOnlinePlayers] = useState(["Loading..."]);
  const [items, setItems] = useState([]);
  const [itemOptions ,setItemOptions] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState('');
  const [playerOptions, setPlayerOptions] = useState([]);
  const [inputValueMoneyAdd, setInputValueMoneyAdd] = useState('');
  const [inputValueMoneyRemove, setInputValueMoneyRemove] = useState('');
  const [inputValueGoldAdd, setInputValueGoldAdd] = useState('');
  const [inputValueGoldRemove, setInputValueGoldRemove] = useState('');
  const [inputValueRolAdd, setInputValueRolAdd] = useState('');
  const [inputValueRolRemove, setInputValueRolRemove] = useState('');
  const [inputValueItemAdd, setInputValueItemAdd] = useState('');
  const [inputValueItemAmountAdd, setInputValueItemAmountAdd] = useState('');
  const [inputValueItemRemove, setInputValueItemRemove] = useState('');
  const [inputValueItemAmountRemove, setInputValueItemAmountRemove] = useState('');
  const [inputValueJob, setInputValueJob] = useState('');
  const [inputValueJobGrade, setInputValueJobGrade] = useState('');
  const [inputValueDiseaseAdd, setInputValueDiseaseAdd] = useState('');
  const [inputValueDiseaseRemove, setInputValueDiseaseRemove] = useState('');
  const [inputValueTeleport, setInputValueTeleport] = useState('');



  const toastStyles = ({
    palette: { info },
    borders: { borderRadius },
    typography: { size },
    boxShadows: { lg },
  }) => ({
    "& .MuiPaper-root": {
      backgroundColor: info.main,
      borderRadius: borderRadius.lg,
      fontSize: size.sm,
      fontWeight: 400,
      boxShadow: lg,
      px: 2,
      py: 0.5,
    },
  });

  const toastTemplate = (
    <MKBox display="flex" justifyContent="space-between" alignItems="center" color="white">
      {message}
      <CloseIcon
        fontSize="medium"
        sx={{ ml: 4, mr: -1, cursor: "pointer" }}
        onClick={toggleSnackbar}
      />
    </MKBox>
  );

  useEffect(() => {
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);


  useEffect(() => {
    fetch(API_URL + '/ingameAdmin/getOnlinePlayers', {
      headers: {
        'x-api-key': API_KEY
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log(data.players);
      setOnlinePlayers(data.players);
      setPlayerOptions(transformPlayerData(data.players));
      console.log(transformPlayerData(data.players));
      // handle the response data
    })
    .catch(error => console.error('Error:', error));
    }, []);

    useEffect(() => {
      fetch(API_URL + '/data/items', {
        headers: {
          'x-api-key': API_KEY
        }
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setItems(data);
        setItemOptions(transformItemData(data));
        // handle the response data
      })
      .catch(error => console.error('Error:', error));
      }, []);

  function hasRole(rolesArray, specificRole) {
    return rolesArray.includes(specificRole);
  }

  const filteredRoutes = filterRoutesByRole(sidenavRoutes, userRoles);
  const roleToCheck = "team"

  /* if (!isLoading && !userRoles.includes(roleToCheck)) {
    return <Navigate to="/" />; // Redirect to the home screen
  } */

  // Give Money
const giveMoney = (playerId, amount) => {
  fetch(`${API_URL}/ingameAdmin/giveMoney`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'x-api-key': API_KEY },
    body: JSON.stringify({ playerId, money: amount })
  })
  .then(response => response.json())
  .then(data => {
      setMessage(inputValueMoneyAdd + "$ an " + selectedPlayer + " übergeben");
      toggleSnackbar();
      setInputValueMoneyAdd('');
      console.log('Money given:', data)})
  .catch(error => console.error('Error:', error));
};

// Remove Money
const removeMoney = (playerId, amount) => {
  fetch(`${API_URL}/ingameAdmin/removeMoney`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'x-api-key': API_KEY },
    body: JSON.stringify({ playerId, money: amount })
  })
  .then(response => response.json())
  .then(data => {
    setMessage(inputValueMoneyRemove + "$ von " + selectedPlayer + " weggenommen");
    toggleSnackbar();
    setInputValueMoneyRemove('');
    console.log('Money removed:', data)}
    )
  .catch(error => console.error('Error:', error));
};

// Give Gold
const giveGold = (playerId, amount) => {
  fetch(`${API_URL}/ingameAdmin/giveGold`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'x-api-key': API_KEY },
    body: JSON.stringify({ playerId, gold: amount })
  })
  .then(response => response.json())
  .then(data => {
      setMessage(inputValueGoldAdd + "$ an " + selectedPlayer + " übergeben");
      toggleSnackbar();
      setInputValueGoldAdd('');
      console.log('Gold given:', data)})
  .catch(error => console.error('Error:', error));
};

// Remove Gold
const removeGold = (playerId, amount) => {
  fetch(`${API_URL}/ingameAdmin/removeGold`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'x-api-key': API_KEY },
    body: JSON.stringify({ playerId, gold: amount })
  })
  .then(response => response.json())
  .then(data => {
    setMessage(inputValueGoldRemove + "$ von " + selectedPlayer + " weggenommen");
    toggleSnackbar();
    setInputValueGoldRemove('');
    console.log('Gold removed:', data)
  })
  .catch(error => console.error('Error:', error));
};

// Give Rol
const giveRol = (playerId, amount) => {
  fetch(`${API_URL}/ingameAdmin/giveRol`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'x-api-key': API_KEY },
    body: JSON.stringify({ playerId, rol: amount })
  })
  .then(response => response.json())
  .then(data => {
      setMessage(inputValueRolAdd + "Tokens an " + selectedPlayer + " übergeben");
      toggleSnackbar();
      setInputValueRolAdd('');
      console.log('Rol given:', data)
  })
  .catch(error => console.error('Error:', error));
};

// Remove Gold
const removeRol = (playerId, amount) => {
  fetch(`${API_URL}/ingameAdmin/removeRol`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'x-api-key': API_KEY },
    body: JSON.stringify({ playerId, rol: amount })
  })
  .then(response => response.json())
  .then(data => {
    setMessage(inputValueRolRemove + "Tokens von " + selectedPlayer + " weggenommen");
    toggleSnackbar();
    setInputValueRolRemove('');
    console.log('Rol removed:', data)
  })
  .catch(error => console.error('Error:', error));
};

const giveItem = (playerId, item, itemAmount) => {
  fetch(`${API_URL}/ingameAdmin/giveItem`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'x-api-key': API_KEY },
    body: JSON.stringify({ playerId, item, itemAmount })
  })
  .then(response => response.json())
  .then(data => {
    // Handle your response here
    setMessage(inputValueItemAdd + " " + itemAmount + "x " + item + " an " + selectedPlayer + " übergeben");
    toggleSnackbar();
    setInputValueItemAmountAdd('');
    console.log('Item given:', data);
  })
  .catch(error => console.error('Error:', error));
};

const removeItem = (playerId, item, itemAmount) => {
  fetch(`${API_URL}/ingameAdmin/removeItem`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'x-api-key': API_KEY },
    body: JSON.stringify({ playerId, item, itemAmount })
  })
  .then(response => response.json())
  .then(data => {
    // Handle your response here
    setMessage(inputValueItemRemove + " " + itemAmount + "x " + item + " von " + selectedPlayer + " weggenommen");
    toggleSnackbar();
    setInputValueItemAmountRemove('');
    console.log('Item removed:', data);
  })
  .catch(error => console.error('Error:', error));
};

const setJob = (playerId, job, jobGrade) => {
  fetch(`${API_URL}/ingameAdmin/setJob`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'x-api-key': API_KEY },
    body: JSON.stringify({ playerId, job, jobGrade })
  })
  .then(response => response.json())
  .then(data => {
    // Handle your response here
    setMessage("Job von " + selectedPlayer + " auf " + job + " " + jobGrade + " gesetzt");
    toggleSnackbar();
    setInputValueJob('');
    setInputValueJobGrade('');
    console.log('Job set:', data);
  })
  .catch(error => console.error('Error:', error));
};

const giveDisease = (playerId, disease) => {
  fetch(`${API_URL}/ingameAdmin/giveDisease`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'x-api-key': API_KEY },
    body: JSON.stringify({ playerId, disease })
  })
  .then(response => response.json())
  .then(data => {
    // Handle your response here
    setMessage("Krankheit " + disease + " an " + selectedPlayer + " gegeben");
    toggleSnackbar();
    setInputValueDiseaseAdd('');
    console.log('Disease given:', data);
  })
  .catch(error => console.error('Error:', error));
};

const removeDisease = (playerId, disease) => {
  fetch(`${API_URL}/ingameAdmin/removeDisease`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'x-api-key': API_KEY },
    body: JSON.stringify({ playerId, disease })
  })
  .then(response => response.json())
  .then(data => {
    // Handle your response here
    setMessage("Krankheit " + disease + " von " + selectedPlayer + " entfernt");
    toggleSnackbar();
    setInputValueDiseaseRemove('');
    console.log('Disease removed:', data);
  })
  .catch(error => console.error('Error:', error));
};

const ragdollPlayer = (playerId) => {
  //if not player id return message that no player is selected
  if (!playerId) {
    setMessage("Kein Spieler ausgewählt");
    toggleSnackbar();
    return;
  }
  fetch(`${API_URL}/ingameAdmin/ragdoll`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'x-api-key': API_KEY },
    body: JSON.stringify({ playerId })
  })
  .then(response => response.json())
  .then(data => {
    // Handle your response here
    console.log('Player ragdolled:', data);
  })
  .catch(error => console.error('Error:', error));
};

const tpPlayer = (playerId, locationKey) => {
  //if not player id return message that no player is selected
  if (!playerId) {
    setMessage("Kein Spieler ausgewählt");
    toggleSnackbar();
    return;
  }
  fetch(`${API_URL}/ingameAdmin/teleport`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'x-api-key': API_KEY },
    body: JSON.stringify({ playerId, locationKey })
  })
  .then(response => response.json())
  .then(data => {
    // Handle your response here
    setMessage("Spieler " + selectedPlayer + " nach " + locationKey + " geportet");
    toggleSnackbar();
    setInputValueTeleport('');
    console.log('Player tped:', data);
  })
  .catch(error => console.error('Error:', error));
};

const resurrectPlayer = (playerId) => {
  //if not player id return message that no player is selected
  if (!playerId) {
    setMessage("Kein Spieler ausgewählt");
    toggleSnackbar();
    return;
  }
  fetch(`${API_URL}/ingameAdmin/resurrect`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'x-api-key': API_KEY },
    body: JSON.stringify({ playerId })
  })
  .then(response => response.json())
  .then(data => {
    // Handle your response here
    setMessage("Spieler " + selectedPlayer + " wiederbelebt");
    toggleSnackbar();
    console.log('Player resurrected:', data);
  })
  .catch(error => console.error('Error:', error));
  };

  //FUNCTIONS FOR COMMANDS
  const handleGiveMoneyClick = (currency) => {
    if (selectedPlayer) {
      if (currency === "gold" && inputValueGoldAdd) {
        giveGold(selectedPlayer, inputValueGoldAdd);
      } else if (currency === "money" && inputValueMoneyAdd) {
        giveMoney(selectedPlayer, inputValueMoneyAdd);
      } else if (currency === "rol" && inputValueRolAdd) {
        giveRol(selectedPlayer, inputValueRolAdd);
      } else {
        console.log('Please enter an amount');
        setMessage("Bitte gib einen Betrag ein!");
        toggleSnackbar();
      }
    } else {
      console.log('Please select a player and enter an amount');
      setMessage("Bitte wähle einen Spieler!");
      toggleSnackbar();
    }
  };

  const handleRemoveMoneyClick = (currency) => {
    if (selectedPlayer) {
      if (currency === "gold" && inputValueGoldRemove) {
        removeGold(selectedPlayer, inputValueGoldRemove);
      } else if (currency === "money" && inputValueMoneyRemove) {
        removeMoney(selectedPlayer, inputValueMoneyRemove);
      } else if (currency === "rol" && inputValueRolRemove) {
        removeRol(selectedPlayer, inputValueRolRemove);
      } else {
        console.log('Please enter an amount');
        setMessage("Bitte gib einen Betrag ein!");
        toggleSnackbar();
      }
    } else {
      console.log('Please select a player and enter an amount');
      setMessage("Bitte wähle einen Spieler und gib einen Betrag ein");
      toggleSnackbar();
    }
  };

  const handleGiveItemClick = (type) => {
    if (selectedPlayer) {
      if (type === "item" && inputValueItemAdd && inputValueItemAmountAdd) {
        console.log('item', inputValueItemAdd, inputValueItemAmountAdd, selectedPlayer);
        giveItem(selectedPlayer, inputValueItemAdd, inputValueItemAmountAdd);
      } else if (type === "weapon") {
        //giveItem(selectedPlayer, inputValueWeaponAdd, inputValueWeaponAmountAdd);
      } else {
        console.log('Please enter an item');
        setMessage("Bitte gib ein Item in korrektem Format ein!");
        toggleSnackbar();
      }
    } else {
      console.log('Please select a player and enter an item');
      setMessage("Bitte wähle einen Spieler und gib ein Item an!");
      toggleSnackbar();
    }
  };
  const handleRemoveItemClick = (type) => {
    if (selectedPlayer) {
      if (type === "item" && inputValueItemRemove && inputValueItemAmountRemove) {
        removeItem(selectedPlayer, inputValueItemAdd, inputValueItemAmountRemove);
      } else if (type === "weapon") {
        //giveItem(selectedPlayer, inputValueWeaponAdd, inputValueWeaponAmountAdd);
      } else {
        console.log('Please enter an item');
        setMessage("Bitte gib ein Item ein!");
        toggleSnackbar();
      }
    } else {
      console.log('Please select a player and enter an item');
      setMessage("Bitte wähle einen Spieler und gib ein Item an!");
      toggleSnackbar();
    }
  };

  const handleSetJobClick = () => {
    if (selectedPlayer) {
      if (inputValueJob && inputValueJobGrade) {
        setJob(selectedPlayer, inputValueJob, inputValueJobGrade);
      } else {
        console.log('Please enter a job and job grade');
        setMessage("Bitte gib einen Job und Jobgrad ein!");
        toggleSnackbar();
      }
    } else {
      console.log('Please select a player and enter a job and job grade');
      setMessage("Bitte wähle einen Spieler und gib einen Job und Jobgrad ein!");
      toggleSnackbar();
    }
  };

  const handleGiveDiseaseClick = () => {
    if (selectedPlayer) {
      if (inputValueDiseaseAdd) {
        giveDisease(selectedPlayer, inputValueDiseaseAdd);
      } else {
        console.log('Please enter a disease');
        setMessage("Bitte gib eine Krankheit ein!");
        toggleSnackbar();
      }
    } else {
      console.log('Please select a player and enter a disease');
      setMessage("Bitte wähle einen Spieler und gib eine Krankheit ein!");
      toggleSnackbar();
    }
  };

  const handleRemoveDiseaseClick = () => {
    if (selectedPlayer) {
      if (inputValueDiseaseRemove) {
        removeDisease(selectedPlayer, inputValueDiseaseRemove);
      } else {
        console.log('Please enter a disease');
        setMessage("Bitte gib eine Krankheit ein!");
        toggleSnackbar();
      }
    } else {
      console.log('Please select a player and enter a disease');
      setMessage("Bitte wähle einen Spieler und gib eine Krankheit ein!");
      toggleSnackbar();
    }
  };

  const handleTeleportPlayerClick = () => {
    if (selectedPlayer) {
      if (inputValueTeleport) {
        tpPlayer(selectedPlayer, inputValueTeleport);
      } else {
        console.log('Please enter a tp location');
        setMessage("Bitte wähle eine Teleport location aus!");
        toggleSnackbar();
      }
    } else {
      console.log('Please select a player and enter a tp location');
      setMessage("Bitte wähle einen Spieler aus und gib eine Teleport location ein!");
      toggleSnackbar();
    }
  };



  return ( 
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      {isLoading ? (
      <div>Loading...</div>
    ) : !isLoading && !userRoles.includes(roleToCheck)?(
      <div>No Permission</div>
    )
    : (
      <DashboardLayout>
      <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Team Dashboard"
              routes={filteredRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={show}
          autoHideDuration={3000}
          onClose={toggleSnackbar}
          message={toastTemplate}
          sx={toastStyles}
        />
          
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Ingame-Administration
                </MDTypography>
                <Autocomplete
                  defaultValue={playerOptions[0]}
                  options={playerOptions}
                  getOptionLabel={(option) => option ? option.label : ''}
                  onChange={(event, newValue) => {
                    setSelectedPlayer(newValue ? newValue.id : '');

                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => <MKInput {...params} variant="standard" />}
                />
              </MDBox>
             {/* <MDBox pt={6} pb={3}>
                {users.map(user => (
                  <MDTypography key={user.identifier} variant="h6" color="white">
                    {user.identifier}
                  </MDTypography>
                ))}
              </MDBox>  */}

                <MDBox component="section" pt={3} margin={2}>
                <MDTypography variant="h5" opacity={0.8} gutterBottom>
                    Berufe
                  </MDTypography>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3}} py={1}>
                    <Grid item xs={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                          <MKInput   label="Job eingeben" fullWidth  value={inputValueJob} onChange={(event) => {setInputValueJob(event.target.value)}}/>
                          <MKInput sx={{ py: 1}} fullWidth label="Rang"  value={inputValueJobGrade} onChange={(event) => {setInputValueJobGrade(event.target.value)}} variant="standard" />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MKButton onClick={(event) =>{handleSetJobClick()}} variant="gradient" color="info" sx={{ height: "100%" }}>
                            Job setzen
                          </MKButton>
                        </Grid>
                      </Grid>
                    </Grid>                 
                                      
                  </Grid>
                  <Divider key="Divider-first"></Divider>


                  <MDTypography variant="h5" opacity={0.8} gutterBottom>
                    Währungen
                  </MDTypography>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3}} py={1}>
                    <Grid item xs={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                          <MKInput label="Menge eingeben" fullWidth  value={inputValueMoneyAdd} onChange={(event) => {setInputValueMoneyAdd(event.target.value)}}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MKButton onClick={(event) =>{handleGiveMoneyClick("money")}} variant="gradient" color="info" sx={{ height: "100%" }}>
                            Geld geben
                          </MKButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    
                    <Grid item xs={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5} >
                          <MKInput label="Menge eingeben" fullWidth value={inputValueMoneyRemove} onChange={(event) => {setInputValueMoneyRemove(event.target.value)}} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MKButton onClick={(event) =>{handleRemoveMoneyClick("money")}} variant="gradient" color="info" sx={{ height: "100%" }}>
                            Geld entwenden
                          </MKButton>
                        </Grid>
                      </Grid>
                      
                    </Grid>
                    
                  </Grid>

                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                    <Grid item xs={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                          <MKInput label="Menge eingeben" fullWidth  value={inputValueGoldAdd} onChange={(event) => {setInputValueGoldAdd(event.target.value)}}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MKButton onClick={(event) =>{handleGiveMoneyClick("gold")}} variant="gradient" color="info" sx={{ height: "100%" }}>
                            Schwarzgeld geben
                          </MKButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    
                    <Grid item xs={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5} >
                          <MKInput label="Menge eingeben" fullWidth value={inputValueGoldRemove} onChange={(event) => {setInputValueGoldRemove(event.target.value)}} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MKButton onClick={(event) =>{handleRemoveMoneyClick("gold")}} variant="gradient" color="info" sx={{ height: "100%" }}>
                            Schwarzgeld entwenden
                          </MKButton>
                        </Grid>
                      </Grid>
                      
                    </Grid>
                  </Grid>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} py={1}>
                    <Grid item xs={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                          <MKInput label="Menge eingeben" fullWidth  value={inputValueRolAdd} onChange={(event) => {setInputValueRolAdd(event.target.value)}}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MKButton onClick={(event) =>{handleGiveMoneyClick("rol")}} variant="gradient" color="info" sx={{ height: "100%" }}>
                            Token geben
                          </MKButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    
                    <Grid item xs={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5} >
                          <MKInput label="Menge eingeben" fullWidth value={inputValueRolRemove} onChange={(event) => {setInputValueRolRemove(event.target.value)}} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MKButton onClick={(event) =>{handleRemoveMoneyClick("rol")}} variant="gradient" color="info" sx={{ height: "100%" }}>
                            Token entwenden
                          </MKButton>
                        </Grid>
                      </Grid>
                      
                    </Grid>
                  </Grid>
                  <Divider key="Divider2"></Divider>


                  <MDTypography variant="h5" opacity={0.8} gutterBottom>
                    Items & Waffen
                  </MDTypography>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3}} py={1}>
                    <Grid item xs={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                        <Autocomplete
                          defaultValue={itemOptions[0]}
                          options={itemOptions}
                          getOptionLabel={(option) => option ? option.label : ''}
                          onChange={(event, newValue) => {
                            setInputValueItemAdd(newValue ? newValue.id : '');
                          
                          }}
                          sx={{ width: 280, py: 1 }}
                          renderInput={(params) => <MKInput {...params} variant="standard" />}
                          />
                          <MKInput label="Menge eingeben" fullWidth  value={inputValueItemAmountAdd} onChange={(event) => {setInputValueItemAmountAdd(event.target.value)}}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MKButton onClick={(event) =>{handleGiveItemClick("item")}} variant="gradient" color="info" sx={{ height: "100%" }}>
                            Item geben
                          </MKButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    
                    <Grid item xs={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5} >
                        <Autocomplete
                          defaultValue={itemOptions[0]}
                          options={itemOptions}
                          getOptionLabel={(option) => option ? option.label : ''}
                          onChange={(event, newValue) => {
                            setInputValueItemRemove(newValue ? newValue.id : '');
                          
                          }}
                          sx={{ width: 280, py: 1 }}
                          renderInput={(params) => <MKInput {...params} variant="standard" />}
                          />
                          <MKInput label="Menge eingeben" fullWidth  value={inputValueItemAmountRemove} onChange={(event) => {setInputValueItemAmountRemove(event.target.value)}}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MKButton onClick={(event) =>{handleRemoveItemClick("item")}} variant="gradient" color="info" sx={{ height: "100%" }}>
                            Item entwenden
                          </MKButton>
                        </Grid>
                      </Grid>
                      
                    </Grid>
                    
                  </Grid>
                  <Divider key="Divider1" ></Divider>

                  <MDTypography variant="h5" opacity={0.8} gutterBottom>
                    Gesundheit
                  </MDTypography>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3}} py={1}>
                    <Grid item xs={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                        <Autocomplete
                          defaultValue="brokenBone"
                          options={["brokenBone", "cold", "malaria", "bleeding"]}
                          onChange={(event, newValue) => {
                            setInputValueDiseaseAdd(newValue ? newValue : '');
                          
                          }}
                          sx={{ width: 280}}
                          renderInput={(params) => <MKInput {...params} variant="standard" />}
                          />
                      
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MKButton onClick={(event) =>{handleGiveDiseaseClick()}} variant="gradient" color="info" sx={{ height: "100%" }}>
                            Krankheit geben
                          </MKButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    
                    <Grid item xs={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5} >
                        <Autocomplete
                          defaultValue="brokenBone"
                          options={["brokenBone", "cold", "malaria", "bleeding"]}
                          onChange={(event, newValue) => {
                            setInputValueDiseaseRemove(newValue ? newValue : '');
                          
                          }}
                          sx={{ width: 280}}
                          renderInput={(params) => <MKInput {...params} variant="standard" />}
                          />
                  
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MKButton onClick={(event) =>{handleRemoveDiseaseClick()}} variant="gradient" color="info" sx={{ height: "100%" }}>
                            Krankheit entfernen
                          </MKButton>
                        </Grid>
                      </Grid>
                      
                    </Grid>
                    <Grid item xs={12} sm={4}>
                          <MKButton onClick={(event) =>{resurrectPlayer(selectedPlayer)}} variant="gradient" color="info" sx={{ height: "100%" }}>
                            Wiederbeleben
                          </MKButton>
                    </Grid>
                    
                  </Grid>

                  <Divider key="Divider4" ></Divider>

                  <MDTypography variant="h5" opacity={0.8} gutterBottom>
                    Aktionen
                  </MDTypography>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3}} py={1}>

                    <Grid item xs={12} sm={4}>
                          <MKButton onClick={(event) =>{ragdollPlayer(selectedPlayer)}} variant="gradient" color="info" sx={{ height: "100%" }}>
                            Ragdoll
                          </MKButton>
                    </Grid>

                  </Grid>
                  <Divider key="Divider4" ></Divider>

                  <MDTypography variant="h5" opacity={0.8} gutterBottom>
                    Teleport
                  </MDTypography>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} py={1}>
                    <Grid item xs={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5} >
                          <Autocomplete
                            defaultValue=""
                            options={
                              [
                                {label: "Saint Denis", id : "sd"}, {label: "Rhodes", id: "rd"}, {label: "Valentine", id: "vt"}, {label: "Van Horn", id: "vh"}, {label: "Annesburg", id: "an"}, {label: "Blackwater", id: "bw"}, {label: "Second Chance", id: "adminShop"}, {label: "Sissika", id: "ss"}, {label: "Saint Denis - Sissika Steg", id: "sd_steg"}
                              ]
                            }
                            getOptionLabel={(option) => option ? option.label + ' [' + option.id +']' : ''}
                            onChange={(event, newValue) => {
                              setInputValueTeleport(newValue ? newValue.id : '');

                            }}
                            sx={{ width: 280 }}
                            renderInput={(params) => <MKInput {...params} variant="standard" />}
                          />

                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MKButton onClick={(event) => { handleTeleportPlayerClick() }} variant="gradient" color="info" sx={{ height: "100%" }}>
                            Teleport Player
                          </MKButton>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>


                </MDBox>

            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Online Spieler ({playerOptions.length})
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
   
    </DashboardLayout>
    )}
    
    </ThemeProvider>
  );
}

export default Tables;
