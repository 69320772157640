// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import { filterRoutesByRole } from 'routes';
import loading from "components/Loading";
import { useState, useEffect } from "react";
import { SignOutButton, SignInButton, ClerkProvider, useUser,useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

function Privacy() {
  const { userId, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);

  useEffect(() => {
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        //console.log(data);
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);

  const filteredRoutes = filterRoutesByRole(routes, userRoles);
  return (
    <>
      <DefaultNavbar
        routes={filteredRoutes}
        action={{
          type: "external",
          route: "",
          label: "buy now",
          color: "dark",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Einsteigerguide 
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Zuletzt aktualisiert am 12.11.2023
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h4" mt={6} mb={3}>
                    Wie sehen die Details zum Setting auf The Western Hills aus?
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Wir schreiben das Jahr 1899 zum Ende der auch als „Pionierzeit“ bezeichneten Ära des 19. Jahrhunderts. In den sogenannten „Territories“, die den größten Teil der westlichen USA bilden, haben sich noch keine Bundesstaaten formiert. Im Vergleich zu den geordneten Staatsgebieten an der Ostküste reicht der Arm des Gesetzes in einem Territory nicht sehr weit. Das Land ist noch nicht flächendeckend von weißen Siedlern erschlossen. Die Pionierzeit, in der die „Eroberung des Westens“ auf Kosten der Indianer vorangetrieben wird, neigt sich bereits in schnellen Schritten ihrem Ende zu. In den späten 1840er Jahren haben die USA ihre Gebietsansprüche auf dem nordamerikanischen Festland weitgehend durchgesetzt. Der mexikanisch-amerikanische Krieg ist beendet und viele Menschen suchen ihr Glück in den scheinbar grenzenlosen Gebieten des Westens, wo noch für jeden Abenteurer ein Stück Land zu finden ist. 
                    <br />
                    So auch in den Western Hills!
                    <br /><br />
                    Die Gründung und die Geschichte der Vereinigten Staaten verliefen ähnlich der Historie in unserem Universum, aber etwas friedlicher als in der realen Welt. Der Hauptgrund für das Verlassen der „Alten Welt“ war vor allem die Möglichkeit in der „Neuen Welt“ groß herauszukommen oder den Machtkämpfen der großen Regierungen zu entkommen. Die Kolonisierung erfolgte durch Erkundung, Verträge und minimale Eroberungen. Die Abspaltung von Europa erfolgte aufgrund des Mangels an angemessener Repräsentation, als die Bevölkerung wuchs und aufgrund der Unannehmlichkeiten von Personen regiert zu werden, die während des Zeitalters der Segelschifffahrt stets Monate vom Kontakt entfernt waren.
                    <br />
                    Die Einwanderungspolitik in unserem Spiel unterscheidet sich auch insofern von der realen Welt, als die Menschen in Zeiten des Friedens und der Sicherheit frei kommen und gehen können. Der östliche Teil der USA ist gut entwickelt und weit in der Industrialisierung fortgeschritten. Die Westküste wird allmählich dicht besiedelt, und das Zentrum des Landes ist schon seit einiger Zeit mit der Eisenbahn verbunden. Es gibt immer noch riesige Gebiete, die noch nicht besiedelt sind und Territorien, die um den Status eines Bundesstaates und eine formale Vertretung auf Bundesebene kämpfen und einige Regionen sind noch nicht richtig vermessen oder besiedelt.
                    <MKTypography variant="h4" mt={6} mb={3}>
                    Welche Art von Rollenspiel wird auf The Western Hills betrieben?
                    </MKTypography>
                    Wir legen auf The Western Hills Wert auf Immersion. Dabei ist uns wichtig, dass sich die Spieler mit dem Setting, in dem ihre Charaktere unterwegs sind, vertraut machen und es semi-historisch korrekt bespielen.
                    <br />
                    Gemeint ist damit, dass wir uns freuen, wenn die Denkweise der damaligen Zeit im Rahmen des Spielspaßes übertragen wird.
                    <br />
                    Auch, wenn wir uns ein immersives Setting wünschen, wünschen wir uns kein historisch überkorrektes Setting. In einigen Punkten machen wir bewusst Abstriche. Dazu zählen die historische Korrektheit im Umgang mit Schwarzen und Indianern, Gleichberechtigung von Mann und Frau, sowie Religionsfreiheit.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Immersion und Abstriche
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Ja – wir spielen im Jahr 1899. Ja – zu dieser Zeit gab es andere Rollenbilder und Gegebenheiten. Ja – Immersion wird hier groß geschrieben. 
                    <br />
                    Immersion hört aber da auf, wo wir einer Hälfte der Community, beispielsweise den Frauen eine Rolle vorschreiben.
                    <br />
                    Darüber hinaus, ist die Rolle der Frau zu dieser Zeit bei vielen anscheinend nicht ausreichend bekannt. Es wurde mehrmalig auch aus Reihen der Community Mitgliedern eingeworfen, dass die Rolle der Frau eben zu dieser Zeit immense Veränderungen und Fortschritte erlebt hat.
                    <br />
                    Ganz unabhängig davon, ist für uns eins klar: JEDER Spieler hat das Recht alle Richtungen im RP einzuschlagen die ihm/ihr belieben. Wir sehen nicht ein, wieso wir einigen Spielerinnen eine Seite des RP mit Hinblick auf vermeintlich historisch korrekte Aspekte des gesellschaftlichen Lebens verweigern sollten.
                    <br />
                    Frauen sind im RP 1zu1 wie Männer zu behandeln. Mit ALLEN VOR- und NACHTeilen.
                    <br />
                    Wenn eine weibliche Spielerin euch im RP blöd kommt, könnt ihr diese genauso hart angehen, wie ihr es mit männlichen Charakter tun würdet etc. Genauso erwarten wir allerdings, dass Frauen mit dem gleichen Respekt behandelt werden, die ihr gegenüber männlichen Charakteren zeigen würdet.
                    <br />
                    Wenn du als Leser also einen Server möchtest, in dem Frauen vermeintlich historisch korrekt auftreten (wie gesagt, der Faktengehalt in den Aussagen zur Rolle der Frau hält sich bei dieser Meinung stark in Grenzen), ist dies hier NICHT der richtige Server für dich.
                    <br />
                    Es wird hier weder eine Schlechterstellung von weiblichen Charakteren in irgendeiner Art geben, noch eine N-Bombe akzeptiert oder sonstiges.
                    <br />
                    Immersion hat eine Grenze. Und diese Grenze setzen wir ganz genau hier.
                  </MKTypography>
                  <MKTypography variant="h4" mt={6} mb={3}>
                    Wie kann ich teilnehmen?
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Bevor du auf unserem Server spielen kannst, musst du das Whitelist Verfahren für neue Spieler durchlaufen. Dazu siehe nachfolgend. Nachdem du deine Whitelist erhalten hast, musst du nur noch deine Technik startklar machen, damit deinem RP Erlebnis Nichts mehr im Wege steht.
                    <br />
                    Die technischen Voraussetzungen für die Teilnahme am RP findest du ebenfalls nachfolgend.
                    <br />
                    Bevor du deine Whitelist beantragen kannst, musst du dich allerdings zuerst auf unserem Discord befinden. 
                    Vergiss nicht vor dem Beantragen deiner Whitelist alle Regelwerke gründlich durchzulesen.
                  </MKTypography>

                  <MKTypography variant="h4" mt={6} mb={3}>
                    Technische Voraussetzungen
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Abseits der Whitelist, benötigst du natürlich auch noch einige technische Voraussetzungen, um auf unserem Server spielen zu können.
                  </MKTypography>
                    <MKTypography variant="h6" mt={6} mb={3}>
                      Salty Chat 
                    </MKTypography>
                    <MKTypography variant="body2" color="text">
                    Sobald du Teamspeak 3 installiert hast, musst du noch das Salty Chat Plugin installieren. Dieses kannst du {" "}<MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="https://gaming.v10networks.com/SaltyChat"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          hier
                        </MKTypography> installieren. 
                    <br />
                    </MKTypography>
                    <MKTypography variant="h6" mt={6} mb={3}>
                      Teamspeak
                    </MKTypography>
                    <MKTypography variant="body2" color="text">
                    Für die Kommunikation im Spiel benötigst du Teamspeak 3. Teamspeak 3 kannst du {" "}
                        <MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="https://www.teamspeak.de/download/"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          hier
                        </MKTypography> installieren.
                    </MKTypography>

                  <MKTypography variant="h4" mt={6} mb={3}>
                    Whitelist
                  </MKTypography>
                  <MKTypography variant="h6" mt={6} mb={3}> {/* Unter-Unterüberschrift: Zwei Stufen schwächer wie die Seitenüberschrift Einsteigerguide */}
                    Vor dem Antrag
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Bitte lies dir alle Regelwerke und Informationen sorgfältig durch. Am besten machst du dir schon vorab Gedanken zu deinem Charakterkonzept, das du auf The Western Hills bespielen möchtest.
                    <br />

                    Der Start deiner Whitelist-Anfrage beginnt nach Erstellen deines Accounts {" "}
                     
                        <MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="/whitelist"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          hier
                        </MKTypography>. 
                    <br />
                  </MKTypography>
                  <MKTypography variant="h6" mt={6} mb={3}>
                    Antrag stellen
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                                        Zuerst musst du dich auf unserer Website registrieren. Nach der Registrierung kannst du dich auf der Website einloggen.
                    <br />
                    Nachdem du dich eingeloggt hast, kannst du deine Whitelist beantragen. Hierzu kannst du einfach auf das nach der Registrierung erscheinende Banner drücken, oder auf den Button &lsquo;Whitelist&lsquo; in der Navigation.
                    <br />
                    Bevor du deine Whitelist beantragen kannst, musst du dich allerdings zuerst auf unserem Discord befinden. 
                    Vergiss nicht vor dem Beantragen deiner Whitelist alle Regelwerke gründlich durchzulesen.
                    <br />
 Den Antrag kannst du {" "}
                        <MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="/whitelist"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          hier
                        </MKTypography> stellen.
                    <br />
                    Nachdem du deine Whitelist beantragt hast, wird für dich im Discord automatisch ein Kanal eröffnet. In diesem Kanal bekommst du alle weiteren Informationen und deine Rückmeldung zu deinem Antrag, sobald dieser bearbeitet wurde.
                    <br />
                    Wichtig: Sollte nach Abschluss der Whitelist kein Kanal für dich erstellt worden sein, melde dich bitte im Support.
                  </MKTypography>

                  <MKTypography variant="h4" mt={6} mb={3}> 
                    Vor dem Spielstart
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Bitte überprüfe vor deinem RP dein Aufnahmeprogramm! (Supportfälle wie Erstattungen können nur mit Videobeweis bearbeitet werden!) 
                    <br />
                    Vergewissere dich nochmals, dass dein RedM auf Beta oder Unstable eigestellt und dein Ingame-Voicechat ausgeschaltet ist!
                  </MKTypography>

                  <MKTypography variant="h4" mt={6} mb={3}> 
                    Hilfestellungen zum Spielstart
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Du hast die Whitelist bestanden, deinen Charakter erstellt und spawnst zum aller ersten Mal im County. Doch was jetzt?
                    <br />
                    Wie verdiene ich mein erstes Geld, in welcher Stadt lasse ich mich nieder oder wie sieht mein Weg ab hier aus? All das sind Fragen, welche dir eventuell während deiner Anfangszeit aufkommen werden. Viele dieser Fragen lassen sich im Roleplay mit anderen Spielern klären. Wie im richtigen Leben hilft es in soziale Interaktion zu treten. Lasse dich von deiner Umgebung und deinen Erlebnissen leiten. Aller Anfang ist schwer, so wie das Leben in einem neuen Land eben so sein kann.
                    
                  </MKTypography>
                  {/* Platzhalter */}
                  {/*  
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Title
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    body
                  </MKTypography>
                  */}
                             
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
