/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKPagination from "components/MKPagination";

// Material Kit 2 PRO React components
import SimpleBookingCard from "examples/Cards/BookingCards/SimpleBookingCard";

// Images
import product1 from "assets/images/products/product-1-min.jpg";
import product2 from "assets/images/products/product-2-min.jpg";
import product3 from "assets/images/products/product-3-min.jpg";
import product4 from "assets/images/products/product-5-min.jpg";
import product5 from "assets/images/products/product-6-min.jpg";
import product6 from "assets/images/products/product-7-min.jpg";

import React, { useState, useEffect } from 'react';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

function Places({ filterTag }) {
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);  // initialize with 1

  console.log(currentPage);
  
  const limit = 9;
  const offset = (currentPage - 1) * limit;

  const endpoint = filterTag 
    ? `/webpageDB/articles?tag=${filterTag}&limit=${limit}&offset=${offset}`
    : `/webpageDB/articles?limit=${limit}&offset=${offset}`;
  useEffect(() => {
    fetch(API_URL + endpoint, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        setArticles(data.articles);
        setTotalPages(Math.ceil(data.totalCount / limit));  // Assuming the API sends the totalCount of articles
      })
      .catch(error => {
        console.error('Failed to fetch articles:', error);
      });
  }, [filterTag, currentPage]);
  const actionProps = {
    type: "internal",
    route: "/pages/landing-pages/rental",
    color: "info",
    label: "Mehr lesen",
  };
  function truncateContent(content, limit = 150) {
    return content.length > limit 
      ? `${content.substring(0, limit)}...` 
      : content;
  }

  function createActionProp(index) {
    let actionProps = {
      type: "internal",
      route: "/article/"+index,
      color: "info",
      label: "Mehr lesen",
    };
    console.log(actionProps.route)
    return actionProps
  }

  return (
    <MKBox component="section" py={3} key={currentPage}>
      <Container>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          {articles.map(article => (
            <Grid item xs={12} md={6} lg={4} key={article.id}>
              <MKBox mt={3}>
                <SimpleBookingCard
                  image={article.thumbnail_url}
                  title={article.title}
                  description={article.preview_text}
                  categories={[article.tag]}
                  action={createActionProp(article.id)}  // Assuming this prop remains the same for all
                />
              </MKBox>
            </Grid>
          ))}
        </Grid>
        {/* Pagination remains unchanged */}
        <MKBox mt={5}>
        <MKPagination>
          <MKPagination item onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>
            <Icon>keyboard_arrow_left</Icon>
          </MKPagination>
          {[...Array(totalPages)].map((_, idx) => (
            <MKPagination 
              key={idx}
              item 
              active={idx + 1 === currentPage}
              onClick={() => setCurrentPage(idx + 1)}
            >
              {idx + 1}
            </MKPagination>
          ))}
          <MKPagination item onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}>
            <Icon>keyboard_arrow_right</Icon>
          </MKPagination>
        </MKPagination>
      </MKBox>
      </Container>
    </MKBox>
  );
}

export default Places;
