/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import { useState, useEffect, useMemo } from "react";
import { SignOutButton, SignInButton, ClerkProvider, useUser, useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";
import React from 'react';

// Images
import profilePicture from "assets/images/bruce-mars.jpg";


const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;


function Profile() {
  const { isLoaded, isSignedIn, user } = useUser();
  const { userId, sessionId, getToken } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);


  useEffect(() => {
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);

  const changeUsername = () => {
  user.update({ username: "dietrich" });
  };
  //for each organisation create a mkbadge with the name of the organisation
  const colorMapping = {
    default: "info", // default color
    team: "primary", // replace with real organization slug and color
    "whitelist-support": "info",
    whitelisted: "success",
    donator: "warning",
    // add other organization slug to color mappings as necessary
  };
  
  const priorityMapping = {
    team: 1, // replace with real organization slug and priority
    "whitelist-support": 2,
    whitelisted: 3,
    // add other organization slug to priority mappings as necessary
    // lower numbers represent higher priority
  };
  
  const sortedRoles = [...userRoles].sort((a, b) => {
    const priorityA = priorityMapping[a] || Number.MAX_VALUE;
    const priorityB = priorityMapping[b] || Number.MAX_VALUE;

    return priorityA - priorityB;
});

const renderedRoles = sortedRoles.map(role => {
    const color = colorMapping[role] || colorMapping.default;

    return (
        <React.Fragment key={role}>
            <MKBadge
                variant="contained"
                color={color}
                badgeContent={role}
                container
                sx={{ mb: 2 }}
            />
            <span key={"span" + role} style={{ marginRight: '5px' }} /> {/* Add a small space */}
        </React.Fragment>
    );
});

  
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={user.imageUrl} alt="Burce Mars" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={2}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h3">@{user.username}</MKTypography>
                {/* <MKButton variant="outlined" color="info" size="small" onClick={changeUsername}>
                  Follow
                </MKButton> */}
              </MKBox>
              {renderedRoles}
              {/* <Grid container spacing={3} mb={3}>
                
                <Grid item>
                  
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    323&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Posts
                  </MKTypography>
                </Grid>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    3.5k&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Followers
                  </MKTypography>
                </Grid>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    260&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Following
                  </MKTypography>
                </Grid>
              </Grid>
              <MKTypography variant="body2" fontWeight="light" color="text">
                {user.publicMetadata.description}
              </MKTypography> */}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
