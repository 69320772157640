/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import bgimageBasic from "assets/images/bg-featurecard-basic.jpg";
import bgimagePodcast from "assets/images/bg-featurecard-podcast.jpg";
import bgimageDevelopment from "assets/images/bg-featurecard-development.jpg";
import bgwiki from "assets/images/bg-wiki.jpg";

const imagesPrefix =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/sections";


  export default [
    {
      title: "Spielsysteme",
      description: "Individuell Entwickelte Spielsysteme",
      items: [
        {
          image: bgimageBasic,
          name: "Spielzeit-Level-System",
          count: "mit Stufenpass und einzigartigen Belohnungen",
          route: "",
        },
        {
          image: bgimageBasic,
          name: "Erleichterter Spielstart",
          count: "durch Welpenschutz je nach Level",
          
        },
        {
          image: bgimageBasic,
          name: "Gratis-Spielbelohnungen",
          count: "in Form von Lootboxen ",
  
        },
        {
          image: bgimageBasic,
          name: "Hauseigenes Sammelkartensystem",
          count: "mit Boosterpacks",
  
        },
        {
          image: bgimageBasic,
          name: "Hauseigenes Outlaw-Addon",
          count: "mit Gefängnisinselmechaniken",
  
        },
        {
          image: bgimageBasic,
          name: "Tägliche Aufgaben",
          count: "mit besonderen Belohnungen",
  
        },
        {
          image: bgimageBasic,
          name: "Respawnmechanik",
          count: "mit Fundbüro und Nachwirkungen",
  
        },
        {
          image: bgimageBasic,
          name: "Individuelles Dispatch-System",
          count: "für Ärzte und Law",
        },
        {
          image: bgimageBasic,
          name: "Individuelles Mapdesign",
          count: "durch massive Erweiterungen",
  
        },
        {
          image: bgimageBasic,
          name: "Und vieles mehr...",
        },
      ],
    },
    {
      title: "Zusätzliche Angebote",
      description: "Angebote ausserhalb des Roleplays",
      items: [
        {
          image: bgimagePodcast,
          name: "Entwickler-/Server-Podcast",
          count: "Behind the Hills [Verfügbar auf Anchor, Spotify, Google Podcasts und Amazon Music.]",
          route: "https://open.spotify.com/show/0HtpsJXZ85lcribgdZVnwj?si=9aa6bce6c92a43d2",
        },
        {
          image: bgwiki,
          name: "Eigenes Wiki",
          count: "über Lore und Spielmechaniken",
          route: "https://wiki.western-hills.de",
        },
        {
          image: bgimageDevelopment,
          name: "Auftragsentwicklung",
          count: "für andere Server auf der ganzen Welt",
          route: "https://docs.western-hills.de",
        },
      ],
    },
  ];
