/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CloseIcon from "@mui/icons-material/Close";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import MKInput from "components/MKInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import WhitelistsOpen from "./components/whitelistsOpen"
import WhitelistsDone from "./components/whitelistsDone"

// Data
import authorsTableData from "layouts/dashboard/whitelistsAdmin/data/openWhitelistsTableData";
import projectsTableData from "layouts/dashboard/whitelistsAdmin/data/projectsTableData";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme-dashboard";


// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";


// RTL plugins
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
// Material Dashboard 2 React routes
import sidenavRoutes from "sidenavRoutes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import { Routes, Route, Navigate, useLocation, useNavigate} from "react-router-dom";
import { useState, useEffect, useMemo } from "react";

import Icon from "@mui/material/Icon";
// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

import { filterRoutesByRole } from 'routes';

import { ThemeProvider } from "@mui/material/styles";
import { useParams } from 'react-router-dom';
import { SignOutButton, SignInButton, ClerkProvider, useUser, useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";
import Whitelist from "pages/Support/Whitelist";
import Snackbar from "@mui/material/Snackbar";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";


const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

function WhitelistViewFunction() {
  const { id } = useParams();
  const [controller, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const { userId, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);
  const [whitelistData, setWhitelistData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [adminUserData, setAdminUserData] = useState([])

  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal)

  const [commentInput, setCommentInput] = useState('');

  const [acceptStatusText, setAcceptStatusText] = useState("akzeptieren")

  
 

  useEffect(() => {
    if(!userId ||typeof userId === "undefined") return;
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {

        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);

  useEffect(() => {
    if(!id || id === "undefined") return;
    fetch(API_URL + '/whitelist/' + id, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        setWhitelistData(data)
        if(data.state === "assigned"){
          setShow(true)
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [userId, adminUserData]);


  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  

  const toastStyles = ({
    palette: { warning },
    borders: { borderRadius },
    typography: { size },
    boxShadows: { lg },
  }) => ({
    "& .MuiPaper-root": {
      backgroundColor: warning.main,
      borderRadius: borderRadius.lg,
      fontSize: size.sm,
      fontWeight: 400,
      boxShadow: lg,
      px: 2,
      py: 0.5,
      my: 5,
    },
  });

  const toastTemplate = (
    <MKBox display="flex" justifyContent="space-between" alignItems="center" color="white">

      ACHTUNG! Diese Whitelist wurde bereits von einem anderen Supporter beansprucht! 
      <CloseIcon
        fontSize="medium"
        sx={{ mr: -1, ml: 2, cursor: "pointer" }}
        onClick={toggleSnackbar}
      />
      
    </MKBox>
  );

  const DisplayQuestionsAnswers = ({ questions, answers }) => {
    return questions.pages.map((page, pageIndex) => (
      <div key={`page-${pageIndex}`}>
        {page.elements.map((element) => {
          //const question = element.title && element.title.de ? element.title.de : "Unknown Question";
          const question = element.title && element.title.de ? element.title.de : "";
  
          if (element.type === "panel" && question != "") {
            return (
              <div key={element.name}>
                <br />
                <MKTypography
                  variant="h4"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                  >{question}</MKTypography>
                
                {element.elements.map((subElement) => {
                  //const subQuestion = subElement.title && subElement.title.de ? subElement.title.de : "Unknown Sub-Question";
                  const subQuestion = subElement.title && subElement.title.de ? subElement.title.de : "";
                  let subDisplayAnswer = answers[subElement.name];
  
                  if ((subDisplayAnswer === undefined || subDisplayAnswer === null)&& subElement.title != null ) {
                    subDisplayAnswer = "Keine Antwort";
                  } else if (["dropdown", "radiogroup"].includes(subElement.type) && subElement.choices) {
                    if (Array.isArray(subDisplayAnswer)) {
                      subDisplayAnswer = subDisplayAnswer.map(item => {
                        const choice = subElement.choices.find(choice => choice.value === item);
                        return choice && choice.text && choice.text.de ? choice.text.de : item;
                      }).join(', ');
                    } else if (typeof subDisplayAnswer === 'string') {
                      const choice = subElement.choices.find(choice => choice.value === subDisplayAnswer);
                      subDisplayAnswer = choice && choice.text && choice.text.de ? choice.text.de : subDisplayAnswer;
                    }
                  } else if (typeof subDisplayAnswer === 'boolean') {
                    subDisplayAnswer = subDisplayAnswer ? 'Ja' : 'Nein';
                  }
                  if(!subElement.title)return null; //if unknown question
                  return (
                    <div key={subElement.name}>
                      <strong>{subQuestion}</strong>:<br />{subDisplayAnswer}
                      <br />
                    </div>
                  );
                })}
                <br />
                <br />
              </div>
            );
          } else if(question != "") {
            let displayAnswer = answers[element.name];
  
            if (displayAnswer === undefined || displayAnswer === null) {
              displayAnswer = "No answer was given";
            } else if (["dropdown", "radiogroup", "checkbox"].includes(element.type) && element.choices) {
              // Check if the answer is either an array or an object
              if (Array.isArray(displayAnswer) || typeof displayAnswer === 'object') {
                // Convert object to array of values if needed
                const answerArray = Array.isArray(displayAnswer) ? displayAnswer : Object.values(displayAnswer);
                
                displayAnswer = answerArray.map(item => {
                  const choice = element.choices.find(choice => choice.value === item);
                  return choice && choice.text && choice.text.de ? choice.text.de : item;
                }).join(', ');
              } else if (typeof displayAnswer === 'string') {
                const choice = element.choices.find(choice => choice.value === displayAnswer);
                displayAnswer = choice && choice.text && choice.text.de ? choice.text.de : displayAnswer;
              }
            } else if (typeof displayAnswer === 'boolean') {
              displayAnswer = displayAnswer ? 'Ja' : 'Nein';
            }
  
            return (
              <div key={element.name}>
                <strong>{question}</strong>:<br /> {displayAnswer}
              </div>
            );
          }
        })}
      </div>
    ));
  };
  
  useEffect(() => {
    GetUserDataByID(whitelistData.user)
  },[whitelistData])

  useEffect(() => {
    if(!userId || typeof userId === "undefined") return;
    fetch(API_URL + '/users/fetchUserById/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        setAdminUserData(data)
      })
      .catch(error => {
        console.error('Error:', error);
      });
  },[userId])

  const GetUserDataByID = (userID) => {
    if(!userID || typeof userID === "undefined") return;
    fetch(API_URL + '/users/fetchUserById/' + userID, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        setUserData(data)
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  
  
  
  
  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );



  //accept whitelist
  const acceptWhitelist = () => {

    setAcceptStatusText("akzeptieren")
    toggleModal()
    // accept wl logic
  };

  //decline whitelist
  const declineWhitelist = () => {

    setAcceptStatusText("ablehnen")
    toggleModal()
    // accept wl logic
  };

  const claimWhitelist = () => {
    if(!whitelistData || !adminUserData || isObjectEmpty(adminUserData)){
      console.log("No WhitelistData or Admin found")
      return
    }
    //logic to update status of wl whitelistData.id
    fetch(API_URL+"/whitelist/status/"+whitelistData.id, {
      method: 'POST',
      headers: {
        'x-api-key': API_KEY,
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify({
        "status": "assigned",
        "supporterId": adminUserData.username
    }
    )
    })
    .then(response => {
      if (response.ok) {

        window.location.reload()
        //success message
      } else {
        // Handle error
      }
    })
    .catch(error => {
      // Handle error
    });
  };

  function addUserRole(userId, roleToAdd){
    if (userId){
      //send api call with userid and role to add to API_URL + "users/addUserRole/:userId/:role"
      console.log("send Api call for user: "+userId)
      fetch(API_URL + '/users/addUserRole/' + userId+"/"+roleToAdd, {
        headers: {
          'x-api-key': API_KEY
        }
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
  
        })
        .catch(error => {
          console.error('Error:', error);
  
        });
    }else{
      null
    }
  
  }

  function removeUserRole(userId, roleToRemove){
    if (userId){
      //send api call with userid and role to add to API_URL + "users/addUserRole/:userId/:role"
      console.log("send Api call to remove role"+roleToRemove+" for user: "+userId)
      fetch(API_URL + '/users/removeUserRole/' + userId+"/"+roleToRemove, {
        headers: {
          'x-api-key': API_KEY
        }
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
  
        })
        .catch(error => {
          console.error('Error:', error);
  
        });
    }else{
      null
    }
  
  }

  //update wl state
  const navigate = useNavigate();
  const updateWhitelistStatus = (data) => {
    let status = "accepted"

    if(data.status === "ablehnen"){
      status = "declined"
    }

    if(!whitelistData || !adminUserData || isObjectEmpty(adminUserData)){
      console.log("No WhitelistData or Admin found")
      return
    }
    //logic to update status of wl whitelistData.id
    fetch(API_URL+"/whitelist/status/"+whitelistData.id, {
      method: 'POST',
      headers: {
        'x-api-key': API_KEY,
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify({
        "status": status,
        "supporterId": adminUserData.username,
        "comment": data.comment
    }
    )
    })
    .then(response => {
      if (response.ok) {
        if(status === "accepted"){
          addUserRole(whitelistData.user, "whitelisted")
          //removeUserRole(whitelistData.user, "whitelist-pending")
        }else if(status === "declined"){
          removeUserRole(whitelistData.user, "whitelist-pending")
        }
        setShowModal(false)
        
        navigate('/whitelists-admin');
        //success message
      } else {
        alert("Fehler beim Aktualisieren der Whitelist")
      }
    })
    .catch(error => {
      alert("Fehler beim Aktualisieren der Whitelist: "+error)
    });


  }

  const roleToCheck = "team"

  const filteredRoutes = filterRoutesByRole(sidenavRoutes, userRoles);

  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0
  }

  if (!isLoading && (!userRoles.includes(roleToCheck)&&!userRoles.includes("whitelist-support"))) {
    return <Navigate to="/" />; // Redirect to the home screen
  } 

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
    <DashboardLayout>
      <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Team Dashboard"
              routes={filteredRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
          <Card>
          
              <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={show}
              onClose={toggleSnackbar}
              message={toastTemplate}
              sx={toastStyles}
              />

          {whitelistData && !isLoading && userData ?(
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
              <MDTypography variant="h6" color="white">
                Whitelist für User: {userData.username} | UserId: {userData.id}
              </MDTypography>
              
              </MDBox>
            ):(
              null
            )}

          {whitelistData && !isLoading && whitelistData.state === "pending" ?(
            <Container>
            <Grid container justifyContent="center" py={2}>
              <Stack direction="row" alignitems="flex-end" spacing={1}>
                <MKButton onClick={(event) => {claimWhitelist()}} variant="gradient" color="warning">
                  Beanspruchen
                </MKButton>

              </Stack>
            </Grid>
          </Container>
            ):(
              null
            )}
          
          <MDBox pt={3} px={2}>
            {whitelistData && !isLoading ?(
              
              <DisplayQuestionsAnswers questions={whitelistData.question} answers={whitelistData.answer} />
              
            ):(
              null
            )}
            {/* whitelistData.state === "pending" || */}
            {whitelistData && !isLoading && ( whitelistData.state === "assigned" && whitelistData.supporterId === adminUserData.username )?(
              
              <MKBox component="section" py={12}>
              <Container>
                <Grid container justifyContent="center">
                  <Stack direction="row" alignitems="flex-end" spacing={1}>
                    <MKButton onClick={(event) => {declineWhitelist()}} variant="gradient" color="error">
                      Ablehnen
                    </MKButton>

                    <MKButton onClick={(event) => {acceptWhitelist()}} variant="gradient" color="success">
                      Annehmen
                    </MKButton>



                  </Stack>
                </Grid>
              </Container>
      <Modal open={showModal} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={showModal} timeout={500}>
            <MKBox
              position="relative"
              width="500px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <MKBox display="flex" alginitems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">Whitelist {acceptStatusText} </MKTypography>
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <MKTypography variant="body2" color="secondary" fontWeight="regular">
                  Bist du dir sicher, dass du die Whitelist {acceptStatusText} willst?<br />
                  <br />
                  Falls notwendig, kann ebenfalls noch ein Kommentar angehängt werden:
                  <br />
                  
                </MKTypography>
                <MKInput value={commentInput} onChange={(e) => setCommentInput(e.target.value)}  variant="standard" label="Kommentar" rows={4} multiline fullWidth />
              </MKBox>
              
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                  Abbrechen
                </MKButton>
                <MKButton variant="gradient" color="success" onClick={(event) => {updateWhitelistStatus({status: acceptStatusText, comment: commentInput})}}>
                  Bestätigen
                </MKButton>
              </MKBox>
            </MKBox>
          </Slide>
        </Modal>
    </MKBox>
              
            ):(
              null
            )}
          </MDBox>
          </Card>
          </Grid>
          <Grid item xs={12}>
            
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
    </ThemeProvider>
  );
}

export default WhitelistViewFunction;
