// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { filterRoutesByRole } from 'routes';
import loading from "components/Loading";
import { useState, useEffect } from "react";
import { SignOutButton, SignInButton, ClerkProvider, useUser,useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
function Privacy() {
  const { userId, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);
  useEffect(() => {
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);
  const filteredRoutes = filterRoutesByRole(routes, userRoles);
  return (
    <>
      <DefaultNavbar
        routes={filteredRoutes}
        action={{
          type: "external",
          route: "",
          label: "buy now",
          color: "dark",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Law-Guide 
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Das Gesetz zu vertreten und zu stützen ist die wichtigste Aufgabe der vielen Gesetzeshüter/innen.
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                     Einführung
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Wenn du dich für den Weg entschieden hast, das Gesetz zu vertreten, übernimmst du eine sehr verantwortungsvolle Aufgabe im Staat Western Hills. Ähnlich wie bei Crime-Spielern ist es auch deine Aufgabe darauf zu achten, dass es kein Gewinnen oder Verlieren gibt. Lediglich eine Bereicherung und Entwicklung im RP für alle.
                    <br />Versetze dich in die Rolle deines Gegenübers und überlege, ob du Spaß an seiner Stelle hättest. Es gibt nur Gewinner, wenn du und dein Gegenpart Spieler beide nach einer RP Situation denken: „Das war fantastisch, mit diesem Charakter möchte ich gerne wieder interagieren.“
                    <br />Das bedeutet, dass die Law-Spieler sowie die Crime-Parteien sich gegenseitig den RP Ball zuspielen und gemeinsame Stränge miteinander aufbauen. Ganz nach dem Motto: Langfristiges RP schaffen. Eine Verfolgungsjagd bringt mehr RP als jemanden direkt niederzuschießen, weil er wegläuft. Der Verfolgte stürzt eventuell im Verlauf der Verfolgungsjagd oder man schafft es, mit Argumenten den Verfolgten zu überreden, stehen zu bleiben.
                    <br />Einen maskierten Outlaw von einem Überfall direkt bei der zweiten Begegnung zu 100 Prozent an der Stimme wiederzuerkennen, während er gut verkleidet als Bürger der Stadt zum Arzt geht, ist nicht förderlich für langfristiges RP. Vermutungen können entstehen und sind hier die richtige Herangehensweise. Ein gewisses Feingefühl ist gefragt, um die RP Stränge langsam aufzubauen. Ganz wie eine Geschichte die durch Einleitung, Hauptteil und Schluss aufgebaut wird.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Aufgaben und Strukturen in den Western Hills
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Als Gesetzeshüter/in in der Zeit um 1899 gibt es viele Aufgaben und Pflichten, die zu erfüllen sind. Zuallererst ist es wichtig, die Bürger zu schützen und für die Einhaltung der Gesetze zu sorgen. Dazu gehört auch, hauptsächlich in den Städten Straftaten zu verhindern und zu bekämpfen sowie Verhaftungen durchzuführen. Diese Arbeit erfordert Mut, Entschlossenheit und Durchsetzungsvermögen. Nicht selten stand man mit diesem Beruf, gleich wie die Kriminellen, nah an der Schwelle zum Tod. Tägliche gefährliche Situationen oder gröbere Fehler konnten den frühen Tod eines Sheriffs/Deputy bedeuten.
                    <br />U.S.Marshall und State Marshalls sind auf Bundesebenen tätig und bestimmen und befehligen die County Sheriffs, welche auf Bezirksebene tätig sind. Eine Vielzahl von Aufgaben gehören zum täglichen Aufgabenbereich, einschließlich der Durchsetzung von Gesetzen und Verordnungen, der Überwachung von Gefängnissen und Haftanstalten, der Wahrung der öffentlichen Ordnung und Sicherheit sowie der Gerichtsvollstreckung. County Sheriffs sind für die Überwachung und Kommunikation zwischen den verschiedenen Departments zuständig. Auch Grenzübergreifende Fälle werden von den County Sheriffs geleitet und strukturiert. Sheriffs aus New Hannover, Lemoyne und New Austin sind die Grundpfeiler der Sheriff-Offices und halten die Verantwortung über ihre Deputys und deren Arbeit und Strukturen innerhalb der County Grenzen. Auch haben Sheriffs die Aufgabe für die Unterstützung von Gerichtsverfahren, die Durchführung von Untersuchungen und die Unterstützung anderer Strafverfolgungsbehörden zu sorgen.
                    <br />Deputy Sheriffs waren in der Regel Stellvertreter des Sheriffs und unterstützten diesen bei der Erfüllung seiner Aufgaben. Sie hatten ähnliche Verantwortlichkeiten wie Sheriffs, einschließlich der Durchsetzung von Gesetzen, der Überwachung von Gefängnissen und Haftanstalten und der Wahrung der öffentlichen Ordnung und Sicherheit. Deputys wurden oft auch beauftragt, Rechtsdokumente wie Gerichtsbeschlüsse und Vorladungen zuzustellen und bei Ermittlungen mitzuarbeiten. Sheriffs und Deputys arbeiten nah am Volk und viele von ihnen helfen gerne den Bürgern, wenn sie Schutz erbitten, weil die Überfälle an ihrer Ranch zunehmen. Patrouillen in der Stadt oder Übungsszenarien gehören ebenfalls zu täglichen Aufgaben.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Wie fange ich an?
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Verschaffe dir einen Überblick über die Umgebung, lerne Land und Leute sowie Gesetzeshüter/innen in den jeweiligen Städten kennen. Erkundige dich, wo du vorstellig werden kannst, um das Gesetz zu vertreten. Mache dir Gedanken, warum du das tun möchtest, dies wird den Sheriff sicher interessieren.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                        Besorge dir wenn möglich ein Pferd, mit dem du bei deiner Deputy Arbeit gut zurecht kommst.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                        Praktikanten und Anwärter Deputy&apos;s erlernen in ihrer ersten Zeit die wichtigsten Grundlagen und erhalten so Erfahrung und einen umfassenden Einblick in die Arbeit mit dem Gesetz. Ausbildungsinhalte können folgende Szenarien beinhalten:

                        </MKTypography>
                          <MKBox component="ul" my={3} ml={6}>
                            <MKBox component="li">
                              <MKTypography variant="body2" color="text" fontWeight="regular">
                                Schuss auf bewegliche und stillstehende Ziele.
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography variant="body2" color="text" fontWeight="regular">
                              Umgang mit dem Pferd während verschiedener Situationen und Parkour reiten.
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography variant="body2" color="text" fontWeight="regular">
                              Respektvoller aber auch dominanter Umgang mit Verbrechern und Zeugen, -Entwaffnung einer Person, Blocken und ausweichen (Boxkampf).
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography variant="body2" color="text" fontWeight="regular">
                              Ortskenntnisprüfung (N,O,S,W),Beschreibung von Orten und oder Wegen.
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography variant="body2" color="text" fontWeight="regular">
                              Verhalten bei Fällen in der Leib und Leben gefährdet ist.
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography variant="body2" color="text" fontWeight="regular">
                                Wie verkündet man einen Haftbefehl?
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography variant="body2" color="text" fontWeight="regular">
                                Wie verhält man sich, wenn eine Strafe nicht gezahlt wird?
                              </MKTypography>
                            </MKBox>
                          </MKBox>
                      </MKBox>
                      {/* <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                        Gesetzeshüter/innen arbeiten eng mit weiteren staatlichen Gemeinschaften zusammen wie dem Government, welches Gesetze in Kraft setzt, staatliche Berufe bezahlt oder weitere Unterstützung erbringt – in schwierigen wie auch guten Situationen. Enge Zusammenarbeit besteht ebenfalls mit weiteren Politikern des Countys: Die Bürgermeister (County Mayor) der Bundesstaaten, diese vertreten die Countys und agieren als beratende Stellen, können Gesetze verabschieden nach demokratischer Abstimmung durch die Bürger.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                        Genauso ist die Ärztegemeinschaft ein wichtiger Anker in der Gesetzesarbeit, um medizinische Versorgung zu gewährleisten, aber auch um Informationen über eventuelle, zwielichtige Personen zu erhalten, oder um Tatorte mit den Ärzten zu begutachten. Der Undertaker/Bestatter ist auch immer ein bekanntes Gesicht bei der Arbeit mit dem Gesetz. Er sorgt dafür, dass nach kleinen und größeren Tragödien alle ihre letzte Ruhe finden und die Spuren des Todes verschwinden. Das Einwohnerregister wird gepflegt. Durch die Totenscheine, die von den Ärzten ausgestellt werden, werden die Toten aus dem Register gelöscht.
                        </MKTypography>
                      </MKBox> */}
                   
                  </MKBox>
                    <br />Gesetzeshüter/innen arbeiten eng mit weiteren staatlichen Gemeinschaften zusammen wie dem Government, welches Gesetze in Kraft setzt, staatliche Berufe bezahlt oder weitere Unterstützung erbringt – in schwierigen wie auch guten Situationen. Enge Zusammenarbeit besteht ebenfalls mit weiteren Politikern des Countys: Die Bürgermeister (County Mayor) der Bundesstaaten, diese vertreten die Countys und agieren als beratende Stellen, können Gesetze verabschieden nach demokratischer Abstimmung durch die Bürger.
                    <br />Genauso ist die Ärztegemeinschaft {/* TO-DO Dietrich: Wort "Ärztegemeinschaft" verlinken mit: https://wiki.western-hills.de/de/HowTo/Guides/Aerzte-Guide */} ein wichtiger Anker in der Gesetzesarbeit, um medizinische Versorgung zu gewährleisten, aber auch um Informationen über eventuelle, zwielichtige Personen zu erhalten, oder um Tatorte mit den Ärzten zu begutachten. Der Undertaker/Bestatter {/* TO-DO Dietrich: Wort "Bestatter" verlinken mit: https://wiki.western-hills.de/de/Lore/Wissenswertes/Bestatter */} ist auch immer ein bekanntes Gesicht bei der Arbeit mit dem Gesetz. Er sorgt dafür, dass nach kleinen und größeren Tragödien alle ihre letzte Ruhe finden und die Spuren des Todes verschwinden. Das Einwohnerregister wird gepflegt. Durch die Totenscheine, die von den Ärzten ausgestellt werden, werden die Toten aus dem Register gelöscht.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                     Korruption
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Den staatlichen Fraktionen ist schwere Korruption untersagt, wie im Regelwerk §15 Korruption vermerkt. Eine schwere Korruption gleicht einem Zusammenbruch der gesetzlichen Strukturen und das Rollenspielerlebnis für alle anderen Mitspieler würde massiv darunter leiden.
                    <br />Kleinere Formen von Korruption können beispielsweise darin bestehen, dass man ein Auge zudrückt, wenn jemand eine geringfügige Straftat begeht, oder das bestimmte Personen vom Sheriff/Deputy bevorzugt werden, weil durch die bevorzugte Person Vorteile entstehen. Es ist jedoch wichtig, dass du deine Position nicht ausnutzt, um Geld oder andere Vergünstigungen zu erhalten. Zusätzlich ist jeder staatliche Angestellte dazu verpflichtet, Korruption oder schwere Gesetzesbrüche zu melden. Dies bedeutet auch, dass man seinen eigenen Vorgesetzten zur Rechenschaft ziehen müsste, indem man sich an höhere Instanzen wendet und Beweise vorlegt. Niemals sollte es somit zu einem Putschversuch gegenüber einer Person kommen, sondern immer das RP und die Beweise gesucht werden, um dann über staatliche Wege und Gesetze des Landes Aufklärung zu bringen.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Zwischen Zellen, RP und Strafen
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Das Zellen-RP stellt eine besondere Herausforderung für beide Parteien dar. Es kann eine großartige Möglichkeit bieten, spannende Szenarien zu schaffen. Allerdings ist es wichtig, dass beide Seiten des Rollenspiels zu jeder Zeit aktiv beteiligt sind, um RP zu erleben und um zu vermeiden, dass der Gefangene zu lange nicht am RP teilnehmen kann, vielleicht sogar unschuldig eine ganze Stunde hinter Gittern saß ohne viel RP. Im schlimmsten Fall nur, weil er einem Bankräuber zum Verwechseln ähnlich sieht. Natürlich ist es kein Problem, sich mit den Kollegen vor der Türe ein paar wenige Minuten abzusprechen. Ziel ist es, Gefangenen so viele Informationen wie möglich zu entlocken. Hierfür kommen verschiedene Methoden in Frage. Jeder Gefangene ist anders; Der eine plaudert vielleicht mehr, weil man ihm eine Zigarre anbietet, der nächste würde nicht mal für viel Geld etwas ausplaudern. Deswegen ist es wichtig, festgenommene Personen in die Situation mit einzubeziehen, dass es in der Zelle angenehm, aber auch nicht so angenehm werden kann. Die Härte des Gesetzes wird irgendwann zuschlagen können. Mit genug Beweisen droht irgendwann sogar der Galgen. Der/Die Gesetzeshüter können zum Beispiel die Zelle betreten, um den Verhafteten zu verhören oder ihm seine Rechte zu erklären. Beim Verhör wird der/die Gefangene genau überlegen, was er von sich preisgibt: Der Verhaftete kann versuchen zu fliehen, indem er versucht, das Schloss der Zellentür zu knacken oder andere Gegenstände in der Zelle zu benutzen. Der Gefangene könnte gewisse Orte außerorts erwähnen, um die Gesetzeshüter in eine spätere Falle oder Irreführung zu locken.
                    <br />Es können weitere RP Stränge aufgebaut werden, indem andere fachkundige Personen zu Fällen hinzugefügt werden, wie z.B. ein Geistlicher, der bei der Aufklärung und Sensibilisierung bei der Befragung von hoch religiösen Verbrechern helfen kann, höchst brisante Hintergrund Informationen zu entlocken. Oder ein Arzt/Geistlicher, der vor einer Hinrichtung mit den Gefangenen sprechen kann.
                    <br />Zielführendes RP: Es ist zu vermeiden, direkt bei einem ersten Vergehen einem Crime-Spieler permanent mit dem Galgen zu drohen: „Person XY wird immer wieder auffällig.“ Ihr habt ihn diverse Male festgenommen wegen verschiedenen Sachen, die eine Hinrichtung rechtfertigen würden. „Bei der nächsten Situation droht ihr der Person beim nächsten Vergehen an, euch darum zu bemühen, dass er am Galgen landet!“ Alle Strafen, Hinrichtungen oder lange Haftstrafen auf Sisika, werden durch IC Gesetze geregelt und durch entsprechende Personen verhandelt und vollstreckt. Genauso sind Crimler angehalten ihre Ziele langsam, verdeckt aufzubauen oder gar stumpfes Bereicherungs-RP zu betreiben, um langrifistiges und grandioses RP für beide Seiten zu erhalten.
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}
export default Privacy;