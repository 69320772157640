// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleFooter from "examples/Footers/SimpleFooter";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

// HelpCenter page components
import FaqCollapse from "pages/Support/Faq/components/FaqCollapse";
import Table from "examples/Tables/Table";

import KeyboardLayout from "./components/keyboardLayout";

// Routes
import routes from "routes";

import { filterRoutesByRole } from 'routes';
import loading from "components/Loading";
import { useState, useEffect } from "react";
import { SignOutButton, SignInButton, ClerkProvider, useUser,useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;



function Faq() {
  const [collapse, setCollapse] = useState(false);
  const { userId, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);
  const { columns, rows } = {
    columns: [
      { name: "command", align: "center" },
      { name: "Funktion", align: "center" },
    ],

    rows: [
        // Clothing data
        { command: "satchels", Funktion: "Tasche" },
        { command: "shirt", Funktion: "Hemd an/aus" },
        { command: "boots", Funktion: "Schuhe an/aus" },
        { command: "pant", Funktion: "Hose an/aus" },
        { command: "vest", Funktion: "Weste an/aus" },
        { command: "bandana", Funktion: "Bandana hochziehen, runterziehen" },
        { command: "bandanabegone", Funktion: "Bandana an/aus" },
        { command: "coat", Funktion: "Jacke an/aus" },
        { command: "ccoat", Funktion: "Geschlossene Jacken an/aus" },
        { command: "chap", Funktion: "Chaps an/aus" },
        { command: "hat", Funktion: "Hut an/aus" },
        { command: "glove", Funktion: "Handschuhe an/aus" },
        { command: "collar", Funktion: "Kragen auf/zu" },
        { command: "eyewear", Funktion: "Brillen etc an/aus" },
        { command: "spats", Funktion: "Gamaschen an/aus" },
        { command: "spurs", Funktion: "Sporen an/aus" },
        { command: "belt", Funktion: "Gürtel an/aus" },
        { command: "buckle", Funktion: "Gürtelschnalle an/aus" },
        { command: "gauntlets", Funktion: "Stulpen an/aus" },
        { command: "skirt", Funktion: "Rock an/aus" },
        { command: "accessories", Funktion: "Accessoires an/aus" },
        { command: "poncho", Funktion: "Poncho an/aus" },
        { command: "tie", Funktion: "Krawatte/Fliege an/aus" },
        { command: "gunbelt", Funktion: "Waffengurt an/aus" },
        { command: "suspender", Funktion: "Hosenträger an/aus" },
        { command: "sleeves", Funktion: "Ärmel krempeln" },

        // Minigames data
        { command: "fff", Funktion: "An manchen Tischen ausführen um Five Finger Fillet zu spielen" },
        { command: "coinflip", Funktion: "Münzwurf" },
        { command: "dice (Nummer)", Funktion: "Würfel werfen" },

        // Anderes data
        { command: "me", Funktion: "Text dahinter um eine Nachricht mit Kontextinformationen über sich schweben zu lassen" },
        { command: "alertdoctor", Funktion: "Sendet einen Alert an alle Doktoren, welche online sind" },
        { command: "campunbuild", Funktion: "Aufgestellte Konstrukte abbauen (Zusammenpacken bevor man es einsammeln kann)" },
        { command: "camptake", Funktion: "Zusammengepackte Konstrukte einsammeln" },
        { command: "TWA", Funktion: "Waffen-Tricks" },
        { command: "TWB", Funktion: "Waffen-Tricks" },
        { command: "TWC", Funktion: "Waffen-Tricks" }
    ]
};



  useEffect(() => {
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        //console.log(data);
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);

  const filteredRoutes = filterRoutesByRole(routes, userRoles);
  
  return (
    <>
      <DefaultNavbar
        routes={filteredRoutes}
        action={{
          type: "external",
          route: "",
          label: "buy now",
          color: "dark",
        }}
        sticky
      />
      
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="info"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    FAQ
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Zuletzt aktualisiert am 01. August 2023
                  </MKTypography>
                </MKBox>
                <MKBox p={6}>
                  <MKTypography variant="h5" my={3}>
                    Allgemeines
                  </MKTypography>
                  <FaqCollapse
                    title="Was ist RedM?"
                    open={collapse === 1}
                    onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
                  >
                    RedM ist eine Modifikationsplattform für das Videospiel Red Dead Redemption 2 ähnlich wie FiveM für Grand Theft Auto V. Mit RedM können Spieler auf speziell modifizierten Servern spielen,
                    die eine Vielzahl von benutzerdefinierten Inhalten und Spielmodi bieten.
                    Das ermöglicht ein einzigartiges Rollenspielerlebnis, das über das hinausgeht, was in der standardmäßigen Einzelspieler- oder Mehrspielererfahrung von Red Dead Redemption 2 zu finden ist.
                    Mit RedM kann man beispielsweise auf unserem Western Hills RP-Server spielen und eine immersivere und personalisierte Western-Rollenspiel-Erfahrung genießen.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Was ist The Western Hills?"
                    open={collapse === 2}
                    onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
                  >
                    The Western Hills ist ein Online Rollebspielserver, auf den man sich mit der RedM Modifikationsplattform verbinden kann.
                    <br />
                    Uns ist Immersion wichtig. Mache Dich daher mit unserem Server vertraut. Auf unserer Webseite findest Du alle Informationen zu Technik, Lore, Server-Regelwerk, und allen anderen Dingen, die Du brauchst, um Dich in unser Spiel einfinden zu können. Ein guter Einstiegspunkt hierzu ist der Einsteiger-Guide. (Link) {/* VERLINKUNG  */}
                    <br />
                    Nimm Dir bitte Zeit, Dich einzulesen. Wenn Du bereit bist, dann melde Dich zu unserem Whitelist verfahren.
                    </FaqCollapse>
                  <FaqCollapse
                    title="Welche Anforderungen muss ich erfüllen, um auf dem Server spielen zu können?"
                    open={collapse === 3}
                    onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
                  >
                    Du hast...
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          ...eine aktuelle Spielversion von Red Dead Redemption 2 oder Red Dead Redemption Online für den PC installiert.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                        ...ein Mindestalter von 18 Jahren.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                        ...ein Mikrofon mit guter Audioqualität und wenig bis keinen Hintergrundgeräuschen.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                        ...unser {" "}
                        <MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="/allgemeines-regelwerk"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          allgemeines Regelwerk
                        </MKTypography> und die {" "} <MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="/serverregelwerk"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          Serverregeln
                        </MKTypography> Serverregeln gelesen und akzeptiert.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                        ...unseren {" "}
                        <MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="/roleplay-guide"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          Roleplay-Guide
                        </MKTypography>  gelesen und verstanden.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                        ...unser{" "}
                        <MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="https://wiki.western-hills.de"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          Server-Wiki
                        </MKTypography>  HowTo zur Einführung gelesen.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                        ...das Bewerbungsverfahren bestanden. Informationen dazu finden sich bei den Whitelist-Informationen.
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  </FaqCollapse>
                  <FaqCollapse
                    title="Wo finde ich Informationen zu Installation und Spielstart?"
                    open={collapse === 4}
                    onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
                  >
                    Alle Informationen zur Installation und zum Spielstart stehen in den dafür vorgesehnenen Guides. Zögere nicht, uns zu kontaktieren, wenn Du darüber hinaus Fragen hast.
                  </FaqCollapse>


                  <MKTypography variant="h5" mt={6} mb={3}>
                    Technisches
                  </MKTypography>
                  <FaqCollapse
                    title="SaltyChat zieht mich nicht in den Ingame-Voice Channel"
                    open={collapse === 10}
                    onClick={() => (collapse === 10 ? setCollapse(false) : setCollapse(10))}
                  >
                    Du benötigst die Salty-Chat Version 3.1.2 und eine kompatible TS3 Version. Die korrekte Version von Salty-Chat findest du hier: {" "}<MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="https://gaming.v10networks.com/SaltyChat"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          Salty-Chat
                        </MKTypography>
                  <br />
                  Solltest du diese Version bereits haben kann dir dieses Video zu den DNS Einstellungen helfen: {" "}<MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="https://www.youtube.com/watch?v=D67JYmvFFVA"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          DNS Einstellungen ändern
                        </MKTypography>
                  </FaqCollapse>
                  <FaqCollapse
                    title="Die Ingame-Menüs laggen"
                    open={collapse === 11}
                    onClick={() => (collapse === 11 ? setCollapse(false) : setCollapse(11))}
                  >
                    Das liegt meistens daran, dass deine Grafikkarte vollständig ausgelastet ist. Im RedM Menü, bevor du auf den Server verbindest, gibt es unter den Einstellungen &gt; Spiel &gt; &quot;NUI-Beschleunigung&quot; eine Option, die bei diesem Problem helfen kann. Ebenfalls kann man in den Grafikeinstellungen die Option &quot;Dreifachbuffering&quot;, &quot;MSAA&quot; und &quot;Reflexion MSSA&quot; ausschalten. Das kann auch bei manchen Spielern zu einer Verbesserung bei Lagg-Problemen mit den Ingame-Menüs führen. 
                  </FaqCollapse>
                  <FaqCollapse
                    title="Die Ingame-Menüs sehen komisch aus oder Elemente sind verschoben"
                    open={collapse === 12}
                    onClick={() => (collapse === 12 ? setCollapse(false) : setCollapse(12))}
                  >
                    RedM ist hauptsächlich für Full HD (1920x1080) Auflösung entwickelt. Teilweise werden von manchen Menüs auch Auflösungen wie 4K oder WQHD unterstützt. Bei allen anderen Auflösungen kann es zu Problemen kommen. Um dies zu beheben, kann man im RedM Menü unter Einstellung &gt; Spiel &gt; &quot;Fixed size NUI&quot; aktivieren. Damit wird den Ingame-Menüs vorgegaukelt, dass ein Full HD Monitor verwendet wird.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Welche Software benötige ich für das Spiel?"
                    open={collapse === 13}
                    onClick={() => (collapse === 13 ? setCollapse(false) : setCollapse(13))}
                  >
                  Neben einer offiziellen Kopie von &quot;Red Dead Redemption 2&quot; oder &quot;Red Dead Redemption Online&quot; benötigst du:
                  <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                        TeamSpeak (ts.western-hills.de)
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Saltychat Version 3.1.2
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          RedM
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Discord
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Steam
                        </MKTypography>
                      </MKBox>
                  </MKBox>

                
                  </FaqCollapse>
                  <FaqCollapse
                    title="Was muss ich in den Einstellungen meines Spiels beachten?"
                    open={collapse === 14}
                    onClick={() => (collapse === 14 ? setCollapse(false) : setCollapse(14))}
                  >
                    <MKBox component="ul" my={3} ml={6}>
                      
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                        RedM Einstellungen:
                        </MKTypography>
                          <MKBox component="ul" my={3} ml={6}>
                            <MKBox component="li">
                              <MKTypography variant="body2" color="text" fontWeight="regular">
                              auf Beta oder Unstable
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography variant="body2" color="text" fontWeight="regular">
                              Einstellungen &gt; Spiel &gt; Fix &quot;IU Lag&quot;... aktivieren
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography variant="body2" color="text" fontWeight="regular">
                              Danach den RedM Launcher unbedingt neustarten, damit die Änderungen wirksam werden
                              </MKTypography>
                            </MKBox>
                          </MKBox>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                        RDR2 Grafik Einstellungen:
                        </MKTypography>
                          <MKBox component="ul" my={3} ml={6}>
                            <MKBox component="li">
                              <MKTypography variant="body2" color="text" fontWeight="regular">
                              Drew Achpuffer deaktivieren
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography variant="body2" color="text" fontWeight="regular">
                              MSAA deaktivieren (optional)
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography variant="body2" color="text" fontWeight="regular">
                              Relexion MSAA deaktivieren (optional)
                              </MKTypography>
                            </MKBox>
                          </MKBox>
                      </MKBox>
                    </MKBox>

                    
                  </FaqCollapse>
                  <FaqCollapse
                    title="Wie lauten die wichtigsten Konsolenbefehle?"
                    open={collapse === 15}
                    onClick={() => (collapse === 15 ? setCollapse(false) : setCollapse(15))}
                  >
                  Zur Erinnerung: Die Konsole startest du über F8.
                  <Table columns={columns} rows={rows} />
                  </FaqCollapse>
                  <FaqCollapse
                    title="Welche technische Ausrüstung benötige ich, um auf dem Server zu streamen?"
                    open={collapse === 16}
                    onClick={() => (collapse === 16 ? setCollapse(false) : setCollapse(16))}
                  >
                    Leider sind wir hier kein technischer Support für Streaming. Beachte zum Streamen auf unserem Server einfach unsere Richtlinien für Streamer, welche im {" "}
                        <MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="/allgemeines-regelwerk"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          allgemeinen Regelwerk
                        </MKTypography> nachzulesen sind.       
                  </FaqCollapse>
          


                  <MKTypography variant="h5" my={3}>
                    Fehlermeldungen
                  </MKTypography>
                  <FaqCollapse
                    title="Ich werde ständig von Teamspeak informiert, wenn andere Spieler sich verbinden/die Verbindung trennen. Was kann ich tun?"
                    open={collapse === 20}
                    onClick={() => (collapse === 20 ? setCollapse(false) : setCollapse(20))}
                  >
                  Gehe in deine Einstellungen &gt; auf Benachrichtigungen &gt; auf Soundpacks &gt; auf  Sounds Deactivated
                  <br />
                  Anschließend verbinde dich erneut mit dem Server.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Ich höre irgendwelche Stimmen im Spiel. Was kann ich tun?"
                    open={collapse === 21}
                    onClick={() => (collapse === 21 ? setCollapse(false) : setCollapse(21))}
                  >
                  Wenn du Stimmen von zufälligen Spielern hörst, stelle sicher das du den Sprachchat in RDR2 deaktiviert hast, überprüfe dies in den Einstellungen &gt; Audio &gt; Sprach-Chat aktiviert: Aus
                  Andernfalls solltest du überprüfen, ob deine SaltyChat Version 3.1.2 ist. Ggf. musst du SaltyChat neu installieren.
                  </FaqCollapse>
                  <FaqCollapse
                    title="PoolSize Error"
                    open={collapse === 22}
                    onClick={() => (collapse === 22 ? setCollapse(false) : setCollapse(22))}
                  >
                    Wenn du den RedM PoolSize Error hattest, führe folgende Schritte aus:
                    Suche den Dateipfad von RedM (standardmäßig: C:\Users\NonAdmin\AppData\Local\RedM) &gt; öffne &quot;RedM Application Data&quot; &gt; öffne &quot;citizen&quot;, &gt; öffne &quot;common&quot; &gt; öffne &quot;data&quot; &gt; öffne&quot;gameconfig.xml&quot; mit einem Editor &gt; STRG+F 8100 suchen und mit 16200 ersetzen &gt; Speichern.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Durch die Kleidung meines Charakters scheint stellenweise die Haut"
                    open={collapse === 23}
                    onClick={() => (collapse === 23 ? setCollapse(false) : setCollapse(23))}
                  >
                    Buggende Kleidung ist ein weit verbreitetes Phänomen auf RedM Servern. Es hilft, die betroffenen Kleidungsstücke aus- und wieder anzuziehen. Achte dabei auf die richtige Reihenfolge, sollten mehrere Stücke betroffen sein.
                    Achte bei der Kleidung deines Charakters generell auf die Wahl der Kleidung. Es gibt Kleidungsstücke, die auf diesem Charaktermodell nicht funktionieren.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Die ALT-Taste funktioniert bei mir nicht, was kann ich tun?"
                    open={collapse === 25}
                    onClick={() => (collapse === 25 ? setCollapse(false) : setCollapse(25))}
                  >
                    Vermutlich hast du ein Windows-Betriebssystem. Dies ist ein Fehler in RDR2 unter Windows, der leider nicht behoben werden kann. Du kannst aber alternativ die ALT-GR Taste oder STRG+ALT verwenden.                 
                  </FaqCollapse>
                  
                  
                  <MKTypography variant="h5" my={3}>
                    Content Creator
                  </MKTypography>
                  <FaqCollapse
                    title="Wie bekomme ich eine Creator Rolle auf dem Discord?"
                    open={collapse === 30}
                    onClick={() => (collapse === 30 ? setCollapse(false) : setCollapse(30))}
                  >
                    Grundsätzlich solltest du auf einer Plattform streamen und das Interesse haben, deinen Content auf TWH dort zu teilen. Um eine Creator-Rolle zu erhalten, eröffne einfach ein Supportticket und teile uns dein Anliegen mit. Poste den Link deines Twitch-/Kick-/etc-Profils hinein. Nach der Verifizierung erhältst du die Rolle &quot;Creator&quot; auf dem Discord.                    
                  </FaqCollapse>




                  <MKTypography variant="h5" my={3}>
                    Whitelist
                  </MKTypography>
                  <FaqCollapse
                    title="Wann wird meine Whitelist bearbeitet?"
                    open={collapse === 40}
                    onClick={() => (collapse === 40 ? setCollapse(false) : setCollapse(40))}
                  >
                    Deine Whitelist wird von uns eingehend geprüft und im Anschluss erhältst du eine Mitteilung in dein Ticket. Wir bemühen uns, deine Whitelist innerhalb von ein paar Stunden zu bearbeiten. Bitte hab Verständnis dafür, wenn es mal etwas länger dauert. Nachfragen beschleunigen das Verfahren nicht.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Darf ich zusammen mit meinen Freunden einreisen?"
                    open={collapse === 41}
                    onClick={() => (collapse === 41 ? setCollapse(false) : setCollapse(41))}
                  >
                    Auf The Western Hills kannst du mit Personen, die du OOC kennst, oder mit denen du schon lange gemeinsam spielst, gemeinsam einreisen und mit dem Roleplay beginnen. Beachte bitte, dass dennoch jeder von euch ein Whitelist-Ticket eröffnen muss und eine eigenständige Charakterstory Voraussetzung ist. Innerhalb der jeweiligen Charakterstory könnt ihr erläutern, dass ihr gemeinsam einreisen wollt.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Ich habe meinen Char erstellt und er gefällt mir nicht"
                    open={collapse === 42}
                    onClick={() => (collapse === 42 ? setCollapse(false) : setCollapse(42))}
                  >
                    Wenn du deinen Charakter erstellt hast und dir irgendetwas direkt nach deiner Einreise doch nicht an ihm gefällt, dann eröffne bitte ein Supportticket. Dein Charakter muss durch das Team gelöscht werden und im Anschluss kannst du einen Neuen erstellen.
                  </FaqCollapse>
                </MKBox>

              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={{ xs: 0, lg: 3 }} pb={3}>
        <SimpleFooter />
      </MKBox>
    </>
  );
}

export default Faq;


