/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import CoworkingPage from "layouts/pages/landing-pages/coworking";
import Rental from "layouts/pages/landing-pages/rental";
import AboutUs from "layouts/pages/company/about-us";
import DonationPage from "layouts/pages/company/donations";
import AppliesPage from "layouts/pages/company/applies";
import Partner from "layouts/pages/partner";
import Dashboard from "layouts/dashboard/dashboard";
import Pricing from "layouts/pages/company/pricing";
import HelpCenter from "layouts/pages/support/help-center";
import ContactUs from "layouts/pages/support/contact-us";
import Faq from "layouts/pages/support/faq";
import Privacy from "layouts/pages/support/privacy";
import DesktopApp from "layouts/pages/apps/desktop-app";
import SingleArticle from "layouts/pages/blogs/single-article";
import Author from "layouts/pages/blogs/author";
import VirtualRealityPage from "layouts/pages/extra/virtual-reality";

import ArticlesPage from "layouts/pages/blogs/articles";


import IngameAdmin from "layouts/dashboard/ingame-admin";
import WhitelistsAdmin from "layouts/dashboard/whitelistsAdmin"


import Whitelist from "pages/Support/Whitelist";

import PreviewArticlePage from "layouts/pages/blogs/preview-article";

//Rules
import Serverrules from "layouts/pages/rules/serverrules";
import Commonrules from "layouts/pages/rules/commonrules";
import Roleplayguide from "layouts/pages/rules/roleplayguide";
import Newguide from "layouts/pages/rules/newguide";
import RoleguideCrime from "layouts/pages/rules/roleguidecrime";
import RoleGuideCivilist from "layouts/pages/rules/roleguidecivilist";
import RoleGuideNative from "layouts/pages/rules/roleguidenative";
import RoleGuideLaw from "layouts/pages/rules/roleguidelaw";
import RoleGuideMedic from "layouts/pages/rules/roleguidemedic";

import Legalcode from "layouts/pages/rules/legalcode";

import KeyboardLayoutPage from "layouts/pages/support/keyboardlayout";
import ModelsPeds from "layouts/pages/models/modelspeds";

// Account
import SignInBasicPage from "layouts/authentication/sign-in/basic";
import SignInCoverPage from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignInSimplePage from "layouts/authentication/sign-in/simple";
import SignUpCoverPage from "layouts/authentication/sign-up/cover";
import ResetPasswordPage from "layouts/authentication/reset-password/cover";

import WhitelistPage from "layouts/pages/support/whitelist";

// Sections
import PageHeaders from "layouts/sections/page-sections/page-headers";
import Features from "layouts/sections/page-sections/featuers";
import PricingSection from "layouts/sections/page-sections/pricing";
import FaqSection from "layouts/sections/page-sections/faq";
import BlogPosts from "layouts/sections/page-sections/blog-posts";
import Testimonials from "layouts/sections/page-sections/testimonials";
import Teams from "layouts/sections/page-sections/teams";
import Stats from "layouts/sections/page-sections/stats";
import Cta from "layouts/sections/page-sections/cta";
import Applications from "layouts/sections/page-sections/applications";
import LogoAreas from "layouts/sections/page-sections/logo-areas";
import Footers from "layouts/sections/page-sections/footers";
import GeneralCards from "layouts/sections/page-sections/general-cards";
import ContentSections from "layouts/sections/page-sections/content-sections";
import Navbars from "layouts/sections/navigation/navbars";
import NavTabs from "layouts/sections/navigation/nav-tabs";
import Pagination from "layouts/sections/navigation/pagination";
import Newsletters from "layouts/sections/input-areas/newsletters";
import ContactSections from "layouts/sections/input-areas/contact-sections";
import Inputs from "layouts/sections/input-areas/inputs";
import Forms from "layouts/sections/input-areas/forms";
import Alerts from "layouts/sections/attention-catchers/alerts";
import Notifications from "layouts/sections/attention-catchers/notifications";
import Modals from "layouts/sections/attention-catchers/modals";
import TooltipsPopovers from "layouts/sections/attention-catchers/tooltips-popovers";
import Avatars from "layouts/sections/elements/avatars";
import Badges from "layouts/sections/elements/badges";
import BreadcrumbsEl from "layouts/sections/elements/breadcrumbs";
import Buttons from "layouts/sections/elements/buttons";
import Dropdowns from "layouts/sections/elements/dropdowns";
import ProgressBars from "layouts/sections/elements/progress-bars";
import SocialButtons from "layouts/sections/elements/social-buttons";
import Tables from "layouts/sections/elements/tables";
import Toggles from "layouts/sections/elements/toggles";
import Typography from "layouts/sections/elements/typography";
import { ClerkProvider, useUser, SignedOut } from "@clerk/clerk-react";
import { layouts } from "chart.js";




const routes = [
  {
    name: "Informationen",
    icon: <Icon>description</Icon>,
    columns: 2,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Getting started",
        collapse: [
          {
            name: "Einsteiger Guide",
            route: "/einsteiger-guide",
            component: <Newguide />,
          },
          {
            name: "Roleplay Guide",
            route: "/roleplay-guide",
            component: <Roleplayguide />,
          },
          /* {
            name: "Rollen-Guides",
            route: "/roleplay-guide",
            component: <Roleplayguide />,
          }, */
        ],
      },
      {
        name: "Regelwerke",
        collapse: [
          {
            name: "Allgemeines Regelwerk",
            route: "/allgemeines-regelwerk",
            component: <Commonrules />,
          },
          {
            name: "Serverregelwerk",
            route: "/serverregelwerk",
            component: <Serverrules />,
          },
        ],
      },
      {
        name: "Rollen-Guides",
        collapse: [
          {
            name: "Rollen-Guide: Crime",
            route: "/roleguide-crime",
            component: <RoleguideCrime />,
          },
          {
            name: "Rollen-Guide: Zivilist",
            route: "/roleguide-civilist",
            component: <RoleGuideCivilist />,
          },
          {
            name: "Rollen-Guide: Native",
            route: "/roleguide-native",
            component: <RoleGuideNative />,
          },
          {
            name: "Rollen-Guide: Law",
            route: "/roleguide-law",
            component: <RoleGuideLaw />,
          },
          {
            name: "Rollen-Guide: Mediziener",
            route: "/roleguide-medic",
            component: <RoleGuideMedic />,
          },
          
        ],
      },
      
      
      {
        name: "Sonstiges",
        collapse: [
          {
            name: "Server-Wiki",
            href: "https://wiki.western-hills.de"
          },
        ],
      },
    ],
    shouldBeLoggedIn: false,
    
    
  },
  
  {
    name: "Über uns",
    icon: <Icon>groups</Icon>,
    dropdown: true,
    collapse: [
      {
        name: "Das Projekt",
        description: "Alles über das Projekt und das Team",
        route: "/about-us",
        component: <AboutUs />,
      },
      {
        name: "Partner",
        description: "Business-Partner und Content-Creator",
        route: "/partner",
        component: <Partner />,
      },
      {
        name: "Spenden und Ausgaben",
        description: "Eine Übersicht über alle Spenden und Ausgaben",
        route: "/spenden-ausgaben",
        component: <DonationPage />,
        allowedRoles: ["whitelisted"],
      },
      {
        name: "Ausschreibungen",
        description: "Eine Übersicht über alle Teampositionen auf welche du dich bewerben kannst",
        route: "/ausschreibungen",
        component: <AppliesPage />,
      },
    ],
  },
  {
    name: "Hilfe",
    icon: <Icon>help</Icon>,
    collapse: [
      {
        name: "FAQ",
        description: "Die meist gestellten Fragen auf einen Blick",
        route: "/faq",
        component: <Faq />,
      },
      {
        name: "Tastaturbelegung",
        description: "Unsere interaktive Tastaturbelegung",
        route: "/tastaturbelegung",
        component: <KeyboardLayoutPage />,
      }
    ],
  },
  {
    name: "News",
    icon: <Icon>article</Icon>,
    route: "/news",
    component: <ArticlesPage />,
  },
  {
    name: "Whitelist",
    icon: <Icon>article</Icon>,
    route: "/whitelist",
    component: <WhitelistPage />,
    shouldBeLoggedIn: true,
    excludedRoles: ["whitelisted"],
  },
  /* {
    name: "Ausschreibungen",
    icon: <Icon>article</Icon>,
    route: "/about-us",
  }, */
  {
    name: "Mitglieder-Bereich",
    icon: <Icon>group</Icon>,
    collapse: [
     /*  {
        name: "Gesetzbücher",
        description: "See all sections",
        dropdown: true,
        collapse: [
          {
            name: "Gesetzbuch",
            route: "/gesetze",
            component: <Dashboard />,
          },
        ],
      },
      {
        name: "input areas",
        description: "See all input areas",
        dropdown: true,
        collapse: [
          {
            name: "inputs",
            route: "/sections/input-areas/inputs",
            component: <Inputs />,
          },
          {
            name: "forms",
            route: "/sections/input-areas/forms",
            component: <Forms />,
          },
        ],
      }, */
      {
        name: "Gesetzbuch",
        description: "IC-Gesetzbuch",
        route: "/gesetze",
        component: <Legalcode />,
      },
    ],
    shouldBeLoggedIn: true,
    allowedRoles: ["whitelisted"],
  },
  {
    name: "Team-Bereich",
    icon: <Icon>groups</Icon>,
    collapse: [
      {
        name: "Dashboards",
        description: "Administration",
        dropdown: true,
        collapse: [
          {
            name: "Ingame-Admin",
            route: "/ingame-admin",
            component: <IngameAdmin />,
            allowedRoles: ["team"],
          },
          {
            name: "Preview Article",
            route: "/preview-article",
            component: <PreviewArticlePage />,
            allowedRoles: ["team"],
          },
          {
            name: "Whitelists",
            route: "/whitelists-admin",
            component: <WhitelistsAdmin />,
          },
        ],
        allowedRoles: ["team","whitelist-support"],
      },
      {
        name: "Models",
        description: "Hashvalues für Models",
        dropdown: true,
        collapse: [
          {
            name: "Peds",
            route: "/models-peds",
            component: <ModelsPeds />,
          },
        ],
        allowedRoles: ["team"],
      },

    ],
    shouldBeLoggedIn: true,
    allowedRoles: ["team","whitelist-support"],
  },
  
];

export function filterRoutesByRole(routes, userRoles) {
  return routes
    .filter(route => {
      // Check for excludedRoles and if any of them are in userRoles
      if (route.excludedRoles && route.excludedRoles.some(role => userRoles.includes(role))) {
        return false;
      }

      // Check for allowedRoles and if any of them are in userRoles
      if (route.allowedRoles) {
        return route.allowedRoles.some(role => userRoles.includes(role));
      }
      return true;
    })
    .map(route => {
      if (route.collapse) {
        return {
          ...route,
          collapse: filterRoutesByRole(route.collapse, userRoles),
        };
      }
      return route;
    });
}


export default routes;
