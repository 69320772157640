/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
import appleLogo from "assets/images/logos/gray-logos/logo-apple.svg";
import facebookLogo from "assets/images/logos/gray-logos/logo-facebook.svg";
import nasaLogo from "assets/images/logos/gray-logos/logo-nasa.svg";
import vodafoneLogo from "assets/images/logos/gray-logos/logo-vodafone.svg";
import digitalOceanLogo from "assets/images/logos/gray-logos/logo-digitalocean.svg";
import Tooltip from "@mui/material/Tooltip";
// Material Kit 2 React components
import megaLogo from "assets/images/mega-logo.png";
import kadLogo from "assets/images/kad-logo.png";
import spooniLogo from "assets/images/spooni-logo.png";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Bereits vertraut von über&nbsp;</MKTypography>
          <MKTypography variant="h2" color="info" textGradient mb={2}>
            1000+ Spielern 
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Viele Spieler und andere Projekte vertrauen uns bereits und haben sich für The Western Hills entschieden
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Bowie Dustman"
              date="Top-Games"
              review='Für "Neulinge" und "alte Hasen" sehr zu empfehlen. Angenehme Spieler und tolles Admin-Team.'
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="C. Marlboro"
              date="Top-Games"
              review="Erstklassiger Server! Das Team und die Liebe zum Detail sind beeindruckend! Kann jedem nur empfehlen, sich hier whitelisten zu lassen und die Immersion zu genießen!"
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Zinnoberwolf"
              date="Top-Games"
              review='Die Spieler binden Neulinge & Einzelne ins RP ein,die Leitung ist offen für Ideen & Kritik. "Go for it", hier steckt viel Liebe & Herzblut drin!'
              rating={5}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
        <Container>
        <Grid container item xs={6} mx="auto">
          
        </Grid>
      </Container>
      <Container>
        <Grid container item xs={6} mx="auto">
          <MKBox textAlign="center">
            <MKTypography variant="h3" mt={6} mb={3}>
              Unsere Partner
            </MKTypography>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={4} lg={2}>
                <Tooltip title="MEGA Development Services">
                  <MKBox
                    opacity={0.8}
                    component="a"
                    href="/partner"
                  >
                    <MKBox
                      component="img"
                      src= {megaLogo}
                      width="100%"
                    />
                  </MKBox>
                </Tooltip>
              </Grid>
              
              <Grid item xs={4} lg={2}>
                <Tooltip title="Kad Darem">
                  <MKBox
                    opacity={0.8}
                    component="a"
                    href="/partner"
                  >
                    <MKBox
                      component="img"
                      src={kadLogo}
                      width="100%"
                    />
                  </MKBox>
                </Tooltip>
              </Grid>
              <Grid item xs={4} lg={2}>
                <Tooltip title="Spooni-RDR2-Mapping">
                  <MKBox
                    opacity={0.8}
                    component="a"
                    href="/partner"
                  >
                    <MKBox
                      component="img"
                      src={spooniLogo}
                      width="100%"
                    />
                  </MKBox>
                </Tooltip>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
        {/* <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={appleLogo} alt="Apple" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={facebookLogo} alt="Facebook" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={nasaLogo} alt="Nasa" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={vodafoneLogo} alt="Vodafone" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={digitalOceanLogo}
              alt="DigitalOcean"
              width="100%"
              opacity={0.6}
            />
          </Grid>
        </Grid> */}
      </Container>
    </MKBox>
  );
}

export default Information;
