
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import { filterRoutesByRole } from 'routes';
import loading from "components/Loading";
import { useState, useEffect } from "react";
import { SignOutButton, SignInButton, ClerkProvider, useUser,useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

function Privacy() {
  const { userId, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);

  useEffect(() => {
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        //console.log(data);
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);

  const filteredRoutes = filterRoutesByRole(routes, userRoles);
  return (
    <>
      <DefaultNavbar
        routes={filteredRoutes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "dark",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Roleplayguide
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Zuletzt aktualisiert am 01.08.2023
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h4" mt={6} mb={3}>
                    Einführung
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  Im Folgenden möchten wir euch im Rahmen dieses &quot;RP-Guides&quot; nahe bringen, was wir uns als Serverteam unter RP, vor allem im Bezug auf das spezielle Setting der Spielwelt, vorstellen. 
                  Das heißt für euch, selbst als Veteran dürften unsere Ausführung für euch wichtig sein!<br /> 
                  Natürlich sind neben den speziellen RP Kenntnissen auch die Grundlegenden Kenntnisse nicht zu unterschätzen. Für alle Neulinge in der Welt des RP sei also folgendes gesagt. <br />
                  Grundlegende Kenntnisse im Roleplay sind erforderlich, um ein reibungsloses Spielgeschehen zu ermöglichen. In der Regel könnt ihr es euch wie in einem Theaterstück oder Film vorstellen,
                  in dem wir die Schauspieler sind. Wir spielen alle eine Rolle, die entgegen unserer wahren Persönlichkeit auch dementsprechend anders handeln könnte. Passt euch eurem Charakter an und versucht Entscheidungen aus seiner Perspektive zu treffen.
                  Das Motto hier lautet: Auch verlieren kann im Roleplay Spaß machen! <br />
                  Was heißt das konkret für euch? Kann man ohne viel Erfahrung im RP nicht auf dem Server spielen? Natürlich heißt es das nicht. Wichtig ist nur, 
                  dass ihr euch immer bewusst macht, was Roleplay bedeutet. 

                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Stay in Character
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Bleibe stets in der Rolle und lasse dich niemals dazu verleiten, aus dieser zu fallen. Alles was im Roleplay passiert bleibt auch dort! Hierbei ist Vorstellungskraft, Kreativität und Roleplayverständnis gefragt.
                    Dein Charakter kann ein gerissener Kopf sein oder ein vergessliches Gemüt. Egal wer mit dir interagiert, sei dir immer bewusst, dass hinter dem fremden Charakter auch eine Person steckt, die nur eine Rolle im Wilden Westen spielt!
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Wer bist du und was kannst du?
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Jeder Charakter hat seine eigene Geschichte. Wenn du dir deinen Charakter überlegst solltest du dir bewusst machen, was deinen Charakter aus macht:
                  </MKTypography>
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Wo kommt dein Charakter her?
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Wieso ist dein Charakter hier?
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Welche Ängste hat dein Charakter?
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Was kann dein Charakter sehr gut?
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Welche Ordnung in der Gesellschaft hat dein Charakter?  Ist er/sie wohlhabend?  Welche Kleidung ist für deinen Charakter angemessen?
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Muss dein Charakter vorsichtig sein, was er/sie über sich preis gibt? 
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Was hat dein Charakter alles erlebt?  (Lies dich gerne historisch ein) 
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Hat dein Charakter Bildung genossen? Inwiefern? 
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  <MKTypography variant="body2" color="text">
                    Wie du siehst gibt es gerade im historischen Roleplay einige Punkte, die du berücksichtigen musst, um einen authentischen Charakter zu spielen. Versuche eine altmodische Sichtweise zu entwickeln und dich in diese Zeit zu denken. (Infos und Tipps findest du in diesem Guide!)
                  </MKTypography>
                  <MKTypography variant="h4" mt={6} mb={3}>
                    Der Wilde Westen, das Jahr 1899 & Spielwelt
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                      Großer Bestandteil eures Charakterbuildings und eures Roleplays, sollte die Auseinandersetzung mit dem historischen Kontext sowie der Spielwelt des Servers sein. Das heißt natürlich nicht,
                      dass ihr ein halbes Geschichtsstudium absolvieren sollt und alles wissen müsst. Je mehr ihr jedoch wisst, umso mehr könnt ihr dies in euer RP einbetten und für alle Spieler ein noch viel realistischeres Spielgefühl erschaffen.<br /> 
                      Was wären also Dinge von denen wir erwarten, dass Ihr euch damit zumindest etwas auseinandersetzt? Grob lässt es sich auf die folgenden Punkte zusammenfassen.
                    <br /> 
                    <br /> 
                    <MKTypography variant="h6" mt={6} mb={3}>
                      Der Wilde Westen, das Jahr 1899 & Spielwelt
                    </MKTypography>
                      Gibt es das Land zu dieser Zeit überhaupt schon?  Oder hieß es ggf. anders?  Beispiel wäre hier z.B.  Österreich. Zum Zeitpunkt des Spiels lautet der korrekte Name des Landes Österreich-Ungarn.
                      Oberhaupt war ein Kaiser der beide Monarchien in Personalunion führte. Gerade die europäische Landkarte sah zur damaligen Zeit doch etwas anders aus.
                      Lest euch hier einfach kurz ein. Aber auch die USA sahen noch nicht so aus wie wir sie heute kennen. Die Bundesstaaten Arizona, New Mexico sowie Oklahoma waren z.B. erst nach 1900 der USA beigetreten.
                      Noch viel später kam Hawaii und Alaska hinzu.
                    <br /> 
                    <br /> 
                    <MKTypography variant="h6" mt={6} mb={3}>
                      Der Wilde Westen und seine Nachwirkung
                    </MKTypography>
                      So viel sei vorab gesagt: Der Wilde Westen, wie viele Ihnen aus Filmen und Co. kennen, ist im Jahr 1899 bereits vorbei.
                      Vielmehr wirkt dieser im County nach. Viele Zeitgenossen haben  diesen noch erlebt , von ihm profitiert , oder durch ihn gelitten.<br />
                      Spielern des Storymodus von Red Dead Redemption 2 dürfte recht eindeutig aufgezeigt worden sein, wie die fortschreitende Industrialisierung, Besiedlung sowie Versuche der Regierung in Washington den &quot;Wilden Westen&quot; zu zähmen,
                      eben diesen nach und nach gezähmt haben.<br />
                      Im Jahr 1899 befindet sich somit das County in genau dieser Zeit des Umbruchs. Ein stetiger Konflikt zwischen alten Normen und Machtanspruch des Staates der nach und nach in das Leben jedes Bürgers eingreift.
                      Während im Wilden Westen Probleme gerne mit der Waffe oder zumindest selbst gelöst werden, beansprucht der Staat dieses Machtmonopol nun für sich.<br />
                      Neben dem Staat greifen auch immer mehr private Investoren nach dem Rohstoffreichen Ländereien des Westens. In Saint Denis befinden sich riesige Fabriken, in den Heartlands wird nach Öl gebohrt und dieses verarbeitet.
                      Auch der Einfluss eben dieser reichen Kaufleute wächst nach und nach an.  Vor allem im östlichen Teil der Karte werdet ihr feststellen,  dass dieser Teil der Karte um einiges moderner aufgestellt ist, als der Westen.
                    <br />
                    <br /> 
                    <MKTypography variant="h6" mt={6} mb={3}>
                      Umgang mit Indianern
                    </MKTypography>
                      Kleiner Disclaimer vorweg!  Auch wenn zu dieser Zeit anderweitig mit Minderheiten & Frauen umgegangen wurde, heißt dies nicht das ihr das auch tun sollt!
                      Es geht hier nur um einen Überblick vor allem auf Bezug auf die Indianer, da diese im Spielgeschehen eine Gegenpartei zum normalen Siedler darstellen können. <br /><br />
                      Genauso wie alle anderen Menschen, die nicht weiß waren, waren auch die Ureinwohner massiven Rassismus und Genozid ausgesetzt. Bereits zu Beginn der Besiedlung des amerikanischen Kontinents wurden diese vertrieben, getötet und versklavt.<br /><br />
                      Nicht unerwähnt lassen sollte man hier jedoch, dass es sehr wohl auch Indianerstämme gab, die Seite an Seite mit den Siedlern gekämpft haben. Dies passierte zum Beispiel im 7 Jährigen Krieg, dem Unabhängigkeitskrieg als auch im Bürgerkrieg.
                      Alle beteiligten Nationen, von Frankreich, Großbritannien über Spanien bis zu den Nord- UND Südstaaten im Bürgerkrieg griffen auf indigene Stämme zurück, um Ihre Kampfkraft zu stärken.
                      Dies führte dazu, dass es auch Indianerstämme gab, die um einiges westlicher lebten als man vermuten würde. In einigen Stämmen war es z.B. üblich die Kleidung der Siedler zu tragen.
                      Andere Stämme hingegen sonderten sich aktiv von den Siedlern ab und verhielten sich feindselig.
                      Dies führte vor allem in der Zeit des &quot;Wilden Westens&quot; dazu, dass die Indianerstämme militärisch zurückgedrängt, ihre Siedlungen vernichtet und sie teilweise in Reservate gezwungen wurden.<br /><br />
                      Im County werdet ihr einige Spuren dieser Auseinandersetzungen finden können. Beispielsweise gibt es ein komplett niedergebranntes indigenes Dorf, welches die Zerstörungswut der weißen Siedler gegenüber den Indianern aufzeigt.<br /><br />
                      &quot;Rothäute&quot; wie die Indianer von den weißen Siedlern genannt wurden waren jedoch ähnlich wie die Afroamerikaner nicht ansatzweise gleichgestellt geschweige denn toleriert. <br /><br />
                      Zu Beginn des Servers wurden die Indianer als eine Art Gegenpol zu den Siedlern bespielt. Ein gemeinsames handeln zwischen Siedlern und Natives war daher nicht erwünscht und stellte ohne triftige RP-Gründe schnell Fail-RP dar.
                      Durch ein Event besteht seit kurzem ein brüchiger Friedensvertrag zwischen Siedlern und Indianern um auch Native Spielern den Zugang zu Städten zu ermöglichen.
                      Dieser Vertrag hindert die Spieler jedoch nicht daran den o.g. Gegenpol weiter anzuspielen und Indianern negativ gegenüber eingestellt zu sein.
                      Durch dieses Event ist die o.g. Gefahr eines Regelverstoßes bei Zusammenarbeit jedoch rein Regelwerkstechnisch ausgehebelt.<br /><br /> Es liegt also in eurer Hand als Spieler, welche Art des Umgangs Ihr mit den Indianern pflegt.
                      Was weiterhin jedoch nicht gewünscht ist, sind Aussagen wie &quot;es ist das Land der Indianer, Sie waren vor uns hier etc.&quot;. Dies ist ein Gedanke den 1899 wohl nur die wenigsten Siedler hatten. 
                      <br /> 
                    <br /> 
                    <MKTypography variant="h6" mt={6} mb={3}>
                      Religion & Gesellschaftliche Normen
                    </MKTypography>
                      Im Jahr 1899  wurde großen Wert auf das Ansehen innerhalb der Gemeinschaft gelegt.
                      Man tat gut daran sich mit Nachbarn und anderen Bewohnern der selben Stadt gutzustellen. Galoppieren in den Städte oder gar eine gezogene Waffe konnten einen sehr schnell ins Abseits innerhalb der Stadtgemeinschaft bringen.
                      Ein weiterer großer Bestandteil des gesellschaftlichen Lebens war der  christliche Glaube und die wöchentliche Messe zu der sich meist die ganze Stadt zusammenfand.
                      Die Abwesenheit einer Person wurde meistens direkt bemerkt und wurde schnell Stadtgespräch.
                      Zu dieser Zeit war es extrem unüblich nicht religiös zu sein. Es sollte also auch von eurer Seite nicht ständig auf den eigenen Atheismus hingewiesen werden.
                      Falls ihr das tut, kann es  euch durchaus passieren, dass Ihr aus der Gemeinschaft ausgegrenzt werdet. <br /><br />
                      Neben der Kirche bildete der Saloon  einen weiteren Dreh - und Angelpunkt jeder Stadt. Hier wurde sich regelmäßig getroffen  um zu trinken,  Karten  zu spielen oder dem Klavier zu lauschen.
                      Behandelt also diese  Orte mit Respekt und nutzt diese als Treffpunkte aus. Die Barkeeper der Saloons waren oft auch sehr gut informiert und hatten Infos zu allen möglichen Gerüchten.
                      Durch den Alkoholkonsum kam es in den Saloons auch des Öfteren zu Prügeleien. Diese wurden jedoch selten mit der Waffe beendet. Spätestens, wenn der Sheriff den Saloon betrat, war jedem bewusst, dass der Spaß nun sein Ende hat. <br /><br />
                      Vor allem in kleineren Städten war der Sheriff die oberste Instanz in Sachen Rechtsprechung und Verfolgung.
                      Sein Wort galt. Das heißt, dass auch Bewohner der Stadt ihm meistens blind vertrauten und diesen unter Umständen sogar schützten, wenn eine Bande Outlaws die Stadt unsicher machen wollte.<br /><br />
                      Auch Bewohner außerhalb von Städten waren diesen Normen unterworfen. Bauern und Landwirte, die Ihre Waren loswerden wollten mussten stets höflich und freundlich bleiben, damit sie von den Stadtbewohnern als vertrauensvoll wahrgenommen wurden.
                      Respekt war somit mit das wichtigste, was man anderen zeigen musste. Dies galt vor allem gegenüber Fremden. 
                      <br />
                      <br />
                      <b>Die genannten Punkte und deren Inhalte bilden nur einen groben Einstieg in die Thematiken. Wie vorab erwähnt. Je mehr Ihr wisst, umso mehr könnt ihr in euer RP einbauen. </b>
                    </MKTypography> 
                            
                             
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
