// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import LiveTvIcon from '@mui/icons-material/LiveTv';

// Material Kit 2 PRO React examples
import TransparentTeamCard from "examples/Cards/TeamCards/TransparentTeamCard";

import { Link } from "react-router-dom";

// Images
import team1 from "assets/images/bruce-mars.jpg";
import team2 from "assets/images/team-3.jpg";
import team3 from "assets/images/team-4.jpg";
import annuschka from "assets/images/annuschka.jpg";
import stephano from "assets/images/stephano.jpg"

function Team1() {
  const socialIcons = (
    <>
      <Tooltip placement="top" title="Twitch">
      <a href="https://www.twitch.tv/vikingstephano" target="_blank" rel="noopener noreferrer">
        <MKBox
          component="i"
          fontSize="1.125rem"
          className="fab fa-twitch"
          sx={{
            color: "#6441a5",
            cursor: "pointer",
          }}
        />
      </a>
      </Tooltip>
      <Tooltip placement="top" title="TikTok">
      <a href="https://www.tiktok.com/@vikingstephano" target="_blank" rel="noopener noreferrer">
        <MKBox
          component="i"
          fontSize="1.125rem"
          className="fab fa-tiktok"
          sx={{
            color: ({ palette: { socialMediaColors } }) => socialMediaColors.youtube.main,
            cursor: "pointer",
          }}
        />
      </a>
      </Tooltip>
      <Tooltip placement="top" title="YouTube">
      <a href="https://www.youtube.com/@vikingstephano" target="_blank" rel="noopener noreferrer">
        <MKBox
          component="i"
          fontSize="1.125rem"
          className="fab fa-youtube"
          sx={{
            color: ({ palette: { socialMediaColors } }) => socialMediaColors.youtube.main,
            cursor: "pointer",
          }}
        />
      </a>
      </Tooltip>
      <Tooltip placement="top" title="Instagram">
      <a href="https://www.instagram.com/vikingstephano" target="_blank" rel="noopener noreferrer">
        <MKBox
          component="i"
          fontSize="1.125rem"
          className="fab fa-instagram"
          sx={{
            color: ({ palette: { socialMediaColors } }) => socialMediaColors.instagram.main,
            cursor: "pointer",
          }}
        />
      </a>
      </Tooltip>
    </>
  );

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid container item flexDirection="column" xs={12} lg={7} mb={6}>
          <MKBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="3rem"
            height="3rem"
            variant="gradient"
            bgColor="info"
            color="white"
            shadow="md"
            borderRadius="lg"
            mb={2}
          >
            <LiveTvIcon />
          </MKBox>
          <MKTypography variant="h3" mb={1}>
            Partnered Content-Creator
          </MKTypography>
          <MKTypography variant="body2" color="text">
            Erfahre mehr über die offiziellen Aushängeschilder von The Western Hills
          </MKTypography>
        </Grid>
        <Grid container spacing={3} mt={6}>
          <Grid item xs={12} md={6} lg={4}>
            <TransparentTeamCard
              image={stephano}
              name="Vikingstephano"
              position="Streamer"
              description=""
              socials={socialIcons}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team1;