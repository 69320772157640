/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
// rellax
import Rellax from "rellax";


import JsxParser from 'react-jsx-parser';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/Blogs/SingleArticle/sections/Information";
import Steps from "pages/Blogs/SingleArticle/sections/Steps";
import OurEfforts from "pages/Blogs/SingleArticle/sections/OurEfforts";
import Features from "pages/Blogs/SingleArticle/sections/Features";
import Posts from "pages/Blogs/SingleArticle/sections/Posts";
import Support from "pages/Blogs/SingleArticle/sections/Support";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";



import { useState, useEffect, useRef, React } from "react";
import { useParams } from 'react-router-dom';
import { filterRoutesByRole } from 'routes';
import { SignOutButton, SignInButton, ClerkProvider, useUser, useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";

// Images
import bgImage from "assets/images/webpage-twh.webp";

import { useLocation } from 'react-router-dom';

function formatTimestampToDDMMYYYY(timestamp) {
  const date = new Date(timestamp);

  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  const year = date.getUTCFullYear();

  return `${day}.${month}.${year}`;
}


const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
function SingleArticle() {
  const headerRef = useRef(null);

  const { id } = useParams();

  const [article, setArticle] = useState(null);
  const [collapse, setCollapse] = useState(false);
  const { userId, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);


  useEffect(() => {
    if(!userId || userId === "undefined") return;
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);

    
    
  
 
  
  const filteredRoutes = filterRoutesByRole(routes, userRoles);




  useEffect(() => {
    fetch(`${API_URL}/webpageDB/articles/${id}`, {
      headers: {
        'x-api-key': API_KEY
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then(data => {
      if (data) {
        setArticle(JSON.parse(data));
      } else {
        console.warn('Empty response from server');
      }
    })
    .catch(error => {
      console.error('Failed to fetch article:', error);
    });
    
  }, [id]);


  // Handle loading and error states as necessary
  if (!article) return <div>Loading...</div>;

  function RenderedComponent(props) {
    const yourString = props;  // Your JSX string

    return (
        <JsxParser
            components={{ MKBox, Container, Grid, MKTypography, Icon }} // Components used in your string
            jsx={yourString}
        />
    );
}

  return (
    <>
      <MKBox bgColor="white" py={0.25}>
        <DefaultNavbar
          routes={filteredRoutes}
          action={{
            type: "external",
            route: "",
            label: "buy now",
            color: "dark",
          }}
          transparent
          relative
        />
      </MKBox>
      
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${article.thumbnail_url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            sx={{ mx: "auto", textAlign: "center" }}
          ><Container>
            <MKTypography
              variant="h2"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {article.title}       
            </MKTypography>
            </Container>
            <Container>
            <MKTypography variant="body1" color="white" mt={1}>
              {formatTimestampToDDMMYYYY(article.created_at)}
            </MKTypography>
            </Container>
          </Grid>
      </MKBox>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {RenderedComponent(article.content)}
        {/* <Information />
        <Steps />
        <OurEfforts />
        <Features />
        <Posts />
        <Support /> */}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default SingleArticle;
