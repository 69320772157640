/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

import React, { useState, useRef, useEffect } from 'react';

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

const json = [
  {
    "id": "5",
    "image": "path_to_team2_image",
    "name": "John Michael",
    "email": "john@creative-tim.com",
    "jobTitle": "Manager",
    "jobDescription": "Organization",
    "status": "pending",
    "whitelistDate": "23/04/18"
  },
 // and so on for other users
];


export default function data() {
  const [data, setData] = useState(json);

  // useEffect(() => {
  //   // Fetch data from your JSON file
  //   fetch('/path_to_your/data.json')
  //     .then((res) => res.json())
  //     .then((json) => setData(json));
  // }, []);

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "author", accessor: "author", width: "45%", align: "left" },
      { Header: "function", accessor: "function", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "employed", accessor: "employed", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: data.map(item => ({
      author: <Author image={item.image} name={item.name} email={item.email} />,
      function: <Job title={item.jobTitle} description={item.jobDescription} />,
      status: (
        <MDBox ml={-1}>
          <MDBadge badgeContent={item.status} color={item.status === 'online' ? 'success' : 'dark'} variant="gradient" size="sm" />
        </MDBox>
      ),
      employed: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item.whitelistDate}
        </MDTypography>
      ),
      action: (
        <MDTypography component="a" href={"/whitelistView/"+item.id} variant="caption" color="text" fontWeight="medium">
          Edit
        </MDTypography>
      ),
    }))
  };
}
