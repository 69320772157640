/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// HelpCenter page components
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6} my={6}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Offene Positionen
            </MKTypography>
            <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
                Alle offenen Positionen, auf die du dich bewerben kannst.
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={10}>
            <FaqCollapse
              title="REDM DEVELOPER / ENTWICKLUNG (M/W/D) "
              open={collapse === 1}
              onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
            >
              <MKTypography variant="h6" mt={1} mb={1}>
                Dein Profil:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Kenntnisse in der Programmierung mit lua. Vorzugsweise ebenfalls js, html und css
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Wünschenswert sind Kenntnisse im allgemeinen Umgang mit RedM, alternativ auch FiveM
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Wünschenswert sind Kenntnisse im Umgang mit dem RedM Framework „Vorp“ und allen dazugehörigen Systemen
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Hohe Eigeninitiative, Teamfähigkeit, strukturiertes Denken und sehr gute Kommunikationsfähigkeit.
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Passion für das große Ganze
                  </MKTypography>
                </MKBox>
              </MKBox>
              <MKTypography variant="h6" mt={1} mb={1}>
              Deine Verantwortungen:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Unterstützung bei der Weiterentwicklung und Pflege der Server-Ressourcen
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Einführung und Entwicklung neuer Scripte auf The Western Hills
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Spätere Entwicklung eines eigenen Frameworks in Zusammenarbeit mit einem externen Unternehmen
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Aktive Begleitung von DEV-Projekten und Bearbeitung von Projektaufgaben
                  </MKTypography>
                </MKBox>
              </MKBox>
            </FaqCollapse>
            <FaqCollapse
              title="UI-/WEB-DESIGNER (M/W/D) "
              open={collapse === 2}
              onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
            >
              <MKTypography variant="h6" mt={1} mb={1}>
                Dein Profil:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Kenntnisse in den gängigen Grafikbearbeitungsprogrammen(vorzugsweise Photoshop)
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Wünschenswert sind Kenntnisse  in der Programmierung mit html und css
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Wünschenswert sind Kenntnisse im Umgang mit dem RedM Framework „Vorp“ und allen dazugehörigen Systemen
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Hohe Eigeninitiative, Teamfähigkeit, strukturiertes Denken und sehr gute Kommunikationsfähigkeit
                  </MKTypography>
                </MKBox>
              </MKBox>
              <MKTypography variant="h6" mt={1} mb={1}>
              Deine Verantwortungen:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Unterstützung bei der Weiterentwicklung und Pflege der Grafik-Ressourcen
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Entwicklung von Item-Icons
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Entwicklung von UI- und Web-Designs
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Aktive Begleitung von DEV-Projekten und Bearbeitung von Projektaufgaben
                  </MKTypography>
                </MKBox>
              </MKBox>
            </FaqCollapse>
            <FaqCollapse
              title="ASSISTENZ DER GESCHÄFTSLEITUNG (M/W/D)"
              open={collapse === 3}
              onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
            >
              <MKTypography variant="h6" mt={1} mb={1}>
                Dein Profil:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Verantwortungsbewusstsein und ein hohes Maß an Eigeninitiative
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Professioneller Umgang in Bezug auf personenbezogene Daten
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Gutes Zeit- und Organisationsmanagement
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Hohe Lernbereitschaft
                  </MKTypography>
                </MKBox>
              </MKBox>
              <MKTypography variant="h6" mt={1} mb={1}>
              Deine Verantwortungen:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Planung und Organisation von Terminen und Fristen
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Planung und Organisation von Aufgaben
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Kalenderpflege
                  </MKTypography>
                </MKBox>
              </MKBox>
            </FaqCollapse>
            {/* <FaqCollapse
              title="CHIEF OPERATING OFFICER | TEAMLEITUNG (M/W/D)"
              open={collapse === 4}
              onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
            >
              <MKTypography variant="h6" mt={1} mb={1}>
                Dein Profil:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Verantwortungsbewusstsein und ein hohes Maß an Eigeninitiative
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Erfahrung im professionellen Umgang mit Mitarbeitern und Teammitgliedern
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Erfahrung in leitender Position
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Hohe Lernbereitschaft
                  </MKTypography>
                </MKBox>
              </MKBox>
              <MKTypography variant="h6" mt={1} mb={1}>
              Deine Verantwortungen:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Ausführung und Umsetzung der Unternehmensstrategien
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Umsetzung von organisatorischen Veränderungen oder neuer Initiativen
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Beaufsichtigung der Mitarbeiter/ Teammitglieder und Förderung ihrer Entwicklung
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Agierend als Mentor für junge Leitungspositionen
                  </MKTypography>
                </MKBox>
              </MKBox>
            </FaqCollapse> */}
            <FaqCollapse
              title="HEAD OF CREATIVE MEDIA | EDITOR (M/W/D)"
              open={collapse === 5}
              onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
            >
              <MKTypography variant="h6" mt={1} mb={1}>
                Dein Profil:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Du kannst gut mit Schnittprogrammen wie Adobe Premiere o.ä. umgehen
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Graphische Erfahrungen mit Photoshop, Illustrator sind von Vorteil aber kein muss
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Moderner Schnittstil und ausgeprägtes Auge für minimalistisches und modernes Design
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Kreativität und neue Ideen gehören zu deinen Stärken
                  </MKTypography>
                </MKBox>
              </MKBox>
              <MKTypography variant="h6" mt={1} mb={1}>
              Deine Verantwortungen:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Aufnehmen und Editieren von Ingame-Clips
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Planung und Umsetzung von Gametrailern und/oder Kurzfilmen
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Unterstützung in der graphischen Darstellung von Aktionen/Videos auf der Website & Social Media
                  </MKTypography>
                </MKBox>
              </MKBox>
            </FaqCollapse>
            <FaqCollapse
              title="CONTENT REDAKTEUR | SOCIAL MEDIA (M/W/D)"
              open={collapse === 6}
              onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
            >
              <MKTypography variant="h6" mt={1} mb={1}>
                Dein Profil:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Recherche von unterschiedlichen Themengebieten 
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Du bist immer auf dem neuesten Stand was im Bereich Roleplay passiert und bist offen für neue Themengebiete
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Grafische Erfahrungen mit Photoshop, Illustrator sind von Vorteil aber kein muss
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Kreativität und neue Ideen gehören zu deinen Stärken
                  </MKTypography>
                </MKBox>
              </MKBox>
              <MKTypography variant="h6" mt={1} mb={1}>
              Deine Verantwortungen:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Recherche von angesagten Themen im Roleplaybereich
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Vorbereitung von Kampagnen 
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Unterstützung in der graphischen Darstellung von Aktionen auf der Website & Social Media
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Managen der Social-Media Kanäle
                  </MKTypography>
                </MKBox>
              </MKBox>
            </FaqCollapse>
            <FaqCollapse
              title="3D-MODELLING (M/W/D)"
              open={collapse === 7}
              onClick={() => (collapse === 7 ? setCollapse(false) : setCollapse(7))}
            >
              <MKTypography variant="h6" mt={1} mb={1}>
                Dein Profil:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Erfahrung in Arbeiten mit Blender 
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Du hast ein Auge fürs Detail
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Grafische Erfahrungen mit Photoshop, Illustrator sind von Vorteil aber kein muss
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Erfahrungen im Mapping von Vorteil aber kein Muss
                  </MKTypography>
                </MKBox>
              </MKBox>
              <MKTypography variant="h6" mt={1} mb={1}>
              Deine Verantwortungen:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Umsetzen neuer Objekte und Gebäuden im Spiel in Blender
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Einbringen neuer Ideen für Objekte und Spielinhalte
                  </MKTypography>
                </MKBox>
              </MKBox>
            </FaqCollapse>
            <FaqCollapse
              title="EVENT-TEAM (M/W/D)"
              open={collapse === 8}
              onClick={() => (collapse === 8 ? setCollapse(false) : setCollapse(8))}
            >
              <MKTypography variant="h6" mt={1} mb={1}>
                Dein Profil:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Kreatives Denken und Umsetzung
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Du hast ein Auge fürs Detail
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Grafische Erfahrungen mit Photoshop, Illustrator sind von Vorteil aber kein Muss 
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Hohe Eigeninitiative, Teamfähigkeit, strukturiertes Denken und sehr gute Kommunikationsfähigkeit
                  </MKTypography>
                </MKBox>
              </MKBox>
              <MKTypography variant="h6" mt={1} mb={1}>
              Deine Verantwortungen:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Planung und Entwicklung von Ingame- und Server-Events
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Regelmäßige Umsetzung von kleineren und größeren Lore-Events
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Pflege des Server-Wiki
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Eventuell Begleitung und/oder Entwicklung dazugehöriger grafischer Ressourcen
                  </MKTypography>
                </MKBox>
              </MKBox>
            </FaqCollapse>
            <FaqCollapse
              title="ERWEITERTER SUPPORT (M/W/D) "
              open={collapse === 9}
              onClick={() => (collapse === 9 ? setCollapse(false) : setCollapse(9))}
            >
              <MKTypography variant="h6" mt={1} mb={1}>
                Dein Profil:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Hohen Anspruch an Professionalität
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Erfahrung in Leitungspositionen und/oder massives Verständnis in zwischenmenschlicher Kommunikation
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Technische Affinität ist von Vorteil aber kein Muss
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Hohe Eigeninitiative, Teamfähigkeit, strukturiertes Denken und sehr gute Kommunikationsfähigkeit
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Hohes Maß an Roleplay-Erfahrung
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Strukturiertes/Organisiertes Denken und Arbeiten
                  </MKTypography>
                </MKBox>
              </MKBox>
              <MKTypography variant="h6" mt={1} mb={1}>
              Deine Verantwortungen:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Planung und Entwicklung von Formularen und Strukturen
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Schlichtgespräche und Spielerkommunikation
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Abhandlung von Supportfällen, Tickets und Whitelist-Anfragen
                  </MKTypography>
                </MKBox>
              </MKBox>
            </FaqCollapse>
            <FaqCollapse
              title="SUPPORT (M/W/D) "
              open={collapse === 10}
              onClick={() => (collapse === 10 ? setCollapse(false) : setCollapse(10))}
            >
              <MKTypography variant="h6" mt={1} mb={1}>
                Dein Profil:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Hohen Anspruch an Professionalität
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Technische Affinität ist von Vorteil aber kein Muss
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Hohe Eigeninitiative, Teamfähigkeit, strukturiertes Denken und sehr gute Kommunikationsfähigkeit
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Hohes Maß an Roleplay-Erfahrung
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Strukturiertes/Organisiertes Denken und Arbeiten
                  </MKTypography>
                </MKBox>
              </MKBox>
              <MKTypography variant="h6" mt={1} mb={1}>
              Deine Verantwortungen:
              </MKTypography>
              <MKBox component="ul" my={1} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Planung und Entwicklung von Formularen und Strukturen
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  Abhandlung von Supportfällen, Tickets und Whitelist-Anfragen
                  </MKTypography>
                </MKBox>
              </MKBox>
            </FaqCollapse>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
