// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "The Western Hills",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/TheWesternHills/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/TheWesternHills",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/@western-hills",
    },
    
  ],
  menus: [
    {
      name: "Unternehmen",
      items: [
        { name: "Über uns", href: "/about-us" },
        { name: "News", href: "/news" },
      ],
    },
    {
      name: "Ressourcen",
      items: [
        { name: "Illustrationen", href: "/" },
        { name: "Vorlagen", href: "/" },
        { name: "Affiliate Programm ", href: "/" },
      ],
    },
    {
      name: "Hilfe & Support",
      items: [
        { name: "Kontakt", href: "/contact-us" },
        { name: "FAQ", href: "/faq" },
      ],
    },
    {
      name: "legal",
      items: [
        //{ name: "terms & conditions", href: "https://www.creative-tim.com/terms" },
        { name: "impressum", href: "/impressum" },
        { name: "privacy policy", href: "/privacy" },
        // { name: "licenses (EULA)", href: "https://www.creative-tim.com/license" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} {" "}
      <MKTypography
        component="a"
        href="/impressum"
        variant="button"
        fontWeight="regular"
      >
        Dietrich Development & Services
      </MKTypography>
      .
    </MKTypography>
  ),
};
