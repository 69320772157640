
import { Triangle } from  'react-loader-spinner'
import MKBox from "components/MKBox";

const Loading = (
    <>
      <MKBox
        sx={{
          textAlign: 'center',
          display: "grid",
          placeItems: "center",
          position: 'fixed', // Fixed position
          top: 0, right: 0, bottom: 0, left: 0, // Give it full screen size
          zIndex: 9999, // Make sure it's on top
        }}>
        <Triangle
          color="#1a4ee8"
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </MKBox>
    </>
)


export default Loading;