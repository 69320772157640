/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/team-5.jpg";
import team2 from "assets/images/bruce-mars.jpg";
import team3 from "assets/images/ivana-squares.jpg";
import team4 from "assets/images/ivana-square.jpg";
import teamDietrich from "assets/images/team/dietrich.png";
import teamRoschy from "assets/images/team/roschy.png";
import teamPlaceholder from "assets/images/team/placeholder.png";
import teamOgen from "assets/images/team/ogen.png";
import teamMarlboro from "assets/images/team/marlboro.png";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Das Team
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
            Durch umfassende Kompetenz in den verschiedensten Bereichen wie Unternehmens-Management, Softwareentwicklung und Grafikdesign, versuchen wir als Team, euch das bestmögliche Spielerlebnis zu bieten!
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={teamDietrich}
                name="Dietrich"
                position={{ color: "info", label: "Projektleitung | Founder" }}
                description="Zuständig für die Organisation und die Leitung des Projekts, als auch für die Entwicklung der Systeme."
              />
            </MKBox>
          </Grid>
          {/* <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={teamPlaceholder}
                name="Tony"
                position={{ color: "info", label: "Teamleitung" }}
                description="Seit Anfang an an unserer Seite. Zuständig für die Organisation und Leitung des Teams."
              />
            </MKBox>
          </Grid> */}
          
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={teamRoschy}
                name="Roschy"
                position={{ color: "info", label: "Lead-Developer" }}
                description="Das technische Backend-Genie hinter dem Projekt. Zuständig für die Entwicklung der Systeme."
              />
            </MKBox>
          </Grid>
          <Divider sx={{ width: "100%" }} variant="middle"/>
          <Divider sx={{ width: "100%" }} variant="middle"/>
          </Grid>
          <Grid container spacing={3}>
          {/* <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={teamOgen}
                name="Dr.Ogen"
                position={{ color: "info", label: "Support" }}
                description="Als alter Hase, gehört er mittlerweile zum urgestein des Projekts."
              />
            </MKBox>
          </Grid> */}
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={teamMarlboro}
                name="Marlboro"
                position={{ color: "info", label: "Support" }}
                description=""
              />
            </MKBox>
          </Grid>
          {/* <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={teamPlaceholder}
                name="Nordon"
                position={{ color: "info", label: "Support" }}
                description=""
              />
            </MKBox>
          </Grid> */}
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={teamPlaceholder}
                name="Chapman"
                position={{ color: "info", label: "Support & Design" }}
                description=""
              />
            </MKBox>
          </Grid>
          
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={teamPlaceholder}
                name="Gil"
                position={{ color: "info", label: "Erweiterter Support" }}
                description=""
              />
            </MKBox>
          </Grid>
          
        </Grid>
      </Container>
    </MKBox>
  );
}


export default Team;
