// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import { filterRoutesByRole } from 'routes';
import loading from "components/Loading";
import { useState, useEffect } from "react";
import { SignOutButton, SignInButton, ClerkProvider, useUser,useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

function Privacy() {
  const { userId, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);

  useEffect(() => {
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);

  const filteredRoutes = filterRoutesByRole(routes, userRoles);
  return (
    <>
      <DefaultNavbar
        routes={filteredRoutes}
        action={{
          type: "external",
          route: "",
          label: "buy now",
          color: "dark",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white"> 
                    Natives Guide
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Natives auf the Western Hills – alles, das du wissen solltest!
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                    <MKTypography variant="h5" mt={6} mb={3}>
                    Wie fange ich an?
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                        Als Native erwachst du inmitten der wunderschönen Natur in der Nähe des Wapiti Reservats und wirst von einem Indianer Guide begrüßt, welcher einige wichtige Informationen über Orte für Natives preisgibt. Eine Grundausstattung in Form von Werkzeug und anderen Kleinigkeiten hast du als Native direkt dabei.
                        </MKTypography>
                        
                  <MKBox component="ul" my={3} ml={6}>
                        
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Informiere dich über Natives, wie sie im Jahre 1899 lebten und sich versorgten.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Mache dir Gedanken über deinen Native Charakter: Von welchem Stamm stammt dein Native Charakter ab? Wie war die Lebensweise und welche Ansichten galten dort? War es ein friedlicher Stamm oder ein Kriegerstamm? Wie heißt dein Charakter wie agiert er mit seiner Umwelt?
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Was sind deine Ziele in den Western Hills? Einen eigenen Stamm zu gründen, einem Stamm beizutreten, oder als Einzelgänger durch die Wälder zu streifen? Möchtest du Handel mit Bürgern betreiben? Den Weg der Pferdezucht oder den Weg der Kräutermedizin bestreiten? Was sind die Fähigkeiten deines Native Charakters und welche weiteren Kenntnisse möchte sich dein Native Charakter noch aneignen?
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Erstelle einen Charakter und beachte die richtige Kleidungswahl, das diese zu einem naturverbundenen Native passt. Vermeidet bitte alle Arten von Masken und beachtet dazu unsere Serverregeln zu Masken. Masken wurden entgegen der allgemeinen Auffassung nur zu rituellen Anlässen getragen und sind bei uns auf dem Server absolut untypisch für Native Charaktere!
Ist dein Native Charakter einer, der vermehrt mit Bürgern Kontakt hat, oder Handel betreibt, eignet sich dieser Charakter sicher bald auch modernere Kleidung an, um diese mit der traditionellen Kleidung zu vermischen. Beide Seiten lernen voneinander und akzeptieren sich so weit es geht.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Mache dich bei deinen ersten Schritten in den Western Hills mit deiner Umgebung vertraut und beschaffe dir die Dinge, die dein Charakter benötigt. Begib dich auf die Suche nach einem Wildpferd und zähme es. Spüre wilde Karotten auf, um dein Pferd zu versorgen. Wichtige Ressourcen wie Holz und Tiersehnen wirst du für deinen Bogen benötigen.
                        </MKTypography>
                      </MKBox>
                  </MKBox>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Das Leben der Natives in den Western Hills
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    {/* To-Do Dietrich: Bild einfügen, href= https://wiki.western-hills.de/guides/nativecraft.jpg alt=Native Crafting Menü */}
                                        Das Leben in den Western Hills gestaltet sich frei und autark. Im Radialmenü findest du als Native Möglichkeiten, Gegenstände wie einen Bogen, Lasso, Fackel und weitere Dinge direkt zu craften. Es gibt drei verschiedene Native Lager in den Western Hills, die bespielt werden können. Dort befinden sich neben Tippis, auch ein Stall, Lager, eine Crafting Bank und ein Kleidungs Zelt zum kostenlosen Wechseln der Kleidung. Ebenfalls gibt es eigene Minen oder auch Höhlen in der Nähe des Wapiti Reservats, wo Steine abgebaut werden können, die zum Herstellen von Gegenständen benötigt werden.
                    <br />
                    Mit steigender Erfahrung an der Crafting Bank lassen sich schon bald weitere Gegenstände herstellen. Der Anbau und das Sammeln von Pflanzen, die für Medizin oder den Schnitttabak benötigt werden, ist neben dem Jagen eine wichtige Aufgabe der Natives. Das Stammesleben unterscheidet sich je nach Region. Pferde können selbst gezähmt, trainiert und eingestallt werden.
                    <br />
                    Ebenfalls haben Natives die Möglichkeit ein Kanu auf den Gewässern zu befahren, wenn sie den Native Boot NPC antreffen. Der Handel zwischen den Stämmen und Bürgern baut auf gegenseitiges Vertrauen auf, welches sehr zerbrechlich ist. Ein Friedensvertrag bewahrte beiden Parteien vor neuen großen Auseinandersetzungen und man lebte vorwiegend friedlich nebeneinander. Doch ganz ohne kleinere Konflikte würde es wohl nie auskommen, denn auf beiden Seiten gibt es Verräter und Diebe. Amberino gilt als vorwiegender Lebensraum der Natives, ebenso der westliche Teil von New Austin, wo die endlose Weite der Natur regiert.
                    {/* To-Do Dietrich: Wort "Amberino" verlinken mit: https://wiki.western-hills.de/de/Lore/Counties/Ambarino */}
                    {/* To-Do Dietrich: Wort "New Austin" verlinken mit: https://wiki.western-hills.de/de/Lore/Counties/New-Austin */}
                                      </MKTypography>
                    {/* To-Do Dietrich: Bild einfügen href= https://wiki.western-hills.de/guides/nativeboot.jpg alt=Native Boot*/}       
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
