/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleFooter from "examples/Footers/SimpleFooter";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

// HelpCenter page components
import FaqCollapse from "pages/Support/Faq/components/FaqCollapse";

import KeyboardLayout from "./components/keyboardLayout";

// Routes
import routes from "routes";

import { filterRoutesByRole } from 'routes';
import loading from "components/Loading";
import { useState, useEffect } from "react";
import { SignOutButton, SignInButton, ClerkProvider, useUser,useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

function Faq() {
  const [collapse, setCollapse] = useState(false);
  const { userId, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);

  useEffect(() => {
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        //console.log(data);
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);

  const filteredRoutes = filterRoutesByRole(routes, userRoles);
  
  return (
    <>
      <DefaultNavbar
        routes={filteredRoutes}
        action={{
          type: "external",
          route: "",
          label: "buy now",
          color: "dark",
        }}
        sticky
      />
      
      <MKBox component="section" pt={20} pb={12}>
        
        <Container>
        
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                
                <KeyboardLayout />
                {/* <MKBox p={6}>
                  <MKTypography variant="h5" my={3}>
                    Basics
                  </MKTypography>
                  <FaqCollapse
                    title="Was ist RedM?"
                    open={collapse === 1}
                    onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
                  >
                    RedM ist eine Modifikationsplattform für das Videospiel Red Dead Redemption 2, ähnlich wie FiveM für Grand Theft Auto V. Mit RedM können Spieler auf speziell modifizierten Servern spielen,
                    die eine Vielzahl von benutzerdefinierten Inhalten und Spielmodi bieten.
                    Das ermöglicht ein einzigartiges Rollenspielerlebnis, das über das hinausgeht, was in der standardmäßigen Einzelspieler- oder Mehrspielererfahrung von Red Dead Redemption 2 zu finden ist.
                    Mit RedM kann man beispielsweise auf unserem Western Hills RP-Server spielen und eine immersivere und personalisierte Western-Rollenspiel-Erfahrung genießen.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Welche Anforderungen muss ich erfüllen, um auf dem Server spielen zu können?"
                    open={collapse === 2}
                    onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
                  >
                    <b>Um auf unserem Server spielen zu können, müssen folgende Anforderungen erfüllt sein:</b>

                    <ul >
                      <li>Red Dead Redemption 2 auf dem PC besitzen</li>
                      <li>Mindestens 18 Jahre alt sein</li>
                      <li>Ein Mikrofon mit guter Audioqualität und wenig bis keinen Hintergrundgeräuschen haben</li>
                      <li>Unser allgemeines Regelwerk und die Serverregeln gelesen und akzeptiert haben (Link)</li>
                      <li>Unseren Roleplay-Guide gelesen und verstanden haben (Link)</li>
                      <li>Unser Server-Wiki HowTo zur Einführung gelesen haben (Link)</li>
                      <li>Das Bewerbungsverfahren bestanden haben. Informationen dazu finden Sie unter Whitelist-Info </li>
                    </ul>
                  </FaqCollapse>
                  <FaqCollapse
                    title=" Wo finde ich Informationen zur Installation und zum Spielstart?"
                    open={collapse === 3}
                    onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
                  >
                    Alle Informationen zur Installation und zum Spielstart kann man in den dafür vorgesehnenen Guides finden. Zögere nicht uns zu kontaktieren, wenn du weitere Fragen hast.
                  </FaqCollapse>
                  
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Account & Settings
                  </MKTypography>
                  <FaqCollapse
                    title="How do I change my password?"
                    open={collapse === 6}
                    onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
                  >
                    Society has put up so many boundaries, so many limitations on what&apos;s right
                    and wrong that it&apos;s almost impossible to get a pure thought out. It&apos;s
                    like a little kid, a little boy, looking at colors, and no one told him what
                    colors are good, before somebody tells you you shouldn&apos;t like pink because
                    that&apos;s for girls, or you&apos;d instantly become a gay two-year-old.
                  </FaqCollapse>
                  <FaqCollapse
                    title="How can I delete my account?"
                    open={collapse === 7}
                    onClick={() => (collapse === 7 ? setCollapse(false) : setCollapse(7))}
                  >
                    Why would anyone pick blue over pink? Pink is obviously a better color.
                    Everyone&apos;s born confident, and everything&apos;s taken away from you
                    <br />
                    <br />
                    We&apos;re not always in the position that we want to be at. We&apos;re
                    constantly growing. We&apos;re constantly making mistakes.
                  </FaqCollapse>
                  <FaqCollapse
                    title="How can I change account make account settings?"
                    open={collapse === 8}
                    onClick={() => (collapse === 8 ? setCollapse(false) : setCollapse(8))}
                  >
                    People in this world shun people for being great. For being a bright color.
                    We&apos;re constantly trying to express ourselves and actualize our dreams. If
                    you have the opportunity to play this game of life you need to appreciate every
                    moment. A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                  </FaqCollapse>
                  <FaqCollapse
                    title="How to reset my password?"
                    open={collapse === 9}
                    onClick={() => (collapse === 9 ? setCollapse(false) : setCollapse(9))}
                  >
                    What matters is the people who are sparked by it. And the people who are like
                    offended by it, it doesn&apos;t matter. Because it&apos;s about motivating the
                    doers. Because I&apos;m here to follow my dreams and inspire other people to
                    follow their dreams, too.
                  </FaqCollapse>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Licenses
                  </MKTypography>
                  <FaqCollapse
                    title="What are the differences between the licenses on the website?"
                    open={collapse === 10}
                    onClick={() => (collapse === 10 ? setCollapse(false) : setCollapse(10))}
                  >
                    We&apos;re constantly trying to express ourselves and actualize our dreams. If
                    you have the opportunity to play this game of life you need to appreciate every
                    moment. A lot of people don&apos;t appreciate the moment until it&apos;s passed.
                    The time is now for it to be okay to be great.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Can I use a premium product in an open source project?"
                    open={collapse === 11}
                    onClick={() => (collapse === 11 ? setCollapse(false) : setCollapse(11))}
                  >
                    People in this world shun people for being great. For being a bright color. For
                    standing out. But the time is now to be okay to be the greatest you. Would you
                    believe in what you believe in, if you were the only one who believed it? As we
                    live, our hearts turn colder.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Can I use my Freelancer/Startup License on an alias domain?"
                    open={collapse === 12}
                    onClick={() => (collapse === 12 ? setCollapse(false) : setCollapse(12))}
                  >
                    Cause pain is what we go through as we become older. We get insulted by others,
                    lose trust for those others. We get back stabbed by friends. It becomes harder
                    for us to give others a hand. We get our heart broken by people we love, even
                    that we give them all we have. Then we lose family over time. What else could
                    rust the heart more over time? Blackgold.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Can I use my Freelancer/Startup License on an alias domain?"
                    open={collapse === 13}
                    onClick={() => (collapse === 13 ? setCollapse(false) : setCollapse(13))}
                  >
                    Cause pain is what we go through as we become older. We get insulted by others,
                    lose trust for those others. We get back stabbed by friends. It becomes harder
                    for us to give others a hand. We get our heart broken by people we love, even
                    that we give them all we have. Then we lose family over time. What else could
                    rust the heart more over time? Blackgold.
                  </FaqCollapse>
                </MKBox>*/}
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox> 
      <MKBox pt={{ xs: 0, lg: 3 }} pb={3}>
        <SimpleFooter />
      </MKBox>
    </>
  );
}

export default Faq;
