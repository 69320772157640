// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import { filterRoutesByRole } from 'routes';
import loading from "components/Loading";
import { useState, useEffect } from "react";
import { SignOutButton, SignInButton, ClerkProvider, useUser,useAuth, SignedOut, SignIn  } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

function RoleguideCrime() {
  const { userId, sessionId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userRoles, setuserRoles] = useState([]);

  useEffect(() => {
    fetch(API_URL + '/users/fetchUserRoles/' + userId, {
      headers: {
        'x-api-key': API_KEY
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setuserRoles(Array.from(data));
        setIsLoading(false);  // Set loading to false after receiving data
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);  // Also set loading to false if an error occurs
      });
  }, [userId]);

  const filteredRoutes = filterRoutesByRole(routes, userRoles);
  return (
    <>
      <DefaultNavbar
        routes={filteredRoutes}
        action={{
          type: "external",
          route: "",
          label: "buy now",
          color: "dark",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white"> {/* Seitenüberschrift: hx wie auf den anderen Seiten */}
                    Crime Guide 
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Alles was du wissen musst als Outlaw, oder auch von Nobody zur Legende des Hills
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                     Einführung
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Obwohl jeder einen Kriminellen spielen kann, ist dies eine der schwierigeren Rollen auf dem Server. Kriminelle sind einer der Hauptauslöser für Konflikt-Rollenspiele auf dem Server und oft die treibenden Kräfte hinter großen Handlungssträngen. Indem die Regeln auf der Website und ein paar einfache Richtlinien an verschiedenen Punkten des Charakterbogens befolgt werden, können kriminelle Spieler dazu beitragen, dass alle ein besseres Rollenspiel erleben. Eines der wichtigsten Dinge, die du als Krimineller beachten solltest, ist es, eine angenehme Erfahrung für alle zu schaffen – für das Gesetz, für Zivilisten und für deine Mitkriminellen. Je mehr du bereit bist, dich auf das Rollenspiel der anderen einzulassen, anstatt deiner eigenen vorgeplanten Geschichte zu folgen und das „Gewinnen“ im Fokus steht, desto wahrscheinlicher ist es, dass die anderen dein RP und deinen Charakter genießen werden.
                    <br />Eine gute Faustregel ist es, ein Ziel für deinen kriminellen Charakter, oder eine Reihe von Zielen zu definieren, um ihm etwas zu geben, auf das er hinarbeiten kann, um seinen Charakter wachsen zu lassen und um ihm einen Sinn in deinem RP zu geben. Du kannst dich an folgende Richtlinien halten, um für deinen Crime-Char ein gutes Konzept zu erarbeiten. Solltet ihr euch bereits als eine gesuchte Outlaw-Bande registrieren wollen, ist dies beim Support möglich und erfordert Absprache. 
                  </MKTypography>
                      
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Wie fange ich an?
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    <ul>
                      <li>Plane deine Geschichte klug. Ein gesuchter Verbrecher ist vielleicht nicht der beste Start, wenn man sich eine kriminelle Laufbahn erarbeiten möchte. Erwarte nicht, dass ein gesuchter Krimineller bei Zivilisten gut ankommt. Starte langsam und bedacht.</li>
                      <li>Einen Charakter zu bespielen, der auf der Flucht ist, ist völlig in Ordnung, solange das deiner Geschichte dient – doch deine eigentliche Geschichte wird auf den Western Hills geschrieben. Der Einstieg als „Gesucht“ kann dir das Leben sehr erschweren.</li>
                      <li>Dein Charakter hat ein Ablaufdatum. Crime-Spieler sollten sich bewusst werden, dass ihre Charaktere unter Umständen schneller sterben als Zivilisten. Das sollte dich jedoch trotzdem dazu animieren, einen Charakter zu spielen, der die Absicht hat zu überleben, eine gute Geschichte für Alle zu schaffen und einem würdigen Ende entgegen zu sehen.</li>
                      <li>Überlege genau wann du dich mit dem Gesetz anlegst. Ein sehr früher Zeitpunkt, kann auch ein frühes Ende deines Charakters bedeuten. Grundsätzlich gilt auch da, biete dem Gesetz RP und dir wird gutes RP geboten. Crime-Spieler die einfach nur mit aller Gewalt versuchen an Ihr Ziel zu kommen, werden vermutlich die Konsequenzen daraus tragen, die ebenso wenig RP bieten.</li>
                      <li>Bevor du den Weg des Verbrechens einschlägst, solltest du die Zeit nutzen, um an wichtige Dinge zu kommen. Hast du eine Waffe? Brauchst du ein Pferd? Wie besorgt man das, ohne dass es auf dich zurückfällt? Das alleine ist schon ein guter Weg um das RP in das Verbrechen zu führen. Versorge dich erst mit Informationen und sei vorsichtig dabei. Das kann dir helfen ein Netzwerk aufzubauen und andere Kriminelle zu finden.</li>
                      <li>Fange mit kleinen Verbrechen an, das ist ein guter Start für dich zu prüfen, wie dein RP rüberkommt. Hier kannst du auch in Erfahrung bringen, wie das Gesetz auf dich reagiert. Dann hat man vielleicht trotz einer Hafteinheit viel dazu gelernt und ist deutlich reicher an Erfahrung als durch einen erbeuteten Cattleman.</li>
                      <li>Mit der Zeit kann man sich steigern und auch mit deutlich mehr Erfahrung sich an größere Fische wagen – eine wohlhabende Person, eine Veranstaltung usw.</li>
                      <li>Ein Raub muss nicht mit dem Verlust von Gegenständen enden. Man kann auch aus reinem RP einen guten Raub gestalten. Du kannst auch ein „Accessoire“ stehlen, Schuhe, oder Informationen. Es muss nicht immer die teuerste Waffe sein, die man ggf. zurückverfolgen kann. Natürlich gehört der Verlust von Dingen dazu, versuche den Gegenwert der Gegenstände in RP aufzuwiegen.</li>
                      <li>Mit der Zeit und deinem Bekanntheitsgrad wirst du langsam, aber sicher ein bekannter Verbrecher. Überlege dir jetzt genau, wie du weiter fortfahren willst. Schließe dich einer Bande an, vermeide Städte außer in Notfällen.</li>
                      <li>Streue kleine Beweise. Gib dem Gesetz eine Chance, die Ermittlung aufzunehmen. Es kann durchaus subtil sein, z.B. immer den gleichen Spruch benutzen.</li>
                      <li>Solltest du wegen kleinen Delikten gesucht werden, ist es auch nicht schlimm gefasst zu werden. Das generiert für dich und das Gesetz RP.</li>
                      <li>Solltest du genug Straftaten angesammelt haben, wird die LAW dich früher, oder später schnappen. Es gibt viele Wege die dein Charakter einschlagen kann.</li>
                      <li>Sofern du nicht zu Tode verurteilt wirst, verbringst du die Zeit auf Sisika. Durch unser Lawmen-Addon hast du dort die Möglichkeit Sissika voll zu bespielen. 
Entweder du sitzt deine Zeit ab, oder wagst es auszubrechen.</li>
                      <li>Sitzt du deine Zeit ab, hast du die Chance einen Richtungswechsel einzuschlagen, oder deinen Weg bis zum Ende zu verfolgen.</li>
                      <li>Eine Hinrichtung ist ebenfalls ein mögliches Ende für die Geschichte deines Charakters. Dies bietet nicht nur dir, sondern auch allen Fraktionen ein belohnendes Gefühl.</li>
                    </ul>
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                     Outlaws
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Outlaws meiden Städte und allgemein Orte an dem das öffentliche Leben tobt.
Um Besorgungen zu machen oder um ärztliche Hilfe zu bekommen müssen sie manchmal in 
die Städte/Dörfer. Dies wird man vorsichtig tun um nicht erkannt zu werden, eventuell die Kleidung wechseln oder die Stimme verstellen.
                    <br />
Oftmals sind auch bestimmte Mitglieder einer Outlaw Bande dafür zuständig Vorräte zu besorgen oder Dinge zu erledigen. Outlaws sind sich bewusst wie schnell es mit ihrem Leben vorbei sein könnte wenn die nächste große „Aktion“ nicht nach Plan verläuft, oder ihre Identität auffliegt.
                     <br />Aufgrund ihrer Vergangenheit sind sie gesetzeslos und oftmals Gierig nach dem nächsten „großem Raub“. Innerhalb jeder Outlaw Bande gelten ganz eigene Strukturen oftmals herrscht ein harter Ton. Schwäche und natürlich Verrat wird gar nicht gerne gesehen. Das Leben ständig auf der Flucht zu sein und die Angst erkannt zu werden, ist immer groß. Somit wird es nötig sein öfters seinen Standort zu wechseln. Outlaw Camps sind überall auf der Map verteilt zu finden, bieten einen gemütlichen und gleichzeitig versteckten Aufenthalt. 
                  </MKTypography>
                      
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Kleinkriminelle 
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                   WIP
                  </MKTypography>
                  {/* TO-DO: Wort "Schwarzmarkthändler" verlinken mit: https://wiki.western-hills.de/de/HowTo/Spielsysteme/Schwarzmarkh%C3%A4ndler */}                             
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default RoleguideCrime;
