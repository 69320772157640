/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import infoCard from "assets/images/information.png";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Weltweit vernetzt"
                    description="Egal ob anderer Projekte oder Entwicklungs-Partner. The Western Hills setzt auf ein starkes Netzwerk."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Unabhängigkeit"
                    description="Durch ein eigenes Entwicklungsunternehmen kann The Western Hills gänzlich unabhängig agieren und sich auf die eigenen Ziele konzentrieren."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Umfassende Angebote"
                    description="Auch abseits des eigentlichen Roleplay-Servers bietet The Western Hills ein breites Spektrum an Angeboten, wie Entwickler-Podcasts."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Organistaion"
                    description="The Western Hills setzt auf in der Geschäftswelt bewährte Organisationsstrukturen, um ein effizientes und effektives Arbeiten zu ermöglichen."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image= {infoCard}
              title="Fragen?"
              description="Kontaktiere uns gerne über unseren Discord-Support."
              action={{
                type: "external",
                route: "https://discord.gg/western-hills",
                color: "info",
                label: "Join Discord",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
