/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Features() {
  const data = [
    {
      icon: "home",
      name: "Housing",
    },
    {
      icon: "person",
      name: "Character-Backgrounds",
    },
    {
      icon: "person_add",
      name: "Zweitcharaktere",
    },
    {
      icon: "view_carousel",
      name: "Kartensets",
    },
    {
      icon: "public",
      name: "Lebendigere Spielwelt",
    },
    {
      icon: "travel_explore",
      name: "Gebietsunterteilung",
    },
  ];

  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          <MKTypography
            component="h6"
            variant="button"
            opacity={0.7}
            textTransform="uppercase"
            fontWeight="bold"
          >
            Dietrich
            <br />
          </MKTypography>
         
          <MKTypography variant="body2">
          <br />
          Liebe Spielerinnen und Spieler,<br /><br />
          wir möchten euch heute über eine wichtige Entscheidung informieren, welche wir nach einer mehrere Monate andauernden Beobachtung und eingehender interner Beratung getroffen haben. <br />
          Seit Weihnachten haben wir stagnierende Spielerzahlen festgestellt und intensiv Feedback von aktiven sowie ehemaligen Spielern eingeholt. 
          Nachdem wir selbst in unsere Spielwelt eingetaucht sind und vieles mit Argusaugen betrachtet haben, mussten wir feststellen, dass vieles nicht so lief, wie wir es uns vorgestellt hatten. 
          <br /> <br />
          Angesichts der komplexen und sich ständig verändernden Anforderungen im Spielumfeld der letzten anderthalb Jahre, haben wir beschlossen, einen mutigen Schritt zu machen. Wir nehmen uns den Druck, das fortwährend wandelnde Umfeld weiterhin auszubalancieren, indem wir einen grundlegenden Wipe wagen.<br />
          Dieser Server-Wipe ermöglicht es uns, wesentliche Aspekte, die seit ihrer Konzeption vor zwei Jahren nicht wie erhofft funktioniert haben, grundlegend zu überarbeiten und zu verbessern. Wir sehen dies als Chance, mit frischen Ideen und verbesserten Features ein neues Kapitel für unsere Community zu beginnen.
          <br />
          Wir haben euer Feedback gesammelt, analysiert und in zahlreichen Teamsitzungen innerhalb der letzten Wochen darüber diskutiert, um daraus zu lernen und die neuen Systeme entsprechend zu gestalten. 
          <br />
          Unser Ziel ist es, euch mehr Freiheit in eurem Roleplay zu ermöglichen und das Spielerlebnis auf dem Server entspannter zu gestalten. 
          Spieler sollen sich leichter orientieren können und mehr Freiraum haben, um das zu machen, worauf sie Lust haben. 
          Dieses Konzept werden wir auch nach dem Wipe kontinuierlich weiterentwickeln. Erwartet also einige Änderungen in Gameplay und System.<br /><br />

          <b>Einige genauere Informationen zu den geplanten Änderungen findet ihr im Anschluss an diesen Text. Mehr Details folgen im Laufe der nächsten Tage und Wochen. </b><br /><br />

          Wir sind uns bewusst, dass dieser Schritt besonders für langjährige Spieler schmerzhaft sein kann. Um eure Treue zu würdigen, werden einige Elemente, wie beispielsweise eure gesammelten Karten, erhalten bleiben. 
          Diese bleiben an eure Accounts gebunden und die Belohnungen können somit direkt erneut eingesammelt werden.<br /> 
          Zusätzlich erhalten Spieler, die eine bestimmte Anzahl an Spielstunden vor dem Wipe erreicht haben, spezielle Belohnungen zum Neustart.<br /> 
          So erhalten Spieler, welche zum Zeitpunkt des Wipes 500 Spielstunden angesammelt haben ein erhöhtes Startgeld, bei über 1000 Stunden zusätzlich ein speziell eingefärbtes einzigartiges Pferd und ab 2000 Stunden einen kleinen Wolfswelpen obendrauf.
          
          Alles andere, einschließlich der Sozialstufen und der Rangliste, wird jedoch vollständig zurückgesetzt. Allerdings wird es möglich sein, für die Topliste auszuwählen, ob man die Gesamtstunden, oder die seit dem Wipe sehen möchte. Somit sind eure bisherigen Stunden auch nicht verloren.
          <br /> 
          Alle Spieler, die bereits eine Whitelist-Berechtigung haben, behalten diese, jedoch wird das Regelwerk zum Wipe angepasst. 
          Es liegt in eurer Verantwortung, euch mit den neuen Regeln vertraut zu machen!
          <br /> <br /> 
          Es ist wichtig zu betonen, dass es nach dem Wipe nicht gestattet sein wird, Ereignisse oder Begebenheiten, die vor dem Wipe stattgefunden haben, in der Spielwelt anzusprechen. Wir möchten damit sicherstellen, dass jeder Spieler, egal ob alt oder neu, auf der gleichen Ebene startet und die Chance hat, völlig neue Geschichten und Beziehungen zu entwickeln.
          <br /> <br /> 

          Die Ingame-Lore, die den Wipe begleitet, wird sein, dass eine große Flut großteile des Landes unzugänglich gemacht hatte, die das Land für eine Weile leer stehen ließ. 
          So haben sich zunächst gesetzlose in diesen gebieten eingefunden, welche jetzt über die Zeit wieder neu eingenommen und bevölkert werden sollen. <br />

          Dies bietet uns die Gelegenheit, die Welt von TWH neu zu bevölkern und eine frische, spannende Umgebung für alle zu schaffen. <br />
          Wir hoffen, dass diese narrative Einbettung des Wipes euch dabei hilft, euch in die neu gestaltete Welt einzufinden und eure Rollenspiel-Erfahrungen zu bereichern und zu intensivieren.<br />
          Wir sind bestrebt, diese Veränderungen so reibungslos und spannend wie möglich zu gestalten und freuen uns darauf, mit euch gemeinsam in dieses neue Kapitel von TWH einzutauchen!<br />
          Vor dem Wipe wird der Server für etwa eine Woche offline sein, um alle Systeme zu übertragen und zu implementieren. Am Startwochenende planen wir ein besonderes Einreise-Event für neue Spieler, bei dem wir die Whitelist-Bedingungen erleichtern und das Startkapital erhöhen werden. Weitere Informationen zum Startwochenende erhaltet ihr rechtzeitig.<br /><br />
          Wir wissen, dass diese Entscheidung nicht bei allen auf Zustimmung stoßen wird, aber wir sind uns sicher, dass die Informationen, welche im Anschluss folgen, euch - genau wie uns selbst - davon überzeugen werden, dass die Western Hills zukünftig viel mehr Möglichkeiten bieten werden, als es bisher der Fall ist und die RP-Erfahrung, gepaart mit eurem und unserem Wissen zu einem erfolgreichen Neuanfang, dem Restart, beitragen!
          <br /> <br /> 
          Mit besten Grüßen,<br />
          euer TWH-Team <br /> <br />
          </MKTypography>
          <MKTypography variant="body2">
          
          </MKTypography><br /><br />
          <MKTypography variant="h3" mb={3}>
            Erste Änderungen im Detail
          </MKTypography>
          <Container>
            <Grid container item xs={12} md={9} mx="auto">
              <Grid item xs={12} md={4} my={2}>
                <MKBox p={2} textAlign="center" borderRadius="lg">
                  <MKTypography variant="h3" color="info" mb={2} textGradient>
                    <Icon>home</Icon>
                  </MKTypography>
                  <MKTypography variant="h6">Housing</MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={12} md={4} my={2}>
                <MKBox p={2} textAlign="center" borderRadius="lg">
                  <MKTypography variant="h3" color="info" mb={2} textGradient>
                    <Icon>person</Icon>
                  </MKTypography>
                  <MKTypography variant="h6">Character-Backgrounds</MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={12} md={4} my={2}>
                <MKBox p={2} textAlign="center" borderRadius="lg">
                  <MKTypography variant="h3" color="info" mb={2} textGradient>
                    <Icon>person_add</Icon>
                  </MKTypography>
                  <MKTypography variant="h6">Zweitcharaktere</MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={12} md={4} my={2}>
                <MKBox p={2} textAlign="center" borderRadius="lg">
                  <MKTypography variant="h3" color="info" mb={2} textGradient>
                    <Icon>view_carousel</Icon>
                  </MKTypography>
                  <MKTypography variant="h6">Kartensets</MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={12} md={4} my={2}>
                <MKBox p={2} textAlign="center" borderRadius="lg">
                  <MKTypography variant="h3" color="info" mb={2} textGradient>
                    <Icon>public</Icon>
                  </MKTypography>
                  <MKTypography variant="h6">Lebendigere Spielwelt</MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={12} md={4} my={2}>
                <MKBox p={2} textAlign="center" borderRadius="lg">
                  <MKTypography variant="h3" color="info" mb={2} textGradient>
                    <Icon>travel_explore</Icon>
                  </MKTypography>
                  <MKTypography variant="h6">Gebietsunterteilung</MKTypography>
                </MKBox>
              </Grid>
            </Grid>
            <br />
          </Container>

          <MKTypography variant="h5" mb={1}>
            Housing
          </MKTypography>
          <MKTypography variant="body2">
          Es werden zukünftig keine Steuern mehr auf Gewerbe erhoben. Dies soll es euch ermöglichen, euch auf das Roleplay zu konzentrieren und eure Sorgen lösen, Gewerbe lukrativ führen zu müssen.
          <br />
          So entfällt zum Beispiel beim Bespielen eines Saloons nicht nur die monatlichen Kosten sondern auch die Kosten zum Kauf dessen. Sprich: Solange das Gewerbe aktiv bespielt wird fallen <b>keine</b> Kosten für das Betreiben an.
          <br />
          WICHTIG:
          Wird ein Gewerbe eine Woche lang nicht aktiv bespielt (Abmeldungen weiterhin möglich) und/oder der RP-Mehrwert wird der Community nicht geboten, so verliert man sein Gewerbe.
          <br /><br />
          </MKTypography>
          <MKTypography variant="h5" mb={1}>
            Character-Backgrounds
          </MKTypography>
          <MKTypography variant="body2">
          Ab sofort könnt ihr nach der Charaktererstellung nicht nur das Aussehen für euren Charakter aussuchen, sondern auch den entsprechenden Background wählen.<br />

          Euer Character ist ein hervorragender Schmied, ein Farmer, ein Bettler, welcher Mülltonnen nach Essbarem durchsucht, ein hervorragender Schütze oder gar ein reicher Schnösel?<br />

          Ab sofort stellt dies für euch kein Problem mehr dar und euer Charakter kann ganz alleine durch euch sogar noch besser werden!<br />
          Die Wahl eures Backgrounds hat zwar Auswirkungen auf euren Spielstart, hindert euch aber nicht daran, während eurer Zeit auf dem projekt gänzlich andere Wege einzuschlagen und 
          eurem Charakter neue Talente anzueignen. Wir wollen euch mehr Freiräume lassen und mehr Möglichkeiten geben, das zu machen worauf ihr gerade Lust habt und auch euren Charakter dahingehend steuern könnt.
          <br /><br />
          </MKTypography>
          <MKTypography variant="h5" mb={1}>
            Zweitcharaktere
          </MKTypography>
          <MKTypography variant="body2">
            Zukünftig wird es keine Zweitcharaktere mehr auf den Western-Hills geben. Hierdurch wollen wir euch dazu bewegen, eure Charaktere intensiver zu bespielen, eure Welt zum Leben zu erwecken sowie euch voll und ganz auf eben jenen Charakter zu konzentrieren, bis dieser sein Ende findet.
            Ort und Zeitgebundene Event-Charaktere sind immernoch eine Option.
          <br /><br />
          </MKTypography>
          <MKTypography variant="h5" mb={1}>
            Kartensets
          </MKTypography>
          <MKTypography variant="body2">
            Abseits davon, dass Bestandsspieler ihre Kartensammlung behalten, werden in Zukunft herausragende Charaktere, welche ausgereist oder gestorben sind, in einem Kartenset veröffentlicht, welches seinen Weg auf den Server finden wird. Dazu zählen bereits Charaktere, welche auch zum aktuellen Zeitpunkt bereits ausgereist und/oder gestorben sind.
          <br /><br />
          </MKTypography>
          <MKTypography variant="h5" mb={1}>
            Lebendigere Spielwelt
          </MKTypography>
          <MKTypography variant="body2">
            Komplette Systemänderungen, neue Nebenaufgaben und mehr Abwechslung, sollen für eine neue Spieldynamik sorgen. Ebenso werden wir versuchen alle Systeme und Organisationseinheiten von OOC-Mitteln auf IC-Mittel zu verlagern.
          <br /><br />
          </MKTypography>
          <MKTypography variant="h5" mb={1}>
            Gebietsunterteilung
          </MKTypography>
          <MKTypography variant="body2">
          Zukünftig sind die einzelnen Gebiete der jeweiligen Fraktionen auf der Karte sichtbar. <br /><br />

          <b>Outlaws</b><br />
          Outlaws erhalten neben dem Westen Thiefs Landing, Van Horn, Räuberhöhle und Butchers Creek. Hier können sie ihren Geschäften nachgehen und sind zugleich immer in der Nähe von Zivilisten/LAW, damit hier entsprechendes Roleplay generiert werden kann.

          Plan zu den Outlaws: Es soll (ab entsprechender Spielerzahl) einen Grenzübergang zum Westen geben, an welchen die LAW entsprechende Grenzkontrollen durchführen kann.
          <br /><br />
          <b>Natives</b><br />
          Natives erhalten drei Möglichkeiten:<br />
          → Spiele einen Native in den Städten (westl. gekleidet)<br />
          → Spiele einen Native in den markierten Regionen<br />
          → Spiele einen Native als Outlaw ohne Konsequenzen<br /><br />

          <b>Zivilist</b><br />
          Alle übrigen Gebiete. Nur Zivilisten haben die Möglichkeit der LAW beizutreten.
          <br /><br />
          <b>WICHTIG: </b>
          Zum Start des neuen Servers wird der Westen vorerst nicht aktiv bespielt. Dies hat den Hintergrund, dass wir die Spielerschaft vorerst konzentrieren wollen. Mehr dazu im Discord verlinkten FAQ oder in der zugehörigen Podcast-Folge.

            <br /><br />
          </MKTypography>
          <MKTypography variant="h3" mb={3}>
          Abschluss
          </MKTypography>
          <MKTypography variant="body2">
          Inmitten der spannenden Welle von den kommenden Veränderungen, die wir bereits angeschnitten haben, halten wir noch viele weitere Überraschungen für euch bereit.
          Wir wollen das Geheimnisvolle bewahren und euch mit einer Fülle von Neuerungen überraschen, die über diese erste Ankündigungen hinausgehen. 
          Freut euch auf innovative Benutzeroberflächen und sorgfältige Überarbeitungen bestehender Systeme. Zudem erwarten euch neue, herausfordernde Abenteuer in der Welt von Western Hills.
          Nach dem Wipe wird euer Spielerlebnis durch stetige Updates bereichert, die nahtlos an unser neues Konzept anknüpfen und dieses weiterentwickeln.
          <br /><br />
          Wir sind voller Vorfreude, euch und allen neuen Mitspielern hoffentlich ein außergewöhnliches Spielerlebnis bieten zu können.
          Dabei sind wir besonders gespannt darauf zu sehen, welche faszinierenden Handlungsstränge und charakterstarken Persönlichkeiten die neuen Western Hills zum Leben erwecken werden.
          In den kommenden Tagen und Wochen bis zum Wipe werden wir euch kontinuierlich mit weiteren Informationen versorgen.
          Haltet Ausschau nach unseren Sneak Peeks, die euch exklusive Einblicke in die größeren Änderungen gewähren werden.
          Wir freuen uns auf jeden, der sich diese Chance nicht entgehen lässt, Teil unserer sich ständig entwickelnden und spannenden Welt zu sein.
            <br /> <br />
            <b> Detailliertere Statements zu den einzelnen Punkten könnt ihr in der zugehörigen Podcast-Folge entnehmen und alternativ einen Blick in das Ankündigungs-FAQ auf dem Discord werfen.</b>
          </MKTypography>
          <br /><br />
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            sx={{ mx: "auto", textAlign: "center" }}
          ><Container>
            <MKTypography
              variant="body2"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Wir sind voller Vorfreude und Motivation für Chapter 2:       
            </MKTypography>
            </Container>
            <MKTypography variant="body1" mt={1}>
            Auf eine neue Reise!
            <br /> <br />
            </MKTypography>
          </Grid> 
        </Grid>
        
      </Container>
    </MKBox>
  );
}

export default Features;
