/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from 'prop-types';




// @mui material components
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
import React, { useState, useEffect } from 'react';
import { Button } from "@mui/material";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from 'components/MKButton';

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Images
import bgImage from "assets/images/webpage-twh.webp";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
const apiUrl = process.env.REACT_APP_API_URL;

const IMAGES_API_URL = apiUrl+'/images/pedModels/'; // Placeholder: replace with your actual endpoint
const API_KEY = process.env.REACT_APP_API_KEY;

function ImageRow({ src, name }) {
    const [isCopied, setIsCopied] = useState(false);
  
    const formattedName = name.replace('.png', '').replace('200px-', '');
  
    const copyToClipboardFallback = (text) => {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();

        try {
            document.execCommand('copy');
            return true;
        } catch (err) {
            console.error('Fallback copy method failed:', err);
            return false;
        } finally {
            document.body.removeChild(textarea);
        }
    };

    const handleCopy = () => {
        let success;
        
        if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
            navigator.clipboard.writeText(formattedName);
            success = true;
        } else {
            success = copyToClipboardFallback(formattedName);
        }

        if (success) {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        }
    };
    
  
    return (
      <Grid item xs={2}>
        <img src={src} alt={name} style={{ width: '200px', height: '358px' }} />
        <MKTypography variant="body2">{formattedName}</MKTypography>
        <MKButton color="info" size="small" onClick={handleCopy}>
            {isCopied ? 'Copied!' : 'Copy'}
        </MKButton>
      </Grid>
    );
}

ImageRow.propTypes = {
    src: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  };

function ModelsPeds() {
    const [images, setImages] = useState([]);

    useEffect(() => {
        console.log(IMAGES_API_URL)
        fetch(IMAGES_API_URL, {
          headers: {
            'x-api-key': API_KEY 
          }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setImages(data);
            })
            .catch(error => {
                console.error('Fetch error:', error.message);
            });
    }, []);
    return (
        <>
          <DefaultNavbar
            routes={routes}
            action={{
              type: "external",
              route: "https://www.creative-tim.com/product/material-kit-pro-react",
              label: "buy now",
              color: "info",
            }}
            transparent
            light
          />
          <MKBox
            minHeight="50vh"
            width="100%"
            sx={{
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0.5),
                  rgba(gradients.dark.state, 0.5)
                )}, url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Container>
              <Grid
                container
                item
                xs={12}
                lg={8}
                justifyContent="center"
                sx={{ mx: "auto", textAlign: "center" }}
              >
                <MKTypography
                  variant="h2"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Hashnames: NPCs
                </MKTypography>

              </Grid>
            </Container>
          </MKBox>
          <Card
            sx={{
              p: 2,
              mx: { xs: 2, lg: 3 },
              mt: -8,
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
              overflow: "hidden",
            }}
          >

            <Grid container spacing={3}>
                  {images.map(image => (
                    <ImageRow key={image.name} src={process.env.REACT_APP_API_URL + image.src} name={image.name} />
                  ))}
                </Grid>
           
          </Card>
          <MKBox pt={6} px={1} mt={6}>
            <DefaultFooter content={footerRoutes} />
          </MKBox>
        </>
      );
}

export default ModelsPeds;
